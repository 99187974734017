<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
<div class="  mb-15" style="background-color: #f3f3f4;padding-left: 18px; padding-right: 18px;">
    <div class="row ">
        <div class="col-md-12 mt-3">
            <h2>
                KYC Verification
            </h2>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card p-5">
                <div class="row">
                    <div class="col-md-1 mt-5">
                        <img class="hw-22" src="../../../assets/svgIcon/workflow/{{aadharStatus == 1 ? 'approved' : (aadharStatus == 2 ? 'underVerify' : (aadharStatus == 3 ? 'rejected' : 'initial' ))}}.svg" 
                        alt="{{aadharStatus == 1 ? 'Verified' : (aadharStatus == 2 ? 'Under Verification' : (aadharStatus == 3 ? 'Rejected' : 'Update' ))}}"
                        title="{{aadharStatus == 1 ? 'Verified' : (aadharStatus == 2 ? 'Under Verification' : (aadharStatus == 3 ? 'Rejected' : 'Update' ))}}">
                    </div>
                    <div class="col-md-11 mt-6">
                        <p>Aadhar Card Details</p>
                    </div>
                    <div class="col-md-1 text-center  vl">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1 mt-5">
                        <img class="hw-22" src="../../../assets/svgIcon/workflow/{{panStatus == 1 ? 'approved' : (panStatus == 2 ? 'underVerify' : (panStatus == 3 ? 'rejected' : 'initial' ))}}.svg" 
                        alt="{{panStatus == 1 ? 'Verified' : (panStatus == 2 ? 'Under Verification' : (panStatus == 3 ? 'Rejected' : 'Update' ))}}"
                        title="{{panStatus == 1 ? 'Verified' : (panStatus == 2 ? 'Under Verification' : (panStatus == 3 ? 'Rejected' : 'Update' ))}}">
                    </div>
                    <div class="col-md-11 mt-6">
                        <p>PAN Card Details</p>
                    </div>
                    <div class="col-md-1 text-center  vl">

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1 mt-5">
                        <img class="hw-22" src="../../../assets/svgIcon/workflow/{{selfieStatus == 1 ? 'approved' : (selfieStatus == 2 ? 'underVerify' : (selfieStatus == 3 ? 'rejected' : 'initial' ))}}.svg" 
                        alt="{{selfieStatus == 1 ? 'Verified' : (selfieStatus == 2 ? 'Under Verification' : (selfieStatus == 3 ? 'Rejected' : 'Update' ))}}"
                        title="{{selfieStatus == 1 ? 'Verified' : (selfieStatus == 2 ? 'Under Verification' : (selfieStatus == 3 ? 'Rejected' : 'Update' ))}}">
                    </div>
                    <div class="col-md-11 mt-6">
                        <p>Selfie Verification</p>
                    </div>
                    <div class="col-md-1 text-center  vl">

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1 mt-5">
                        <img class="hw-22" src="../../../assets/svgIcon/workflow/{{educationStatus == 1 ? 'approved' : (educationStatus == 2 ? 'underVerify' : (educationStatus == 3 ? 'rejected' : 'initial' ))}}.svg" 
                        alt="{{educationStatus == 1 ? 'Verified' : (educationStatus == 2 ? 'Under Verification' : (educationStatus == 3 ? 'Rejected' : 'Update' ))}}"
                        title="{{educationStatus == 1 ? 'Verified' : (educationStatus == 2 ? 'Under Verification' : (educationStatus == 3 ? 'Rejected' : 'Update' ))}}">
                    </div>
                    <div class="col-md-11 mt-6">
                        <p>Educational Qualification</p>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-8 mt-4">
            <div class="card p-5">
                <div *ngIf="steps=='aadhar'">
                    <form class="form" novalidate="novalidate" [formGroup]="saveAdhaarForm">
                        <div class="row">
                            <div class="col-md-6">
                                <h4> Aadhaar Card Details </h4>
                            </div>
                            <div class="col-md-6 text-right">
                                <button type="button" class="btn btn-back mr-2"
                                        [routerLink]="['/agent-profile']" (click)="exit()">Back</button>
                                <button class="btn btn-primary xyxyxy" (click)="nextStep('pan')"
                                        [disabled]="isAadharValid()">{{isAadharUnderReview == true ? 'Next' : 'Save & Next'}}
                                    </button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-7 mt-5">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Add Aadhaar Photos</label><span class="required"></span>
                                    </div>
                                    <div class="col-md-6 mt-5 text-center">
                                        <label for="front-file-upload" class="custom-file-upload text-center">
                                            <img *ngIf="!frontImage" src="../../../assets/svgIcon/uplod.svg" alt=""
                                                width="50">
                                            <p *ngIf="!frontImage" class="mt-3 p-2">Upload Front Image</p>
                                            <img *ngIf="frontImage" [src]="frontImage" alt="Preview" height="100px"
                                                width="100px" /><br>
                                        </label>
                                        <input id="front-file-upload" type="file" [disabled]="this.isAadharUnderReview"
                                            (change)="onFrontSelected($event)" accept="image/*">
                                        <p *ngIf="frontImage" class="mt-3 p-2 success-text">Front Image Uploaded
                                            Successfully !</p>
                                        <span class="text-main d-block"> Note: Accepted JPEG, PNG image with max file size 5 MB</span>    
                                    </div>
                                    <div class="col-md-6 mt-5 text-center">
                                        <label for="back-file-upload" class="custom-file-upload text-center">
                                            <img *ngIf="!backImage" src="../../../assets/svgIcon/uplod.svg" alt=""
                                                width="50">
                                            <p *ngIf="!backImage" class="mt-3 p-2">Upload Back Image</p>
                                            <img *ngIf="backImage" [src]="backImage" alt="Preview" height="100px"
                                                width="100px" /><br>
                                        </label>
                                        <input id="back-file-upload" type="file" [disabled]="this.isAadharUnderReview"
                                            (change)="onBackSelected($event)" accept="image/*">
                                        <p *ngIf="backImage" class="mt-3 p-2 success-text">Back Image Uploaded
                                            Successfully !</p>
                                        <span class="text-main d-block"> Note: Accepted JPEG, PNG image with max file size 5 MB</span> 
                                    </div>

                                </div>
                            </div>
                            <!-- <div class="col-md-6 mt-20">
                                    <label for="file-upload" class="dg-file-upload">
                                        <img src="../../../assets/svgIcon/dg.svg" alt=""><br>
                                        <p class="mt-3 p-2">Upload With DigiLocker </p>
                                    </label>
                                    <input id="file-upload" type="file" formControlName="aadhaarUpload">
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-5">
                                <label for="">Aadhaar Card Number</label><span class="required"></span>
                                <input type="text" name="aadhaarNumber" class="form-control"
                                    placeholder="Eg : 1234 - 1234 - 1234" formControlName="aadhaarNumber"
                                    (keypress)="aadharNumberSpaceOnly($event)" (input)="formatInput($event)"
                                    minlength="14" [readOnly]="button1"> 

                                <div *ngIf="!button1">
                                    <button type="submit" class="btn bt-rd btn-primary validate"
                                        (click)="adhaarValidate()">Validate</button>
                                </div>
                                <div *ngIf="button1">
                                    <button type="button" class="btn bt-rd btn-primary verified"
                                        disabled>Verified</button>
                                </div>
                                <Span class="text-danger"
                                    *ngIf="((AdharForm['aadhaarNumber'].touched  || submitted) && AdharForm['aadhaarNumber'].errors?.['required'])">
                                    Aadhar number is required.
                                </Span>
                                <Span class="text-danger"
                                    *ngIf="((AdharForm['aadhaarNumber'].touched  || submitted) && AdharForm['aadhaarNumber'].errors?.['minlength'])">
                                    Provide valid Aadhar number.
                                </Span>
                                <Span [ngClass]="aadharCode != 200 ? 'text-danger':'text-success'"
                                    *ngIf="((AdharForm['aadhaarNumber'].touched  || submitted) && !AdharForm['aadhaarNumber'].errors?.['required']) && aadharMsg != '' && aadharCode != 0">
                                    {{aadharMsg}}
                                </Span>
                                <Span [ngClass]="'text-danger'"
                                    *ngIf="((submitted) && AdharForm['aadhaarNumber'].value.length == 14) && aadharMsg == '' && aadharCode == 0">
                                    Please verify your aadhar number
                                </Span>
                            </div>

                            <!-- <div class="col-md-6 mt-5">
                                <label class="input-label " for="Date">Gender</label><span class="required"></span><br>
                                <input class="my-3 radio-button" type="radio" id="male" name="gender" value="Male"
                                    formControlName="gender">
                                Male
                                &nbsp;&nbsp;
                                <input class="my-3 radio-button" type="radio" id="female" name="gender" value="Female"
                                    formControlName="gender">
                                Female &nbsp;&nbsp;
                                <input class="my-3 radio-button" type="radio" id="other" name="gender" value="Other"
                                    formControlName="gender">
                                Other
                            </div> -->
                            <div class="col-md-6 mt-5">
                                <label class="input-label" for="Date">Gender</label><span class="required"></span><br>
                                <label class="custom-radio">
                                    <input class="radio-button" type="radio" id="male" name="gender" value="Male" formControlName="gender">
                                    <span class="radio-label">Male</span>
                                </label>
                                &nbsp;&nbsp;
                                <label class="custom-radio">
                                    <input class="radio-button" type="radio" id="female" name="gender" value="Female" formControlName="gender">
                                    <span class="radio-label">Female</span>
                                </label>
                                &nbsp;&nbsp;
                                <label class="custom-radio">
                                    <input class="radio-button" type="radio" id="other" name="gender" value="Other" formControlName="gender">
                                    <span class="radio-label">Other</span>
                                </label>
                            </div>
                            <div class="col-md-6 mt-5">
                                <label for=""> First Name</label><span class="required"></span>
                                <input type="text" name="firstName" id="" class="form-control" placeholder="First Name" formControlName="firstName">
                            </div>
                            <div class="col-md-6 mt-5">
                                <label for=""> Last Name</label><span class="required"></span>
                                <input type="text" name="lastName" id="" class="form-control" placeholder="Last Name" formControlName="lastName">
                            </div>
                            <div class="col-md-6 mt-5">
                                <label for=""> Address</label><span class="required"></span>
                                <input type="text" name="address" id="" class="form-control" placeholder="Address" formControlName="address">
                            </div>
                            <div class="col-md-6 mt-5">
                                <label for=""> PIN Code</label><span class="required"></span>
                                <app-autocomplete (itemSelected)="onPincodeSelection($event)"
                                    [userInput]="userFormControl['pincode'].value" [items]="pincodeList"
                                    [supportingText]="true" placeholder="Pincode"
                                    (onKeyUpEvent)="pincodeChange($event)"></app-autocomplete>
                            </div>
                            <div class="col-md-6 mt-5">
                                <label for=""> City</label><span class="required"></span>
                                <app-autocomplete class="w-100" (itemSelected)="onCitySelection($event)"
                                    [items]="districtList" placeholder="City" [supportingText]="false"
                                    [userInput]="userFormControl['city'].value" [readonly]="'readonly'"></app-autocomplete>
                            </div>                            

                            <div class="col-md-6 mt-5">
                                <label for=""> State</label><span class="required"></span>
                                <app-autocomplete class="w-100" [items]="stateList" placeholder="State"
                                    (itemSelected)="onStateSelection($event)"
                                    [userInput]="userFormControl['state'].value" [readonly]="'readonly'"></app-autocomplete>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button type="button" class="btn btn-back mr-2"
                                        [routerLink]="['/agent-profile']" (click)="exit()">Back</button>      
                                <button class="btn btn-primary xyxyxy" (click)="nextStep('pan')"
                                        [disabled]="isAadharValid()">{{isAadharUnderReview == true ? 'Next' : 'Save & Next'}}
                                    </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="steps=='pan'">
                    <form class="form" novalidate="novalidate" [formGroup]="savePanForm">
                        <div class="row">
                            <div class="col-md-6">
                                <h4> PAN Card Details </h4>
                            </div>
                            <div class="col-md-6 text-right">
                                <button type="button" class="btn btn-back mr-2"
                                    (click)="nextStep('aadhar')">Back</button>
                                <button class="btn btn-primary " (click)="nextStep('selfie')"
                                    [disabled]="savePanForm.invalid || holder==null">{{isPanUnderReview == true ? 'Next' : 'Save & Next'}}
                                    </button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    Add PAN Card Photo<span class="required"></span>
                                </p>
                            </div>
                            <div class="col-md-6 ">
                                <label for="pan-file-upload" class="custom-file-upload text-center">
                                    <img src="{{!panImage ? '../../../assets/svgIcon/uplod.svg' : panImage}}" alt=""
                                        width="100%" height="100px">
                                    <p *ngIf="!panImage" class="mt-3 p-2">Upload PAN Card Image</p>
                                </label>
                                <input id="pan-file-upload" type="file" name="uploadPAN" [disabled]="this.isPanUnderReview"
                                    (change)="onPanSelected($event)" accept="image/*">
                                <p *ngIf="panImage" class="mt-3 p-2 success-text">Pan Card Image Uploaded Successfully !
                                </p>
                                <span class="text-main d-block"> Note: Accepted JPEG, PNG image with max file size 5 MB</span> 
                            </div>

                            <!-- <div class="col-md-6 mt-2">
                                    <label for="file-upload" class="dg-file-upload">
                                        <img src="../../../assets/svgIcon/dg.svg" alt=""><br>
                                        <p class="mt-3 p-2">Upload With DigiLocker </p>
                                    </label>
                                    <input id="file-upload" type="file" formControlName="uploadPAN">
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <label for="">PAN Card Number</label><span class="required"></span>
                                <input type="text" name="panNumber" class="form-control PAN"
                                    formControlName="panNumber" ng-pattern="/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/" (keypress)="alphaNumericOnly($event)"
                                    required=required minlength="10" maxlength="10" (input)="PanCheck()" [readOnly]="holder!=null">
                                <div *ngIf="holder==null">
                                    <button type="submit" class="btn bt-rd btn-primary validate"
                                        (click)="panValidate()">Validate</button>
                                </div>
                                <div *ngIf="holder!=null">
                                    <button type="button" class="btn bt-rd btn-primary verified"
                                        disabled>Verified</button>
                                </div>
                                <!-- <div *ngIf="button3 && !button2">
                                    <button type="button" class="btn bt-rd btn-primary non-verified" disabled>Invalid
                                        Pan</button>
                                </div> -->
                                <Span class="text-danger"
                                    *ngIf="((PanForm['panNumber'].touched  || Pansubmitted) && PanForm['panNumber'].errors?.['required'])">
                                    Pan card number is required.
                                </Span>
                                <span class="text-danger"
                                    *ngIf="((PanForm['panNumber'].touched || Pansubmitted)&& PanForm['panNumber'].errors?.['minlength'])">
                                    Enter your valid Pan card number </span>

                                    <!-- <span class="text-danger"
                                    *ngIf="((PanForm['panNumber'].touched || Pansubmitted)&& !PanForm['panNumber'].errors?.['minlength'])&& holder==null ">
                                    Enter your valid Pan card number </span> -->
                                    <span class="text-danger"
                                    *ngIf=" invalid">
                                    Invalid Pan Card  </span>

                                    <span class="text-success"
                                    *ngIf="((PanForm['panNumber'].touched)) && holder!=null">
                                    {{PanMsg}} </span>
                                <span class="text-danger"
                                    *ngIf="((Pansubmitted ) && PanForm['panNumber'].value.length==10) && holder==null ">
                                    Please Verify your Pan card number
                                </span>
                            </div>
                            <div class="col-md-6 mt-4">
                                <label for="">Name on PAN Card</label><span class="required"></span>
                                <input type="text" name="panName" class="form-control" formControlName="panName">
                                <span class="text-danger"
                                *ngIf="((PanForm['panName'].touched || Pansubmitted)&& PanForm['panName'].errors?.['required'])">
                                Name is required. </span>
                            </div>
                            <div class="col-md-6 mt-4">
                                <label for="">Date of Birth</label><span class="required"></span>
                                <input type="date" [max]="maxDOB" onkeydown="return false" placeholder="DD-MM-YYYY" name="dob" class="form-control" formControlName="dob">
                                <span class="text-danger"
                                *ngIf="((PanForm['dob'].touched || Pansubmitted)&& PanForm['dob'].errors?.['required'])">
                                Date of birth is required. </span>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button type="button" class="btn btn-back mr-2"
                                    (click)="nextStep('aadhar')">Back</button>
                                    <button class="btn btn-primary " (click)="nextStep('selfie')"
                                    [disabled]="savePanForm.invalid || holder==null">{{isPanUnderReview == true ? 'Next' : 'Save & Next'}}
                                    </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="steps=='selfie'">
                    <form class="form" novalidate="novalidate" [formGroup]="saveImageForm">
                        <div class="row">
                            <div class="col-md-6">
                                <h4> Selfie Verification</h4>
                            </div>
                            <div class="col-md-6 text-right">
                                <button type="button" class="btn btn-back mr-2" (click)="nextStep('pan')">Back</button>
                                <button class="btn btn-primary" [disabled]="saveImageForm.invalid" (click)="nextStep('Educational')">{{isSelfieUnderReview == true ? 'Next' : 'Save & Next'}}
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div class="row " *ngIf="selfy=='1'">
                            <div class="row">
                                <!-- <div class="col-md-12 mt-5 text-center">
                                    <img src="../../../assets/images/selty.png" alt="">
                                </div>
                                <div class="col-md-12 mt-5 text-center">
                                    <img src="../../../assets/images/Frame.png" alt="">
                                </div>
                                <div class="col-md-12 mt-5 text-center"> -->
                                <!-- <p class="text-center">Don’t have a Webcam?  Upload your Image</p> -->
                                <!-- <input type="file" name="image" class="form-control">
                                </div>
                                <div class="col-md-12 mt-5 text-center">
                                    <button class="btn btn-sm btn-primary" (click)="nextSelfy('2')">Take Selfie</button>
                                </div> --> 
                                <div class="col-md-12 mt-3">
                                    <div [align]="'center'" style="text-align: center;">
                                        <label for="file-upload" class="custom-file-upload">
                                            <img src="{{!verifySelfie ? '../../../assets/svgIcon/uplod.svg': verifySelfie}}"
                                                width="100%" height="100px" alt=""><br>
                                            <p *ngIf="!verifySelfie" class="mt-3 p-2">Upload Your Picture</p>
                                            <!-- <img *ngIf="verifySelfie" [src]="verifySelfie" alt="Preview" height="100%" width="100%"/><br> -->
                                        </label>
                                        <input id="file-upload" type="file" name="agentSelfie" [disabled]="this.isSelfieUnderReview"
                                            (change)="onAgentSelfieSelected($event)" accept="image/*">
                                        <p *ngIf="verifySelfie" class="mt-3 p-2 success-text" style="text-align: center;">Your Picture Uploaded
                                            Successfully !</p>
                                        <span class="text-main d-block"> Note: Accepted JPEG, PNG image with max file size 5 MB</span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="selfy=='2'">
                            <div class="col-md-12 mt-4 text-center">
                                <img src="../../../assets/images/selfie.png" alt="" class="frame">
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="row">
                                    <div class="col-md-10 text-end">
                                        <button class="btn btn-sm btn-warning" (click)="nextSelfy('1')">Retake</button>
                                    </div>
                                    <div class="col-md-2     ">
                                        <button class="btn btn-sm btn-primary"
                                            (click)="nextStep('Educational')">Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button type="button" class="btn btn-back mr-2" (click)="nextStep('pan')">Back</button>
                                <button class="btn btn-primary" [disabled]="saveImageForm.invalid" (click)="nextStep('Educational')">{{isSelfieUnderReview == true ? 'Next' : 'Save & Next'}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="steps=='Educational'">
                    <form class="form" novalidate="novalidate" [formGroup]="saveEducationForm">
                        <div class="row">
                            <div class="col-md-8">
                                <h4> Educational Qualification </h4>
                            </div>
                            <div class="col-md-4 text-right">
                                <button type="button" class="btn btn-back mr-2"
                                            (click)="nextStep('selfie')">Back</button>
                                <button class="btn btn-primary " (click)="success()"
                                            [disabled]="saveEducationForm.invalid">Submit
                                        </button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-7 mt-5">
                                <label for="">Select Education Level</label><span class="required"></span>
                                <select name="qualificationID" class="form-select" aria-label="Default select example"
                                    formControlName="qualificationID">
                                    <option selected value=""> Education Level</option>
                                    <option value="1">10th</option>
                                    <option value="2">12th</option>
                                    <option value="3">Graduation</option>
                                    <option value="4">Post-Graduation</option>
                                </select>
                            </div>
                            <div class="col-md-6 mt-2">
                                <!-- <p>Add Marksheet</p><span class="required"></span> -->
                                <label for="file-upload" class="custom-file-upload">
                                    <!-- <img  src="{{!agentMarksheet ? '../../../assets/svgIcon/uplod.svg': agentMarksheet}}" width="100%" alt="">
                                    <p *ngIf="!agentMarksheet" class="mt-3 p-2">Upload Your Marksheet</p>
                                    <img *ngIf="agentMarksheet" [src]="agentMarksheet" alt="Preview" height="100%" width="100%"/><br>
                                    <p *ngIf="agentMarksheet" class="mt-3 p-2 success-text">Your Marksheet Uploaded Successfully !</p>
                                -->
                                    <img src="{{!marksheet ? '../../../assets/svgIcon/uplod.svg' : marksheet}}" alt=""
                                        width="100%" height="150px">
                                    <p *ngIf="!marksheet" class="mt-5 p-2">Upload Your Marksheet</p>

                                    <!-- <img [src]="this.panImage" alt="Preview" height="90" width="150"/><br> -->

                                </label>
                                <input id="file-upload" type="file" name="uploadMarksheet" [disabled]="this.isEducationUnderReview"
                                    (change)="onAgentMarksheetSelected($event)" accept="image/*">
                                <p *ngIf="marksheet" class="mt-3 p-2 success-text">Marksheet Uploaded Successfully !</p>
                                <span class="text-main d-block"> Note: Accepted JPEG, PNG image with max file size 5 MB</span> 
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button type="button" class="btn btn-back mr-2"
                                            (click)="nextStep('selfie')">Back</button>
                                <button class="btn btn-primary " (click)="success()"
                                            [disabled]="saveEducationForm.invalid">Submit
                                        </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="Success">
    <div class="popup2">
        <!-- <div class="modal-header  bg-transparent">
            <button type="button" class=" close pull-right" aria-label="Close" (click)=" close()">
            </button>
        </div> -->
        <div class="model-body">
            <div class=" row text-center">
                <div class="col-md-12 mt-3 p-3 text-center">
                    <img src="../../../assets/images/green_double_circle_check_mark 1.png" alt="">
                </div>
                <div class="col-md-12 mt-3">
                    <h6>"KYC details have been submitted successfully."</h6>
                </div>
                <div class="col-md-12 mt-3">
                    <span>Congratulations! KYC details have been submitted successfully.</span>
                </div>
                <div class="col-md-12 mt-2">
                    <button class="btn btn-sm btn-primary" (click)="back('true')">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>