import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse, ApiResponseNew } from '../Models/ApiResponse';
import { Subject, ReplaySubject, BehaviorSubject, Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class InsuranceService {
  protected _appset = AppConfig.settings;
  public dataSubject = new ReplaySubject<any>();
  public dataState = this.dataSubject.asObservable();
  constructor(private http: HttpClient) {
  }

  QuotesValue(data:any) {
    this.dataSubject.next(data);
  }

  async AcquireInsurancePlans(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/GetQuotes`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetOpenQuotes(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/GetOpenQuotes`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetKycDetails(registrationNumber: string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}vehicleInfo/VerifyKYCDetails` + '?registrationNumber=' + registrationNumber, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetTransactionHistory(quoteID: string) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/getTransactionHistory` + '?request=' + quoteID, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      ;
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetUserDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/UniqueIdUserInfo`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async CreateQuote(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/CreateQuote`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });

      
  }
  async PostTestimonials(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}quote/PostTestimonials`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });  
}

async GetAddOnsByCategory(data: any) {
  return this.http.get<ApiResponseNew>(`${environment.APIUrl}mobile/GetAddOnsByCategory?category=`+data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponseNew) => {
    let res = new ApiResponseNew();
    res.code = errorRes.code;
    res.message = errorRes.message;
    return res;
  });
}

async GetHealtQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/GetHealtQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
       //.set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });  
}

async GetOpenHealthQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/GetOpenHealthQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async HealthCreateQuote(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/HealthCreateQuote`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      // .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });  
}

async GetTravelQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/TravelQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async CreateTravelQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/CreateTravelQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async GetPropertyQuote(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/PropertyQuickQuote`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async GetTempQuotesActualRequestByRequestId(requestId: string, quoteId:string) {
  return this.http.get<ApiResponse>(`${environment.APIUrl}quote/GetTempQuotesActualRequestByRequestId` + '?requestId=' + requestId +'&quoteId='+ quoteId, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async ShareQuotes(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/ShareQuotes`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async SendPolicyUserOtp(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/SendPolicyUserOtp`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async ValidatePolicyUserOtp(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/ValidatePolicyUserOtp`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async PreInspStatus(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/PreInspStatus`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}

async ContinuePolicyJourney(data: any) {
  return this.http.post<ApiResponse>(`${environment.APIUrl}quote/ContinuePolicyJourney`, data, {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  }).toPromise().catch(async (errorRes: ApiResponse) => {
    let res = new ApiResponse();
    res.isSuccess = false;
    res.message = errorRes.message;
    return res;
  });
}
}
