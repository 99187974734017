<!-- <app-navbar></app-navbar> -->
<div>
    <div class="container" *ngIf="insuranceDetailsScreen_one">

        <div class="">
            <div class=" mb-1 mt-5">
                <form #vehical_details="ngForm" class="vehicle-form">
                    <div class="row card m-0 flex-row  py-2">
                        <div class="col-md-auto d-flex align-items-center">
                            <button type="button" class="btn btn-primary px-1 py-1"><img
                                    src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="goTo()"></button>
                        </div>
                        <div class="col" *ngIf="healthData.selfSelected">
                            <h4>Self Age</h4>
                            <p class="text-grey4">{{healthData.selfAge}}yrs</p>

                        </div>
                        <div class="col" *ngIf="healthData.spouseSelected">
                            <h4>Spouse Age</h4>
                            <p class="text-grey4">{{healthData.spouseAge}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedSonAge1>0">
                            <h4>1st Son Age</h4>
                            <p class="text-grey4">{{healthData.selectedSonAge1}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedSonAge2>0">
                            <h4>2nd Son Age</h4>
                            <p class="text-grey4">{{healthData.selectedSonAge2}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedSonAge3>0">
                            <h4>3rd Son Age</h4>
                            <p class="text-grey4">{{healthData.selectedSonAge3}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedSonAge4>0">
                            <h4>4th Son Age</h4>
                            <p class="text-grey4">{{healthData.selectedSonAge4}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedSonAge5>0">
                            <h4>5th Son Age</h4>
                            <p class="text-grey4">{{healthData.selectedSonAge5}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedSonAge6>0">
                            <h4>6th Son Age</h4>
                            <p class="text-grey4">{{healthData.selectedSonAge6}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedDauAge1>0">
                            <h4>1st Daughter Age</h4>
                            <p class="text-grey4">{{healthData.selectedDauAge1}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedDauAge2>0">
                            <h4>2nd Daughter Age</h4>
                            <p class="text-grey4">{{healthData.selectedDauAge2}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedDauAge3>0">
                            <h4>3rd Daughter Age</h4>
                            <p class="text-grey4">{{healthData.selectedDauAge3}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedDauAge4>0">
                            <h4>4th Daughter Age</h4>
                            <p class="text-grey4">{{healthData.selectedDauAge4}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedDauAge5>0">
                            <h4>5th Daughter Age</h4>
                            <p class="text-grey4">{{healthData.selectedDauAge5}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.selectedDauAge6>0">
                            <h4>6th Daughter Age</h4>
                            <p class="text-grey4">{{healthData.selectedDauAge6}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.fatherSelected">
                            <h4>Father Age</h4>
                            <p class="text-grey4">{{healthData.fatherAge}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.motherSelected">
                            <h4>Mother Age</h4>
                            <p class="text-grey4">{{healthData.motherAge}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.grandPaSelected">
                            <h4>Grand Father Age</h4>
                            <p class="text-grey4">{{healthData.grandPaAge}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.grandMaSelected">
                            <h4>Grand Mother Age</h4>
                            <p class="text-grey4">{{healthData.grandMaAge}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.fatherLawSelected">
                            <h4>Father-in-Law Age</h4>
                            <p class="text-grey4">{{healthData.fatherLawAge}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.motherLawSelected">
                            <h4>Mother-in-Law Age</h4>
                            <p class="text-grey4">{{healthData.motherLawAge}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.uncleSelected">
                            <h4>Uncle Age</h4>
                            <p class="text-grey4">{{healthData.uncleAge}}yrs</p>
                        </div>
                        <div class="col" *ngIf="healthData.auntSelected">
                            <h4>Aunt Age</h4>
                            <p class="text-grey4">{{healthData.auntAge}}yrs</p>
                        </div>
                        <!-- <div class="col buttons_div_save" style="text-align: right;">
                            <button *ngIf="!isPopupFormVisible" type="button" class="btn btn-primary py-1" (click)="openPopupForm()"><img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="svg_white edit-icon cursor-pointer"></button>
                            <button *ngIf="isPopupFormVisible" type="button" class="btn btn-primary px-2 py-1" (click)="updateVehicleDetailsForm(vehical_details.value)"><img class="svg_white edit-icon cursor-pointer" src="../../assets/svgIcon/FloppyDisk.svg" alt="Apply"></button>
                            <button *ngIf="isPopupFormVisible" type="button" class="btn btn-warning px-2 py-1 ml-2 btn-active" (click)="closePopupForm()"><img class="svg_white edit-icon cursor-pointer" src="../../assets/svgIcon/close-secondary.svg" alt="Cancel"></button>
                            
                        </div> -->
                    </div>
                </form>

            </div>
        </div>
        <div class="container" *ngIf="!isInsurancePlan">
            <div class="row" style="margin-top:20px;">
                <div class="col-12 mb-5">

                </div>
                <div class="col-md-4">

                    <div class="card p-5 filter-section leftDiv rounded-bottom-0 mx-0 mb-0">
                        <form #rangeForm="ngForm">
                            <div class="justify-content-between">
                                <div>
                                    <h4 class="text-start">Sum Insured</h4>
                                    <select class="form-select">
                                        <option value="none">Choose Sum Insured</option>
                                        <option value="bolow8lakhs">Below 5 lakhs</option>
                                        <option value="bolow8lakhs">5 - 9 lakhs</option>
                                        <option value="above8threelakhs">10 - 24 lakhs</option>
                                        <option value="below12lakhs">25 - 99 lakhs</option>
                                        <option value="above12lakhs">1cr+</option>
                                    </select>
                                </div>
                            </div>
                            <div class="justify-content-between">
                                <div>
                                    <h4 class="text-start">Plan Type</h4>
                                    <select class="form-select">
                                        <option value="none">Choose Plan Type</option>
                                        <option value="baseplan">Base Plan</option>
                                        <option value="1crvalue">1Cr Value</option>
                                        <option value="arogyasanjivani">Arogya Sanjeevani</option>
                                        <option value="topupplans">Top-up Plans</option>
                                        <option value="multilevelplans">Multi-Level Plans</option>
                                    </select>
                                </div>
                            </div>
                            <div class="justify-content-between">
                                <div>
                                    <h4 class="text-start">Policy Duration</h4>
                                    <select class="form-select">
                                        <option value="none">Choose Policy Duration</option>
                                        <option value="1year">1 Year</option>
                                        <option value="2year">2 Year</option>
                                        <option value="3year">3 Year</option>

                                    </select>
                                </div>
                            </div>
                        </form>
                        <hr class="" />
                        <div class="justify-content-between">
                            <div>
                                <h4 class="text-start">Add-ons</h4>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-6 align-items-center text-wrap mb-1" *ngFor="let item of addOnnsItems;" [title]="item.name">
                            <input type="checkbox" class="scale-1_3 mt-0 mr-2" [value]="item.id" (change)="onAddOnnChange($event)">{{item.name}}
                            </div>
                        </div> -->
                        <hr class="" />
                        <!-- <div class="justify-content-between">
                        
                            <div class="justify-content-between" style="display: flex;">
                                <div>
                                    <h4 class="text-start">What is your existing illness ?</h4>
                                </div>
                                <div class="d-flex" *ngIf="!isEditPolicy">
                                    <img class="edit-icon" src="../../assets/svgIcon/PencilLine.svg" alt="Edit">&nbsp;&nbsp;
                                    <h6 class="text-edit" (click)="editPolicy()">Edit</h6>
                                </div>
                                <div class="d-flex" *ngIf="isEditPolicy">
                                    <img class="edit-icon" src="../../assets/svgIcon/FloppyDisk.svg" alt="Save">&nbsp;&nbsp;
                                    <h6 class="text-edit" (click)="editPolicy()">Save</h6>
                                </div>
                            </div>
                            <div class="mt-5 row" *ngIf="!isEditPolicy">
                                <div class="col-6">
                                    <div class="p-3 rounded-3 illnessTabs mb-1">
                                        <h5 class="text-start">Diabetes</h5>
                                    </div>                                
                                </div>
                                <div class="col-6">
                                    <div class="p-3 rounded-3 illnessTabs mb-1">
                                        <h5 class="text-start">Heart Ailments</h5>
                                    </div>                                
                                </div>
                                <div class="col-6">
                                    <div class="p-3 rounded-3 illnessTabs mb-1">
                                        <h5 class="text-start">BP / Hypertension</h5>
                                    </div>                                
                                </div>
                                <div class="col-6">
                                    <div class="p-3 rounded-3 illnessTabs mb-1">
                                        <h5 class="text-start">Other health issues</h5>
                                    </div>                                
                                </div>
                                <div class="col-6">
                                    <div class="p-3 rounded-3 illnessTabs mb-1">
                                        <h5 class="text-start">Surgical procedure</h5>
                                    </div>                                
                                </div>
                                <div class="col-6">
                                    <div class="p-3 rounded-3 illnessTabs mb-1">
                                        <h5 class="text-start">None of the Above</h5>
                                    </div>                                
                                </div>
                            </div>
                            <hr class=""/>
                        
                        </div> -->
                    </div>
                    <button class="btn btn-primary w-100 rounded-0 rounded-bottom"
                        (click)="reCalculateQuote()">Apply</button>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-9 plans_header_div w100">
                            <div class="plans_header mt-3">
                                <h2 class="text-start">Insurance plans for you</h2>
                                <span class="font-body">Showing 24 plans for you</span>
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-3 c3">
                            <div class="card p-3">
                                <div class="row">
                                    <div class="col-md-2 c2">
                                        <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending"
                                            width="22">
                                    </div>
                                    <div class="col-md-10 c10 align-items-center d-flex justify-content-end  pl-0">
                                        <select [(ngModel)]="sort_quote" (change)="sortQuote()"
                                            class="w-100 border-0 py-0 px-2 sort-ddl">
                                            <option *ngFor="let item of sortQuoteList; let i = index;"
                                                value="{{item.value}}">{{item.text}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-5 mt-3 row plans_div">
                        <div class="row align-value">
                            <div class="col-md-3 mt-2">
                                <img alt="" class="image-stl"
                                    src="https://api.bimag.in/portalapi/api/lookUpInfo/GetInsuranceLogoById?InsuranceId=2">
                                <a class="features_link" (click)="viewFeatures()">View Features</a>
                            </div>
                            <div class="col-md-3 mt-2 ">
                                <p class=" text-grey4">Plan Name</p>
                                <h4>Supreme Direct </h4>
                            </div>
                            <div class="col-md-3 mt-2 ">
                                <p class=" text-grey4">Cover Amount</p>
                                <h4>₹ {{healthData.amountSelected}} lakhs </h4>
                            </div>
                            <div class="col-md-3 mt-2 ">
                                <!-- <p class=" text-grey4">Cashless hospitals <button class="view_hospitals_btn">99 </button></p> -->
                                <h4></h4>
                                <button class="btn btn-primary bt" id="btn"
                                    (click)="buynow()">₹{{finalPremium}}/year</button><br>
                                <a class="viewbreakup_btn" (click)="viewBreakupPlan()">View Breakup</a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card p-5 mt-3 row">
                        <div class="row">
                            <div class="col d-flex">
                                <div>
                                    <img src="" alt="unitedInsurance" width="150">
                                </div>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col d-flex">
                                <p style="color:  #848493;">Cover amount</p>
                                <h4 class="text-left">₹5 Lakh</h4>
                            </div>
                            <div class="col d-flex">
                                <p style="color:  #848493;">Cashless hospitalst</p>
                                <h4 class="text-left">155</h4>
                            </div>
                            <div class="col d-flex">
                                <p style="color:  #848493;">Claims Settled</p>
                                <h4 class="text-left">99%</h4>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <img src="../../assets/svgIcon/Scales.svg" alt="Scales" width="28">&nbsp;&nbsp;
                                <img src="../../assets/svgIcon/Heart.svg" alt="Heart" width="28">&nbsp;&nbsp;
                                <img src="../../assets/svgIcon/ArrowsOut.svg" alt="ArrowsOut" width="28">&nbsp;&nbsp;
                                <img src="../../assets/svgIcon/Share.svg" alt="Share" width="28">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col">
                                
                            </div>
                            <div class="col">
                                
                            </div>
                            <div class="col">
                                
                            </div>
                            <div class="col">
                                <h4 style="color:#3A8247;">5% Discount</h4>
                            </div>
                        </div>

                        <div class="w-100">
                            <div class="card rounded-5 p-5" style="background: rgba(10, 10, 38, 0.1);">
                                <div class="d-flex row">
                                    <div class="col-8">
                                        <span class="d-flex">
                                            <p class="card p-2" style="color: #848493; text-align: center;">Single private
                                                ac
                                                room</p>&nbsp;
                                            <p class="card p-2" style="color: #848493; text-align: center;">Day care
                                                treatment
                                            </p>&nbsp;
                                            <p class="card p-2" style="color: #848493; text-align: center;">₹2.5 Lakh no
                                                claim
                                                bonus</p>
                                        </span>
                                        <span class="d-flex">
                                            <p class="card p-2" style="color: #848493; text-align: center;">24 Supported
                                                Garages
                                                in (Your City)</p>&nbsp;
                                            <p class="card p-2" style="color: #848493; text-align: center;">Restoration of
                                                cover
                                                once a year</p>
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <button class="btn btn-dark float-right buy_now_btn" style="color: #FFFFFF;;" (click)="buynow('plan1')">₹2,500/month
                                        </button>
                                        <span class="font-body float-right me-5 mt-2">₹17464 annually</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="card row mt-2 p-3">
                        <span class="font-body text-center" style="color: #4040FF;">View 3 More Policies <img
                                src="../../assets/svgIcon/arrowDown.svg" alt="Arrow Down"
                                style="filter: invert(34%) sepia(95%) saturate(6165%) hue-rotate(240deg) brightness(100%) contrast(104%);"></span>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="container mt-5" *ngIf="isInsurancePlan">

            <!-- <div class="row card" style="Padding:12px 20px 12px 20px">
                <h4><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft" class="cursor-pointer">&nbsp;&nbsp;Select Health Insurance Plan and Policy Duration</h4>
            </div>

            <div class="mt-5">
                <h2 class="text-left">Insurance Plan</h2>
                <div class="row">
                    <div class="col card p-5 insurance_plan_tab" [ngClass]="{'active_tab': activeTabs == 1}" (click)="insurancePlan('plan1')">
                        <div class="row">
                            <div class="col">
                                <h6 class="text-left">My Health Suraksha</h6>
                            </div>
                            <div class="col-1 justify-content-end d-flex"><img
                                    style="filter: invert(42%) sepia(100%) saturate(7027%) hue-rotate(240deg) brightness(100%) contrast(102%);"
                                    src="../../assets/svgIcon/Info.svg" alt=""></div>
                        </div>
                        <p style="color:#848493">Covers damages to your vehicle only and not third-party</p>
                        <h4 class="text-center mt-5">₹699/ 1 Year</h4>
                    </div>
                    <div class="col card p-5 insurance_plan_tab" [ngClass]="{'active_tab': activeTabs == 2}" (click)="insurancePlan('plan2')">
                        <div class="row">
                            <div class="col">
                                <h6 class="text-left">Critical Illness Insurance</h6>
                            </div>
                            <div class="col-1 justify-content-end d-flex"><img src="../../assets/svgIcon/Info.svg" alt="">
                            </div>
                        </div>
                        <p style="color:#848493">Covers damages to your vehicle only and not third-party</p>
                        <h4 class="text-center mt-5">₹699/ 1 Year</h4>
                    </div>
                    <div class="col card p-5 insurance_plan_tab" [ngClass]="{'active_tab': activeTabs == 3}" (click)="insurancePlan('plan3')">
                        <div class="row">
                            <div class="col">
                                <h6 class="text-left">InIDVidual Health Insurance</h6>
                            </div>
                            <div class="col-1 justify-content-end d-flex"><img src="../../assets/svgIcon/Info.svg" alt="">
                            </div>
                        </div>
                        <p style="color:#848493">Covers damages to your vehicle only and not third-party</p>
                        <h4 class="text-center mt-5">₹699/ 1 Year</h4>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <h2 class="text-left">Policy Duration</h2>
                <div class="row">
                    <div class="col card p-5">
                        <div class="d-flex row">
                            <div class="col">
                                <h4 class="text-left" style="line-height: 2;">1 Year</h4>
                            </div>
                            <div class="col d-flex">
                                <p style="color:#848493; line-height: 2;">Premium</p>&nbsp;
                                <h2>₹699</h2>
                            </div>
                        </div>
                    </div>&nbsp;&nbsp;
                    <div class="col card p-5" style="border: 1px solid #4040FF;">
                        <div class="d-flex row">
                            <div class="col">
                                <h4 class="text-left" style="line-height: 2;">1 Year</h4>
                            </div>
                            <div class="col d-flex">
                                <p style="color:#848493; line-height: 2;">Premium</p>&nbsp;
                                <h2 style="color:#4040FF">₹699</h2>
                            </div>
                        </div>
                    </div>&nbsp;&nbsp;
                    <div class="col card p-5">
                        <div class="d-flex row">
                            <div class="col">
                                <h4 class="text-left" style="line-height: 2;">1 Year</h4>
                            </div>
                            <div class="col d-flex">
                                <p style="color:#848493; line-height: 2;">Premium</p>&nbsp;
                                <h2>₹699</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-8 card">
                    <h4 class="text-left mt-5">Insurance Cover</h4>
                    <div class="row justify-content-between mt-5">
                        <div class="col-2">
                            <h6>₹5 lakh</h6>
                        </div>
                        <div class="col">
                            <input type="range" class="form-range" id="customRange1" style="color:#4040FF !important">
                        </div>
                        <div class="col-2">
                            <h6>₹20 Lakh</h6>
                        </div>
                    </div>
                    <div class="mt-5">
                        <h1 class="text-center" style="color:#4040FF">₹14 Lakh</h1>
                    </div>
                </div>&nbsp;&nbsp;
                <div class="col card">
                    <h4 class="text-left mt-5">Insured members</h4>
                    <div class="row d-flex mt-5">
                        <div class="d-flex col">
                            <div>
                                <p class="text-start" style="color:#848493; ">Self</p>
                                <h5>32 Year</h5>
                            </div>
                        </div>
                        <div class="d-flex col">
                            <div>
                                <p style="color:#848493; ;">Spouse’s</p>
                                <h5>28 Year</h5>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <div class="d-flex col">
                            <div>
                                <p class="text-start" style="color:#848493; ">Self</p>
                                <h5>32 Year</h5>
                            </div>
                        </div>
                        <div class="d-flex col">
                            <div>
                                <p style="color:#848493; ;">Spouse’s</p>
                                <h5>28 Year</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="card col-8">
                    <h4 class="text-left mt-5">Riders</h4>
                    <p class="font-body">You should get these additional benefits to enhance your current plan</p>

                    <div class="row d-flex justify-content-between">
                        <div class="col d-flex">
                            <input type="checkbox">
                            <h5 class="m-auto">Personal Accident Cover of Rs. 10 Lakh</h5>
                            <img src="../../assets/svgIcon/Info.svg" alt="">
                        </div>
                        <div class="col d-flex">
                            <input type="checkbox">
                            <h5 class="m-auto">Engine and Gear-Box Protect Cover</h5>
                            <img src="../../assets/svgIcon/Info.svg" alt="">
                        </div>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <div class="col" style="left: 42px;">
                            <p style="color:#DF3A4D">PA cover is mandatory </p>
                            <h4>₹180/ 1 Year</h4>
                        </div>
                        <div class="col" style="left: 58px;">
                            <h4>₹100</h4>
                        </div>
                    </div>
                </div>&nbsp;&nbsp;
                <div class="card col">
                    <h4 class="mt-5">Premium Breakup</h4>
                    <br>
                    <div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">InIDVidual Health Insurance</p>
                            <h6 class="float-end">₹1,000</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Net Premium</p>
                            <h6 class="float-end">₹1,000</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Add GST(18%)</p>
                            <h6 class="float-end">₹180</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Final Premium</p>
                            <h6 class="float-end">₹1,180</h6>
                        </div>
                    </div>

                    <div class="card p-2 mb-2" style="background: rgba(58, 130, 71, 0.1);">
                        <h3 class="text-center" style="color:#3A8247">You are eligible for 5% Discount</h3>
                    </div>
                </div>
            </div> -->

            <div class="row" style="margin-top: 10px;">
                <div class="col-md-4">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-md-6">
                                <img src="https://api.bimag.in/portalapi/api/lookUpInfo/GetInsuranceLogoById?InsuranceId=2"
                                    width="100%" alt="">
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4"><span>
                                            Plan:
                                        </span></div>
                                    <div class="col-md-8">
                                        <label for="">{{this.quoteData?.planType}}</label>
                                    </div>
                                    <div class="col-md-4"><span>
                                            Sum Insured:
                                        </span></div>
                                    <div class="col-md-8">
                                        <label for="">{{this.quoteData?.sumInsured}}</label>
                                    </div>
                                    <div class="col-md-5"><span>
                                            Tenure:
                                        </span></div>
                                    <div class="col-md-7">
                                        <label for="">1 Year</label>
                                    </div>

                                </div>

                            </div>

                            <div class="col-md-12">
                                <hr>
                                <h5 class="h">Premium Breakup</h5>
                                <hr>
                            </div>

                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-9">
                                        <span style="color:black"><b>Net Premium</b></span>
                                    </div>
                                    <div class="col-md-3">
                                        <span style="color:black"><b>₹{{this.premiumObject.grosspremium}}</b></span>
                                    </div>
                                    <div class="col-md-9 mt-2 hf-cl">
                                        <span>GST (18%)</span>
                                    </div>
                                    <div class="col-md-3 mt-2 hf-cl">
                                        <span>+ ₹{{this.premiumObject.igst}}</span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="row">
                                    <div class="col-md-9 hf-cl">
                                        <h5 class="h" style="color:black ">Total Premium</h5>
                                    </div>
                                    <div class="col-md-3 hf-cl">
                                        <h5 class="h" style="color:black">₹{{this.premiumObject.finalPremium}}</h5>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-12">
                            <ul
                                class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center">
                                <li class="list-item col" [ngClass]="{'active': detail == 'proposer'}">
                                    Proposer
                                </li>
                                <li class="list-item col" [ngClass]="{'active': detail == 'address'}">
                                    Address Details
                                </li>
                                <li class="list-item  col" [ngClass]="{'active': detail == 'members'}">
                                    Members
                                </li>
                                <li class="list-item  col" [ngClass]="{'active': detail == 'nominee'}">
                                    Nominee
                                </li>
                                <li class="list-item  col" [ngClass]="{'active': detail == 'medical'}">
                                    Medical
                                </li>


                            </ul>
                        </div>
                    </div>


                    <div class="tab-content clearfix mb-5">
                        <div id="proposer" class="col-12 c-pl c-pr" *ngIf="isproposerDetails">
                            <ng-container>
                                <div class="card p-5 row br-top-0">
                                    <div class="details_card">
                                        <form id="kt_account_profile_details_form" class="form"
                                            [formGroup]="proposerForm" novalidate="novalidate">
                                            <!-- <h4 class="text-start">Great! Let’s start with proposer details</h4> -->

                                            <div class="row justify-content-between p-3">

                                                <div class="col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">PANCard</label>
                                                   <div class="inp mt-2"> <input type="text" class=" text-uppercase in-h"
                                                        name="pancard" formControlName="proposerPancard"
                                                        placeholder="PANCard" minlength="10" maxlength="10"
                                                        (keypress)="alphaNumericOnly($event)"
                                                        [ngClass]="{'border border-danger': ((valPropControls['proposerPancard'].touched ) && !valPropControls['proposerPancard'].valid)}">
                                                        <button  disabled="" class="btn btn-primary py-0 px-2 rounded-left1-0 sp" [disabled]="!valPropControls['proposerPancard'].valid" (click)="getPanCardDetails()">
                                                            <img  src="../../assets/svgIcon/fetch.svg" alt="Fetch" class="icon-white" title="Fetch Details">
                                                        </button>
                                                        </div>
                                                        <Span class="text-danger "
                                                        *ngIf="((valPropControls['proposerPancard'].touched) && valPropControls['proposerPancard'].errors?.['required'])">
                                                        PAN number is required.
                                                    </Span>
                                                    <Span class="text-danger"
                                                        *ngIf="((valPropControls['proposerPancard'].touched  ) && valPropControls['proposerPancard'].errors?.['pattern'])">
                                                        Provide valid PAN number.
                                                    </Span>
                                                </div>

                                                <div class=" col-md-6">
                                                    <label class="required lb" for="dob"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Date
                                                        of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="selfDOB"
                                                        formControlName="selfDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent in-h mt-2"
                                                        [ngClass]="{'border border-danger': ((valMemControls['selfDOB'].touched ) && !valMemControls['selfDOB'].valid)}"[(ngModel)]="defaultDate" [max]="maxDate"/>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['selfDOB'].touched) && !valMemControls['selfDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-2 mt-3">
                                                    <label class="required lb"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Titile</label>
                                                    <select class="form-select form-select-custom in-h"
                                                        style="height:50%">
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Mrs.">Mrs.</option>
                                                        <option value="Miss.">Miss.</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-4">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">First
                                                        Name</label>
                                                    <input type="text" class="form-control in-h"
                                                        name="proposerFirstName" (keypress)="alphabetOnly($event)"
                                                        formControlName="proposerFirstName" placeholder="First Name"
                                                        [ngClass]="{'border border-danger': ((valPropControls['proposerFirstName'].touched) && !valPropControls['proposerFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valPropControls['proposerFirstName'].touched ) && !valPropControls['proposerFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="name" class="lb lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Last
                                                        Name </label>
                                                    <input type="text" class="form-control in-h"
                                                        (keypress)="alphabetOnly($event)" name="proposerLastName"
                                                        formControlName="proposerLastName" placeholder="Last Name"
                                                        [ngClass]="{'border border-danger': ((valPropControls['proposerLastName'].touched) && !valPropControls['proposerLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valPropControls['proposerLastName'].touched ) && !valPropControls['proposerLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <!-- <div class="col-md-6 mt-3">
                                                    <div class="form-group">
                                                        <label class="required lb" for="gender"
                                                            style="font-weight: 700 !important; font-size: 14px !important;">Gender</label>
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <input type="radio" name="gender" class="radio_control"
                                                                    value="male" /> Male
                                                            </div>
                                                            <div class="col-4">
                                                                <input type="radio" name="gender" class="radio_control"
                                                                    value="female" /> Female
                                                            </div>
                                                            <div class="col-4">
                                                                <input type="radio" name="gender" class="radio_control"
                                                                    value="other" /> Other
                                                            </div>
                                                        </div>
                                                      
                                                        <Span class="text-danger"
                                                            *ngIf="((valPropControls['proposerGender'].touched ) && !valPropControls['proposerGender'].valid)">
                                                            Gender is required.
                                                        </Span>
                                                    </div>
                                                </div> -->


                                                <!-- <div class="mt-3 col-md-6">
                                                <label for="name" class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;">CKYC Number</label>
                                                <input type="text" class="form-control text-uppercase" name="pancard"
                                                    formControlName="proposerPancard" placeholder="Auto Fetch from PAN">


                                            </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required "
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Email
                                                        Address</label>
                                                    <input type="text" class="form-control" name="email"
                                                        formControlName="proposerEmail" placeholder="User Email Address"
                                                        [ngClass]="{'border border-danger': ((valPropControls['proposerEmail'].touched) && !valPropControls['proposerEmail'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valPropControls['proposerEmail'].touched ) && valPropControls['proposerEmail'].errors?.['required'])">
                                                        Email address is required.
                                                    </Span>
                                                    <Span class="text-danger"
                                                        *ngIf="((valPropControls['proposerEmail'].touched ) && valPropControls['proposerEmail'].errors?.['pattern'])">
                                                        Please provide valid email address.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Mobile
                                                        Number</label>
                                                    <input type="text" class="form-control in-h" name="mobile"
                                                        formControlName="proposerMobile"
                                                        placeholder="User Mobile Number" minlength="10" maxlength="10"
                                                        (keypress)="numberOnly($event)"
                                                        [ngClass]="{'border border-danger': ((valPropControls['proposerMobile'].touched ) && !valPropControls['proposerMobile'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valPropControls['proposerMobile'].touched) && valPropControls['proposerMobile'].errors?.['required'])">
                                                        Mobile number is required.
                                                    </Span>
                                                    <Span class="text-danger"
                                                        *ngIf="((valPropControls['proposerMobile'].touched) && valPropControls['proposerMobile'].errors?.['pattern'])">
                                                        Provide valid Mobile number.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Marital
                                                        Status</label>
                                                        <select class="form-select" aria-label="Default select example">
                                                            <option selected>Your Marital Status</option>
                                                            <option value="married">Married</option>
                                                            <option value="Unmarried">Unmarried</option>
                                                          </select>
                                                          


                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- [disabled]="proposerForm.invalid" -->
                                    <div class="col-md-12 mt-5  text-end">
                                        <button class="btn btn-primary"
                                            (click)="InsuranceDetails('address')" [disabled]="proposerForm.invalid">Next</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div id="address" class="col-12 c-pl c-pr" *ngIf="isproposerAddress">
                            <ng-container>
                                <div class="card p-5 row br-top-0">
                                    <div class="details_card">
                                        <form id="kt_account_profile_details_form" class="form"
                                            [formGroup]="addressInfo" novalidate="novalidate">
                                            <div class="row">

                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required "
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Flat
                                                        Number/Street Number</label>
                                                    <input type="text" class="form-control in-h" name="flatNumber"
                                                        formControlName="flatNumber"
                                                        placeholder="Flat Number/Street Number">
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Street
                                                        Address</label>
                                                    <input type="text" class="form-control in-h" name="streetAddress"
                                                        formControlName="streetAddress" placeholder="Street Address">
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">PIN
                                                        Code</label>
                                                    <!-- <input type="text" class="form-control in-h" name="proposerPinCode"
                                                        formControlName="proposerPinCode" placeholder="Eg. 500034"
                                                        minlength="6" maxlength="6" (keypress)="numberOnly($event)"
                                                        #pinCode (blur)="pincodeUserChangeEvent(pinCode)"> -->

                                                        <app-autocomplete class="w-100" (itemSelected)="onPincodeSelection($event)"
                                                            [items]="pincodeList" placeholder="Pincode" [supportingText]="true"
                                                            [userInput]="userFormControl['pincode'].value"
                                                            [showError]="((userFormControl['pincode'].touched  || submittedAddress) && !userFormControl['pincode'].valid)"
                                                             (onKeyUpEvent)="onPincodeChange($event)"  (input)="onInputChange($event) "  (keypress)="onlyNumbers($event)">
                                                        </app-autocomplete>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['pincode'].touched  || submittedAddress) && userFormControl['pincode'].errors?.['required'])">
                                                            Pincode is required.
                                                        </Span>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['pincode'].touched  || submittedAddress) && userFormControl['pincode'].errors?.['pattern'])">
                                                            Provide valid pincode.
                                                        </Span>

                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">State
                                                    </label>
                                                    <!-- <input type="text" class="form-control in-h" name="proposerState"
                                                        formControlName="proposerState"
                                                        placeholder="Auto Fetch From PIN Code"
                                                        [ngClass]="{'border border-danger': ((valAddressPropControls['proposerState'].touched) && !valAddressPropControls['proposerState'].valid)}"> -->

                                                        <app-autocomplete class="w-100" [items]="stateList" placeholder="State"
                                                            (itemSelected)="onStateSelection($event)"
                                                            (onBlurEvent)="onStateBlur($event)"
                                                            [userInput]="valAddressPropControls['proposerState'].value"
                                                            [showError]="((valAddressPropControls['proposerState'].touched  || submittedAddress) && !valAddressPropControls['proposerState'].valid)" [readonly]="'readonly'"></app-autocomplete>
                                                        <Span class="text-danger"
                                                            *ngIf="((valAddressPropControls['proposerState'].touched  || submittedAddress) && valAddressPropControls['proposerState'].errors?.['required'])">
                                                            State is required.
                                                        </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">District</label>
                                                    <!-- <input type="text" class="form-control in-h" name="district"
                                                        formControlName="district" placeholder="District"> -->
                                                        <app-autocomplete class="w-100" [items]="districtList" placeholder="District" [readonly]="'readonly'"
                                                        (itemSelected)="onDistrictSelection($event)"
                                                        (onBlurEvent)="onDistrictBlur($event)"
                                                        [userInput]="valAddressPropControls['district'].value"
                                                        [showError]="((valAddressPropControls['district'].touched  || submittedAddress) && !valAddressPropControls['district'].valid)"></app-autocomplete>
                                                    <Span class="text-danger"
                                                        *ngIf="((valAddressPropControls['district'].touched  || submittedAddress) && valAddressPropControls['district'].errors?.['required'])">
                                                        State is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">City/Talluka/Village
                                                    </label>
                                                    <!-- <input type="text" class="form-control in-h" name="proposerCity"
                                                        formControlName="proposerCity"
                                                        placeholder="Auto Fetch From PIN Code"
                                                        [ngClass]="{'border border-danger': ((valAddressPropControls['proposerCity'].touched) && !valAddressPropControls['proposerCity'].valid)}"> -->
                                                        <app-autocomplete class="w-100" (itemSelected)="onCitySelection($event)"
                                                        [items]="cityList" placeholder="City/Talluka/Village"
                                                        [supportingText]="true"
                                                        [userInput]="valAddressPropControls['proposerCity'].value"
                                                        [showError]="((valAddressPropControls['proposerCity'].touched  || submittedAddress) && !valAddressPropControls['proposerCity'].valid)"
                                                        (onBlurEvent)="onCityBlur($event)"></app-autocomplete>
                                                    <Span class="text-danger"
                                                        *ngIf="((valAddressPropControls['proposerCity'].touched  || submittedAddress) && valAddressPropControls['proposerCity'].errors?.['required'])">
                                                        City/Talluka/Village is required.
                                                    </Span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mt-5  text-start">
                                        <button class="btn btn-primary-outline btn-outline"
                                            (click)="backFunction('proposer')">Back</button>
                                    </div>
                                    <div class="col-md-6 mt-5  text-end">
                                        <button class="btn btn-primary"
                                            (click)="InsuranceDetails('member')"   [disabled]="addressInfo.invalid" >Next</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div id="members" class="col-12 c-pl c-pr" *ngIf="ismemberDetails">
                            <ng-container>
                                <div class="card p-5 row br-top-0">
                                    <div class="details_card">
                                        <form id="kt_account_profile_details_form" class="form"
                                            [formGroup]="insurerForm" novalidate="novalidate">
                                            <h4 class="text-start member_heading">Member 1</h4>
                                            <!-- <h5 class="text-start mt-5">Gajja Sudhakar</h5> -->
                                            <div class="justify-content-between row">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">First Name</label>
                                                    <input type="text" [value]="valMemControls['selfFirstName'].value"
                                                        class="form-control " formControlName="selfFirstName"
                                                        name="selfFirstName" readonly />
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob"> Last Name</label>
                                                    <input type="text" [value]="valMemControls['selfLastName'].value"
                                                        name="selfLastName" class="form-control "
                                                        formControlName="selfLastName" readonly />
                                                </div>

                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="selfDOB"
                                                        formControlName="selfDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent "
                                                        [ngClass]="{'border border-danger': ((valMemControls['selfDOB'].touched ) && !valMemControls['selfDOB'].valid)}"  [max]="maxDate" [(ngModel)]="defaultDate" />
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['selfDOB'].touched) && !valMemControls['selfDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="selfOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="dob">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div>     -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                    style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection " name="selfHeightFeet"
                                                   
                                                        formControlName="selfHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['selfHeightFeet'].touched) && !valMemControls['selfHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['selfHeightFeet'].touched ) && !valMemControls['selfHeightFeet'].valid)">
                                                        Height is required.
                                                    </Span>
                                                    <select class="form-select height_selection" name="selfHeightInches"
                                                        
                                                        formControlName="selfHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['selfHeightInches'].touched) && !valMemControls['selfHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['selfHeightInches'].touched ) && !valMemControls['selfHeightInches'].valid)">
                                                        Height is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="selfWeight"
                                                        formControlName="selfWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['selfWeight'].touched ) && !valMemControls['selfWeight'].valid)}"   onkeypress="if(this.value.length=='3') return false"/>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['selfWeight'].touched) && !valMemControls['selfWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6"></div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.spouseSelected">
                                                Spouse's Information</h5>
                                            <div class="row" *ngIf="healthData.spouseSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="spouseFirstName"
                                                        formControlName="spouseFirstName"
                                                        placeholder="Spouse's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['spouseFirstName'].touched  || submitted) && !valMemControls['spouseFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['spouseFirstName'].touched) && !valMemControls['spouseFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="spouseLastName"
                                                        formControlName="spouseLastName"
                                                        placeholder="Spouse's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['spouseLastName'].touched  || submitted) && !valMemControls['spouseLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['spouseLastName'].touched) && !valMemControls['spouseLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="spouseDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['spouseDOB'].touched  || submitted) && !valMemControls['spouseDOB'].valid)}"  [max]="maxDate" />
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['spouseDOB'].touched) && !valMemControls['spouseDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="spouseOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="spouseHeightFeet"
                                                        formControlName="spouseHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['spouseHeightFeet'].touched  || submitted) && !valMemControls['spouseHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['spouseHeightFeet'].touched  || submitted) && !valMemControls['spouseHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection"
                                                        name="spouseHeightInches" formControlName="spouseHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['spouseHeightInches'].touched  || submitted) && !valMemControls['spouseHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['spouseHeightInches'].touched  || submitted) && !valMemControls['spouseHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="spouseWeight"
                                                        formControlName="spouseWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['spouseWeight'].touched  || submitted) && !valMemControls['spouseWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['spouseWeight'].touched  || submitted) && !valMemControls['spouseWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.firstSone">
                                                1st Son's Information</h5>
                                            <div class="row" *ngIf="healthData.firstSone">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Son First Name</label>
                                                    <input type="text" class="form-control" name="son1FirstName"
                                                        formControlName="son1FirstName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son1FirstName'].touched  || submitted) && !valMemControls['son1FirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son1FirstName'].touched) && !valMemControls['son1FirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Son Last Name</label>
                                                    <input type="text" class="form-control" name="son1LastName"
                                                        formControlName="son1LastName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son1LastName'].touched  || submitted) && !valMemControls['son1LastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son1LastName'].touched) && !valMemControls['son1LastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="son1DOB"
                                                        formControlName="son1DOB"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son1DOB'].touched ) && !valMemControls['son1DOB'].valid)}"  [max]="maxDate">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son1DOB'].touched  || submitted) && !valMemControls['son1DOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="son1Occupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="son1HeightFeet"
                                                        formControlName="son1HeightFeet">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <select class="form-select height_selection" name="son1HeightInches"
                                                        formControlName="son1HeightInches">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" placeholder="Enter KGs"
                                                        name="son1Weight" formControlName="son1Weight"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son1Weight'].touched  || submitted) && !valMemControls['son1Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son1Weight'].touched) && !valMemControls['son1Weight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.secondSon">
                                                2nd Son's Information</h5>
                                            <div class="row" *ngIf="healthData.secondSon">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Son First Name</label>
                                                    <input type="text" class="form-control" name="son2FirstName"
                                                        formControlName="son2FirstName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son2FirstName'].touched  || submitted) && !valMemControls['son2FirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son2FirstName'].touched) && !valMemControls['son2FirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Son Last Name</label>
                                                    <input type="text" class="form-control" name="son2LastName"
                                                        formControlName="son2LastName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son2LastName'].touched  || submitted) && !valMemControls['son2LastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son2LastName'].touched) && !valMemControls['son2LastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="son2DOB"
                                                        formControlName="son2DOB"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son2DOB'].touched ) && !valMemControls['son2DOB'].valid)}"  [max]="maxDate">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son2DOB'].touched  || submitted) && !valMemControls['son2DOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example"  formControlName="son2Occupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="son2HeightFeet"
                                                        formControlName="son2HeightFeet">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <select class="form-select height_selection" name="son2HeightInches"
                                                        formControlName="son2HeightInches">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" placeholder="Enter KGs"
                                                        name="son2Weight" formControlName="son2Weight"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son2Weight'].touched  || submitted) && !valMemControls['son2Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son2Weight'].touched) && !valMemControls['son2Weight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.thirdSon">
                                                3rd Son's Information</h5>
                                            <div class="row" *ngIf="healthData.thirdSon">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Son First Name</label>
                                                    <input type="text" class="form-control" name="son3FirstName"
                                                        formControlName="son3FirstName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son3FirstName'].touched  || submitted) && !valMemControls['son3FirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son3FirstName'].touched) && !valMemControls['son3FirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Son Last Name</label>
                                                    <input type="text" class="form-control" name="son3LastName"
                                                        formControlName="son3LastName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son3LastName'].touched  || submitted) && !valMemControls['son3LastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son3LastName'].touched) && !valMemControls['son3LastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="son3DOB"
                                                        formControlName="son3DOB"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son3DOB'].touched ) && !valMemControls['son3DOB'].valid)}"  [max]="maxDate">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son3DOB'].touched  || submitted) && !valMemControls['son3DOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="son3Occupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="son3HeightFeet"
                                                        formControlName="son3HeightFeet">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <select class="form-select height_selection" name="son3HeightFeet"
                                                        formControlName="son3HeightFeet">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" placeholder="Enter KGs"
                                                        name="son3Weight" formControlName="son3Weight"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son3Weight'].touched  || submitted) && !valMemControls['son3Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son3Weight'].touched) && !valMemControls['son3Weight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.fourthSon">
                                                4th Son's Information</h5>
                                            <div class="row" *ngIf="healthData.fourthSon">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Son First Name</label>
                                                    <input type="text" class="form-control" name="son4FirstName"
                                                        formControlName="son4FirstName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son4FirstName'].touched  || submitted) && !valMemControls['son4FirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son4FirstName'].touched) && !valMemControls['son4FirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Son Last Name</label>
                                                    <input type="text" class="form-control" name="son4LastName"
                                                        formControlName="son4LastName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son4LastName'].touched  || submitted) && !valMemControls['son4LastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son4LastName'].touched) && !valMemControls['son4LastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="son4DOB"
                                                        formControlName="son4DOB"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son4DOB'].touched ) && !valMemControls['son4DOB'].valid)}"  [max]="maxDate">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son4DOB'].touched  || submitted) && !valMemControls['son4DOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="son4Occupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="son4HeightFeet"
                                                        formControlName="son4HeightFeet">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <select class="form-select height_selection" name="son4HeightInches"
                                                        formControlName="son4HeightInches">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" placeholder="Enter KGs"
                                                        name="son4Weight" formControlName="son4Weight"
                                                        [ngClass]="{'border border-danger': ((valMemControls['son4Weight'].touched  || submitted) && !valMemControls['son4Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['son4Weight'].touched) && !valMemControls['son4Weight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.firstDaughter">
                                                1st Daughter Information</h5>
                                            <div class="row" *ngIf="healthData.firstDaughter">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Daughter First
                                                        Name</label>
                                                    <input type="text" class="form-control" name="dau1FirstName"
                                                        formControlName="dau1FirstName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau1FirstName'].touched  || submitted) && !valMemControls['dau1FirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau1FirstName'].touched) && !valMemControls['dau1FirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Daughter Last
                                                        Name</label>
                                                    <input type="text" class="form-control" name="dau1LastName"
                                                        formControlName="dau1LastName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau1LastName'].touched  || submitted) && !valMemControls['dau1LastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau1LastName'].touched) && !valMemControls['dau1LastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="dau1DOB"
                                                        formControlName="dau1DOB"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau1DOB'].touched ) && !valMemControls['dau1DOB'].valid)}"  [max]="maxDate">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau1DOB'].touched) && !valMemControls['dau1DOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="dau1Occupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="dau1HeightFeet"
                                                        formControlName="dau1HeightFeet">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <select class="form-select height_selection" name="dau1HeightInches"
                                                        formControlName="dau1HeightInches">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" placeholder="Enter KGs"
                                                        name="dau1Weight" formControlName="dau1Weight"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau1Weight'].touched ) && !valMemControls['dau1Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau1Weight'].touched) && !valMemControls['dau1Weight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.secondDaughter">
                                                2nd Daughter Information</h5>
                                            <div class="row" *ngIf="healthData.secondDaughter">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Daughter First
                                                        Name</label>
                                                    <input type="text" class="form-control" name="dau2FirstName"
                                                        formControlName="dau2FirstName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau2FirstName'].touched  || submitted) && !valMemControls['dau2FirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau2FirstName'].touched) && !valMemControls['dau2FirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Daughter Last
                                                        Name</label>
                                                    <input type="text" class="form-control" name="dau2LastName"
                                                        formControlName="dau2LastName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau2LastName'].touched  || submitted) && !valMemControls['dau2LastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau2LastName'].touched) && !valMemControls['dau2LastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="dau2DOB"
                                                        formControlName="dau2DOB"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau2DOB'].touched ) && !valMemControls['dau2DOB'].valid)}"  [max]="maxDate">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau2DOB'].touched) && !valMemControls['dau2DOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example"  formControlName="dau2Occupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="dau2HeightFeet"
                                                        formControlName="dau2HeightFeet">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <select class="form-select height_selection" name="dau2HeightInches"
                                                        formControlName="dau2HeightInches">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" placeholder="Enter KGs"
                                                        name="dau2Weight" formControlName="dau2Weight"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau2Weight'].touched ) && !valMemControls['dau2Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau2Weight'].touched) && !valMemControls['dau2Weight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.thirdDaughter">
                                                3rd Daughter Information</h5>
                                            <div class="row" *ngIf="healthData.thirdDaughter">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Daughter First
                                                        Name</label>
                                                    <input type="text" class="form-control" name="dau3FirstName"
                                                        formControlName="dau3FirstName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau3FirstName'].touched  || submitted) && !valMemControls['dau3FirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau3FirstName'].touched) && !valMemControls['dau3FirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Daughter Last
                                                        Name</label>
                                                    <input type="text" class="form-control" name="dau3LastName"
                                                        formControlName="dau3LastName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau3LastName'].touched  || submitted) && !valMemControls['dau3LastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau3LastName'].touched) && !valMemControls['dau3LastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="dau3DOB"
                                                        formControlName="dau3DOB"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau3DOB'].touched ) && !valMemControls['dau3DOB'].valid)}"  [max]="maxDate">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau3DOB'].touched) && !valMemControls['dau3DOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example"  formControlName="dau3Occupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="dau3HeightFeet"
                                                        formControlName="dau3HeightFeet">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <select class="form-select height_selection" name="dau3HeightInches"
                                                        formControlName="dau3HeightInches">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" placeholder="Enter KGs"
                                                        name="dau3Weight" formControlName="dau3Weight"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau3Weight'].touched ) && !valMemControls['dau3Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau3Weight'].touched) && !valMemControls['dau3Weight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.fourthDaughter">
                                                4th Daughter Information</h5>
                                            <div class="row" *ngIf="healthData.fourthDaughter">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Daughter First
                                                        Name</label>
                                                    <input type="text" class="form-control" name="dau4FirstName"
                                                        formControlName="dau4FirstName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau4FirstName'].touched  || submitted) && !valMemControls['dau4FirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau4FirstName'].touched) && !valMemControls['dau4FirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Enter Daughter Last
                                                        Name</label>
                                                    <input type="text" class="form-control" name="dau4LastName"
                                                        formControlName="dau4LastName"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau4LastName'].touched  || submitted) && !valMemControls['dau4LastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau4LastName'].touched) && !valMemControls['dau4LastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="name">Date of Birth</label>
                                                    <input type="date" class="form-control" name="dau4DOB"
                                                        formControlName="dau4DOB"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau4DOB'].touched ) && !valMemControls['dau4DOB'].valid)}"  [max]="maxDate">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau4DOB'].touched) && !valMemControls['dau4DOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example"  formControlName="dau4Occupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="dau4HeightFeet"
                                                        formControlName="dau4HeightFeet">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <select class="form-select height_selection" name="dau4HeightInches"
                                                        formControlName="dau4HeightInches">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" placeholder="Enter KGs"
                                                        name="dau4Weight" formControlName="dau4Weight"
                                                        [ngClass]="{'border border-danger': ((valMemControls['dau4Weight'].touched ) && !valMemControls['dau4Weight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['dau4Weight'].touched) && !valMemControls['dau4Weight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>
                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.fatherSelected">
                                                Father's Information</h5>
                                            <div class="row" *ngIf="healthData.fatherSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="fatherFirstName"
                                                        formControlName="fatherFirstName"
                                                        placeholder="Father's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherFirstName'].touched  || submitted) && !valMemControls['fatherFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherFirstName'].touched) && !valMemControls['fatherFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="fatherLastName"
                                                        formControlName="fatherLastName"
                                                        placeholder="Father's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLastName'].touched  || submitted) && !valMemControls['fatherLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherLastName'].touched) && !valMemControls['fatherLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="fatherDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherDOB'].touched  || submitted) && !valMemControls['fatherDOB'].valid)}"  [max]="maxDate"/>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherDOB'].touched) && !valMemControls['fatherDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example"  formControlName="FatherOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="fatherHeightFeet"
                                                        formControlName="fatherHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherHeightFeet'].touched  || submitted) && !valMemControls['fatherHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherHeightFeet'].touched  || submitted) && !valMemControls['fatherHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection"
                                                        name="fatherHeightInches" formControlName="fatherHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherHeightInches'].touched  || submitted) && !valMemControls['fatherHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherHeightInches'].touched  || submitted) && !valMemControls['fatherHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="fatherWeight"
                                                        formControlName="fatherWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherWeight'].touched  || submitted) && !valMemControls['fatherWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherWeight'].touched  || submitted) && !valMemControls['fatherWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.motherSelected">
                                                Mother's Information</h5>
                                            <div class="row" *ngIf="healthData.motherSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="motherFirstName"
                                                        formControlName="motherFirstName"
                                                        placeholder="Mother's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherFirstName'].touched  || submitted) && !valMemControls['motherFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherFirstName'].touched) && !valMemControls['motherFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="motherName"
                                                        formControlName="motherLastName" placeholder="Mother's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLastName'].touched  || submitted) && !valMemControls['motherLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherLastName'].touched) && !valMemControls['motherLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="motherDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherDOB'].touched  || submitted) && !valMemControls['motherDOB'].valid)}"  [max]="maxDate" />
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherDOB'].touched) && !valMemControls['motherDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example " formControlName="MotherOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="motherHeightFeet"
                                                        formControlName="motherHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherHeightFeet'].touched  || submitted) && !valMemControls['motherHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherHeightFeet'].touched  || submitted) && !valMemControls['motherHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection"
                                                        name="motherHeightInches" formControlName="motherHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherHeightInches'].touched  || submitted) && !valMemControls['motherHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherHeightInches'].touched  || submitted) && !valMemControls['motherHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="motherWeight"
                                                        formControlName="motherWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherWeight'].touched  || submitted) && !valMemControls['motherWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherWeight'].touched  || submitted) && !valMemControls['motherWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.grandPaSelected">
                                                Grand Father's Information</h5>
                                            <div class="row" *ngIf="healthData.grandPaSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="grandPaFirstName"
                                                        formControlName="grandPaFirstName"
                                                        placeholder="Grand Father's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandPaFirstName'].touched  || submitted) && !valMemControls['grandPaFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandPaFirstName'].touched) && !valMemControls['grandPaFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="grandPaLastName"
                                                        formControlName="grandPaLastName"
                                                        placeholder="Grand Father's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandPaLastName'].touched  || submitted) && !valMemControls['grandPaLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandPaLastName'].touched) && !valMemControls['grandPaLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="grandPaDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandPaDOB'].touched  || submitted) && !valMemControls['grandPaDOB'].valid)}"  [max]="maxDate"/>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandPaDOB'].touched) && !valMemControls['grandPaDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="grandPaOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection"
                                                        name="grandPaHeightFeet" formControlName="grandPaHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandPaHeightFeet'].touched  || submitted) && !valMemControls['grandPaHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandPaHeightFeet'].touched  || submitted) && !valMemControls['grandPaHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection"
                                                        name="grandPaHeightInches" formControlName="grandPaHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandPaHeightInches'].touched  || submitted) && !valMemControls['grandPaHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandPaHeightInches'].touched  || submitted) && !valMemControls['grandPaHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="grandPaWeight"
                                                        formControlName="grandPaWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandPaWeight'].touched  || submitted) && !valMemControls['grandPaWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandPaWeight'].touched  || submitted) && !valMemControls['grandPaWeight'].valid)" >
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.grandMaSelected">
                                                Grand Mother's Information</h5>
                                            <div class="row" *ngIf="healthData.grandMaSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="grandMaFirstName"
                                                        formControlName="grandMaFirstName"
                                                        placeholder="Grand Mother's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandMaFirstName'].touched  || submitted) && !valMemControls['grandMaFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandMaFirstName'].touched) && !valMemControls['grandMaFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="grandMaLastName"
                                                        formControlName="grandMaLastName"
                                                        placeholder="Grand Mother's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandMaLastName'].touched  || submitted) && !valMemControls['grandMaLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandMaLastName'].touched) && !valMemControls['grandMaLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="grandMaDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandMaDOB'].touched  || submitted) && !valMemControls['grandMaDOB'].valid)}"  [max]="maxDate"/>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandMaDOB'].touched) && !valMemControls['grandMaDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="grandMaOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection"
                                                        name="grandMaHeightFeet" formControlName="grandMaHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandMaHeightFeet'].touched  || submitted) && !valMemControls['grandMaHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandMaHeightFeet'].touched  || submitted) && !valMemControls['grandMaHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection"
                                                        name="grandMaHeightInches" formControlName="grandMaHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandMaHeightInches'].touched  || submitted) && !valMemControls['grandMaHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandMaHeightInches'].touched  || submitted) && !valMemControls['grandMaHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="grandMaWeight"
                                                        formControlName="grandMaWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['grandMaWeight'].touched  || submitted) && !valMemControls['grandMaWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['grandMaWeight'].touched  || submitted) && !valMemControls['grandMaWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.fatherLawSelected">
                                                Father-in-Law's Information</h5>
                                            <div class="row" *ngIf="healthData.fatherLawSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="fatherLawFirstName"
                                                        formControlName="fatherLawFirstName"
                                                        placeholder="Father-in-Law's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLawFirstName'].touched  || submitted) && !valMemControls['fatherLawFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherLawFirstName'].touched) && !valMemControls['fatherLawFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="fatherLawLastName"
                                                        formControlName="fatherLawLastName"
                                                        placeholder="Father-in-Law's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLawLastName'].touched  || submitted) && !valMemControls['fatherLawLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherLawLastName'].touched) && !valMemControls['fatherLawLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="fatherLawDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLawDOB'].touched  || submitted) && !valMemControls['fatherLawDOB'].valid)}"  [max]="maxDate"/>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherLawDOB'].touched) && !valMemControls['fatherLawDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="fatherLawOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection"
                                                        name="fatherLawHeightFeet" formControlName="fatherLawHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLawHeightFeet'].touched  || submitted) && !valMemControls['fatherLawHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherLawHeightFeet'].touched  || submitted) && !valMemControls['fatherLawHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection"
                                                        name="fatherLawHeightInches"
                                                        formControlName="fatherLawHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLawHeightInches'].touched  || submitted) && !valMemControls['fatherLawHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>

                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherLawHeightInches'].touched  || submitted) && !valMemControls['fatherLawHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="fatherLawWeight"
                                                        formControlName="fatherLawWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['fatherLawWeight'].touched  || submitted) && !valMemControls['fatherLawWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['fatherLawWeight'].touched  || submitted) && !valMemControls['fatherLawWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                            <h5 class="text-start mt-5 member_heading"
                                                *ngIf="healthData.MotherLawSelected">
                                                Mother-in-Law's Information</h5>
                                            <div class="row" *ngIf="healthData.MotherLawSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="motherLawFirstName"
                                                        formControlName="motherLawFirstName"
                                                        placeholder="Mother-in-Law's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLawFirstName'].touched  || submitted) && !valMemControls['motherLawFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherLawFirstName'].touched) && !valMemControls['motherLawFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="motherLawLastName"
                                                        formControlName="motherLawLastName"
                                                        placeholder="Mother-in-Law's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLawLastName'].touched  || submitted) && !valMemControls['motherLawLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherLawLastName'].touched) && !valMemControls['motherLawLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="motherLawDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLawDOB'].touched  || submitted) && !valMemControls['motherLawDOB'].valid)}" [max]="maxDate" />
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherLawDOB'].touched) && !valMemControls['motherLawDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="motherLawOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection"
                                                        name="motherLawHeightFeet" formControlName="motherLawHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLawHeightFeet'].touched  || submitted) && !valMemControls['motherLawHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>

                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherLawHeightFeet'].touched  || submitted) && !valMemControls['motherLawHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection"
                                                        name="motherLawHeightInches"
                                                        formControlName="motherLawHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLawHeightInches'].touched  || submitted) && !valMemControls['motherLawHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>


                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherLawHeightInches'].touched  || submitted) && !valMemControls['motherLawHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="motherLawWeight"
                                                        formControlName="motherLawWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['motherLawWeight'].touched  || submitted) && !valMemControls['motherLawWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['motherLawWeight'].touched  || submitted) && !valMemControls['motherLawWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                            <h5 class="text-start mt-5 member_heading" *ngIf="healthData.uncleSelected">
                                                Uncle's Information</h5>
                                            <div class="row" *ngIf="healthData.uncleSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="uncleFirstName"
                                                        formControlName="uncleFirstName"
                                                        placeholder="Uncle's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['uncleFirstName'].touched  || submitted) && !valMemControls['uncleFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['uncleFirstName'].touched) && !valMemControls['uncleFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="uncleLastName"
                                                        formControlName="uncleLastName" placeholder="Uncle's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['uncleLastName'].touched  || submitted) && !valMemControls['uncleLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['uncleLastName'].touched) && !valMemControls['uncleLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="uncleDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['uncleDOB'].touched  || submitted) && !valMemControls['uncleDOB'].valid)}"  [max]="maxDate"/>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['uncleDOB'].touched) && !valMemControls['uncleDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="uncleOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="uncleHeightFeet"
                                                        formControlName="uncleHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['uncleHeightFeet'].touched  || submitted) && !valMemControls['uncleHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>


                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['uncleHeightFeet'].touched  || submitted) && !valMemControls['uncleHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection"
                                                        name="uncleHeightInches" formControlName="uncleHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['uncleHeightInches'].touched  || submitted) && !valMemControls['uncleHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>



                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['uncleHeightInches'].touched  || submitted) && !valMemControls['uncleHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="uncleWeight"
                                                        formControlName="uncleWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['uncleWeight'].touched  || submitted) && !valMemControls['uncleWeight'].valid)}"  onkeypress="if(this.value.length=='3') return false">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['uncleWeight'].touched  || submitted) && !valMemControls['uncleWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                            <h5 class="text-start mt-5 member_heading" *ngIf="healthData.auntSelected">
                                                Aunt's Information</h5>
                                            <div class="row" *ngIf="healthData.auntSelected">
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter First Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="auntFirstName"
                                                        formControlName="auntFirstName" placeholder="Aunt's First Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['auntFirstName'].touched  || submitted) && !valMemControls['auntFirstName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['auntFirstName'].touched) && !valMemControls['auntFirstName'].valid)">
                                                        First Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="sname">Enter Last Name</label>
                                                    <input type="text" class="form-control"
                                                        (keypress)="alphabetOnly($event)" name="auntLastName"
                                                        formControlName="auntLastName" placeholder="Aunt's Last Name"
                                                        [ngClass]="{'border border-danger': ((valMemControls['auntLastName'].touched  || submitted) && !valMemControls['auntLastName'].valid)}">
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['auntLastName'].touched) && !valMemControls['auntLastName'].valid)">
                                                        Last Name is required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="dob">Date of birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="auntDOB" autocomplete="off"
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((valMemControls['auntDOB'].touched  || submitted) && !valMemControls['auntDOB'].valid)}"  [max]="maxDate"/>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['auntDOB'].touched) && !valMemControls['auntDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <!-- <div class="mt-3 col-md-6">
                                                    <label class="lb required"    style="font-weight: 700 !important; font-size: 14px !important;" for="name">Occupation</label>
                                                    <input type="text" class="form-control">
                                                </div> -->
                                                <div class="mt-3 col-md-6">
                                                    <label for="name" class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Occupation</label>
                                                        <select class="form-select" aria-label="Default select example" formControlName="auntOccupation">
                                                            <option selected>Your Occupation</option>
                                                            <option value="Salaried">Salaried</option>
                                                            <option value="Self Employed">Self Employed</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Others">Others</option>
                                                            <option value="Housewife">Housewife</option>
                                                            <option value="Politician">Politician</option>
                                                          </select>
                                                          


                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="height">Height</label>
                                                    <select class="form-select height_selection" name="auntHeightFeet"
                                                        formControlName="auntHeightFeet"
                                                        [ngClass]="{'border border-danger': ((valMemControls['auntHeightFeet'].touched  || submitted) && !valMemControls['auntHeightFeet'].valid)}">
                                                        <option value="select">Feets</option>

                                                        <option *ngFor="let feet of feetList" value="{{feet}}">{{feet}}
                                                            Feet</option>
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['auntHeightFeet'].touched  || submitted) && !valMemControls['auntHeightFeet'].valid)">
                                                        Height required.
                                                    </Span>
                                                    <select class="form-select height_selection" name="auntHeightInches"
                                                        formControlName="auntHeightInches"
                                                        [ngClass]="{'border border-danger': ((valMemControls['auntHeightInches'].touched  || submitted) && !valMemControls['auntHeightInches'].valid)}">
                                                        <option value="select">Inches</option>
                                                        <option *ngFor="let inch of inchList" value="{{inch}}">{{inch}}
                                                            Inch</option>


                                                        <!-- <option value="1">1 Inch</option>
                                                        <option value="2">2 Inch</option>
                                                        <option value="3">3 Inch</option>
                                                        <option value="4">4 Inch</option>
                                                        <option value="5">5 Inch</option>
                                                        <option value="6">6 Inch</option>
                                                        <option value="7">7 Inch</option> -->
                                                    </select>
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['auntHeightInches'].touched  || submitted) && !valMemControls['auntHeightInches'].valid)">
                                                        Height required.
                                                    </Span>
                                                </div>
                                                <div class="mt-3 col-md-6">
                                                    <label class="lb required"
                                                        style="font-weight: 700 !important; font-size: 14px !important;"
                                                        for="weight">Weight (Kg)</label>
                                                    <input type="number" class="form-control" name="auntWeight"
                                                        formControlName="auntWeight" placeholder="Enter KGs"
                                                        [ngClass]="{'border border-danger': ((valMemControls['auntWeight'].touched  || submitted) && !valMemControls['auntWeight'].valid)}  "  onkeypress="if(this.value.length=='3') return false">  
                                                    <Span class="text-danger"
                                                        *ngIf="((valMemControls['auntWeight'].touched  || submitted) && !valMemControls['auntWeight'].valid)">
                                                        Weight is required.
                                                    </Span>
                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                    <!-- </div> -->
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mt-5  text-start">
                                        <button class="btn btn-primary-outline btn-outline"
                                            (click)="backFunction('address')">Back</button>
                                    </div>
                                    <div class="col-md-6 mt-5  text-end">
                                        <button class="btn btn-primary" [disabled]="insurerForm.invalid"
                                            (click)="InsuranceDetails('nominee')">Next</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div id="nominee" class="col-12 c-pl c-pr" *ngIf="isnomineeDetails">
                            <ng-container>
                                <div class="card p-5 row br-top-0">
                                    <form id="kt_vehicle_details_form" class="form" [formGroup]="nomineeForm"
                                        novalidate="novalidate">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="input-label required" for="name"
                                                    style="font-weight: 700 !important; font-size: 14px !important;">Nominee
                                                    First Name</label>
                                                <input type="text" class="form-control" name="nomineeFirstName"
                                                    formControlName="nomineeFirstName"
                                                    [ngClass]="{'border border-danger': ((valNomControls['nomineeFirstName'].touched ) && !valNomControls['nomineeFirstName'].valid)}">
                                                <Span class="text-danger"
                                                    *ngIf="((valNomControls['nomineeFirstName'].touched  || submitted) && !valNomControls['nomineeFirstName'].valid)">
                                                    First Name is required.
                                                </Span>
                                            </div>
                                            <div class="col-md-6">
                                                <label class="input-label required" for="name"
                                                    style="font-weight: 700 !important; font-size: 14px !important;">Nominee
                                                    Last Name</label>
                                                <input type="text" class="form-control" name="nomineeLastName"
                                                    formControlName="nomineeLastName"
                                                    [ngClass]="{'border border-danger': ((valNomControls['nomineeLastName'].touched ) && !valNomControls['nomineeLastName'].valid)}">
                                                <Span class="text-danger"
                                                    *ngIf="((valNomControls['nomineeLastName'].touched  || submitted) && !valNomControls['nomineeLastName'].valid)">
                                                    Last Name is required.
                                                </Span>
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label class="input-label required" for="name"
                                                    style="font-weight: 700 !important; font-size: 14px !important;">Relationship
                                                    with proposer</label>
                                                <select class="form-select" name="nomineeRelationWithProposer"
                                                    formControlName="nomineeRelationWithProposer" style="height:58%">
                                                    <option value="select">Select</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Mother">Mother</option>
                                                    <option value="Son">Son</option>
                                                    <option value="Daughter">Daughter</option>
                                                    <option value="Uncle">Uncle</option>
                                                    <option value="Aunt">Aunt</option>
                                                    <option value="Brother">Brother</option>
                                                    <option value="Sister">Sister</option>
                                                    <option value="Spouse">Spouse</option>
                                                    <option value="Grand Son">Grand Son</option>
                                                    <option value="Grand Daughter">Grand Daughter</option>
                                                </select>
                                            </div>

                                            <div class="col-md-6 mt-4">
                                                <label class="input-label required" for="name"
                                                    style="font-weight: 700 !important; font-size: 14px !important;">DOB(DD-MM-YYYY)</label>
                                                <input type="date" class="form-control" name="nomineeDOB"
                                                    formControlName="nomineeDOB">
                                                <!-- <select class="form-select" name="nomineeRelationWithProposer"
                                                    formControlName="nomineeAge" style="height:58%">
                                                    <option value="select" Selected>Select Age</option>
                                                    <label class="input-label" for="name">(DD-MM-YYYY)</label>                                                  
                                                    <option *ngFor="let age of ageList" value="{{age}}">{{age}}</option>                                                    
                                                </select> -->
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <div class="form-group">
                                                    <label class="required lb" for="gender"
                                                        style="font-weight: 700 !important; font-size: 14px !important;">Gender</label>
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <input type="radio" name="proposerGender"
                                                                class="radio_control" formControlName="proposerGender"
                                                                value="male" /> Male
                                                        </div>
                                                        <div class="col-4">
                                                            <input type="radio" name="proposerGender"
                                                                class="radio_control" formControlName="proposerGender"
                                                                value="female" />
                                                            Female
                                                        </div>
                                                        <div class="col-4">
                                                            <input type="radio" name="proposerGender"
                                                                class="radio_control" formControlName="proposerGender"
                                                                value="other" /> Other
                                                        </div>
                                                    </div>

                                                    <Span class="text-danger"
                                                        *ngIf="((valPropControls['proposerGender'].touched ) && !valPropControls['proposerGender'].valid)">
                                                        Gender is required.
                                                    </Span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <!-- <div class="col">
                                        <label class="input-label" for="name">(DD-MM-YYYY)</label>
                                        <input type="date" class="form-control" name="nomineeDOB" formControlName="nomineeDOB">
                                    </div> -->
                                            <!-- <div class="col">
                                        <label class="input-label" for="name">Contact Number</label>
                                        <input type="text" class="form-control" name="nomineeContactNumber" formControlName="nomineeContactNumber"
                                        [ngClass]="{'border border-danger': ((valNomControls['nomineeContactNumber'].touched ) && !valNomControls['nomineeContactNumber'].valid)}" >
                                        <Span class="text-danger"
                                            *ngIf="((valNomControls['nomineeContactNumber'].touched  || submitted) && !valNomControls['nomineeContactNumber'].valid)">
                                            Contact number is required.
                                        </Span>
                                    </div> -->
                                        </div>
                                    </form>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mt-5  text-start">
                                        <button class="btn btn-primary-outline btn-outline"
                                            (click)="backFunction('member')">Back</button>
                                    </div>
                                    <div class="col-md-6 mt-5  text-end">
                                        <button class="btn btn-primary" [disabled]="insurerForm.invalid"
                                            (click)="InsuranceDetails('medical')" > Next</button>
                                    </div>
                                </div>

                            </ng-container>
                        </div>
                        <div id="medical" class="col-12 c-pl c-pr" *ngIf="ismedicalDetails">
                            <ng-container>
                                <div class="card p-5 row br-top-0">
                                    <div class="details_card">
                                        <form class="form needs-validation" [formGroup]="nomineeForm" novalidate="">
                                            <h4 class="text-start">Help us know the medical condition, if any</h4>



                                            <ng-container *ngFor="let item of insurerDetails">
                                                <div class="collapsible-panel rounded-top1">
                                                    <div class="panel-header rounded-top1"
                                                        (click)="toggleMembersQuestion(item.for)">
                                                        <span class="d-flex align-items-center">{{item.firstName+'
                                                            '+item.lastName}}</span>
                                                        <span class="collapse-icon"> <img
                                                                src="../../assets/svgIcon/{{ item.for == this.activePanel ? 'ChevronUp' : 'ChevronDown' }}.svg"
                                                                alt=""></span>
                                                    </div>
                                                    <div class="panel-content collapsed mh-400-scroll"
                                                        [ngClass]="{ 'expanded': item.for == this.activePanel}">
                                                        <div class="row">
                                                            <div
                                                                class="col-12 py-1 border-bottom d-flex align-items-center">
                                                                <app-dynamic-components [id]="item.for"
                                                                    (dataEvent)="getSavedData($event)"
                                                                    [required_input_fields]="item.medicalQuestion"
                                                                    [questionFor]="item.for"></app-dynamic-components>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>





                                        </form>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-6 mt-5  text-start">
                                        <button class="btn btn-primary-outline btn-outline"
                                            (click)="backFunction('nominee')">Back</button>
                                    </div>
                                    <div class="col-md-6 mt-5  text-end">

                                        <button class="btn btn-primary" (click)="continue()">Continue</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container insurance_details" *ngIf="insuranceDetailsScreen_two">

        <!-- <div class="container insurance_details"> -->
        <app-health-summary [healthObj]="royalHealthObj" (gotoEvent)="paySecurly()"></app-health-summary>

        <!-- <div class="container mt-5">
        <div class="card p-2">
            <h4><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                    class="cursor-pointer">&nbsp;&nbsp;Congratulations! your Insurance processed successfully!</h4>
        </div>&nbsp;&nbsp;
        <div class="mt-3 row mb-5">
            <div class="col-8 ">
                <div class="card p-5">
                    <div class="m-auto">
                        <div class="justify-content-center d-flex">
                            <img src="../../assets/svgIcon/sucess.svg" alt="">
                        </div>
                        <div>
                            <h3>My Health Suraksha</h3>
                            <h1 class="text-center" style="color:#3A8247">₹14 Lakh</h1>
                        </div>
                        <div class="card" style="background: rgba(58, 130, 71, 0.1);">
                            <h6 class="p-2">Download Receipt</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card p-5">
                    <div class="justify-content-between" style="display: flex;">
                        <div>
                            <h4 class="text-start"> Insured members</h4>
                        </div>
                    </div>
                    <div class="row" style="display: flex;">
                        <div class="d-flex col">
                            <div>
                                <p class="text-start" style="color:#848493; ">Self</p>
                                <h5>32 Year</h5>
                            </div>
                        </div>
                        <div class="d-flex col">
                            <div>
                                <p style="color:#848493; ;">Spouse’s</p>
                                <h5>28 Year</h5>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="display: flex;">
                        <div class="d-flex col">
                            <div>
                                <p class="text-start" style="color:#848493; ">Self</p>
                                <h5>32 Year</h5>
                            </div>
                        </div>
                        <div class="d-flex col">
                            <div>
                                <p style="color:#848493; ;">Spouse’s</p>
                                <h5>28 Year</h5>
                            </div>
                        </div>
                    </div>
                </div><br>
                <div class="card col">
                    <h4 class="mt-5">Premium Breakup</h4>
                    <br>
                    <div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">InIDVidual Health Insurance</p>
                            <h6 class="float-end">₹1,000</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Net Premium</p>
                            <h6 class="float-end">₹1,000</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Add GST(18%)</p>
                            <h6 class="float-end">₹180</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Final Premium</p>
                            <h6 class="float-end">₹1,180</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    </div>
    <!-- <div class="container mt-5">
        <div class="card p-2">
            <h4><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                    class="cursor-pointer">&nbsp;&nbsp;Congratulations! your Insurance processed successfully!</h4>
        </div>&nbsp;&nbsp;
        <div class="mt-3 row mb-5">
            <div class="col-8 ">
                <div class="card p-5">
                    <div class="m-auto">
                        <div class="justify-content-center d-flex">
                            <img src="../../assets/svgIcon/sucess.svg" alt="">
                        </div>
                        <div>
                            <h3>My Health Suraksha</h3>
                            <h1 class="text-center" style="color:#3A8247">₹14 Lakh</h1>
                        </div>
                        <div class="card" style="background: rgba(58, 130, 71, 0.1);">
                            <h6 class="p-2">Download Receipt</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card p-5">
                    <div class="justify-content-between" style="display: flex;">
                        <div>
                            <h4 class="text-start"> Insured members</h4>
                        </div>
                    </div>
                    <div class="row" style="display: flex;">
                        <div class="d-flex col">
                            <div>
                                <p class="text-start" style="color:#848493; ">Self</p>
                                <h5>32 Year</h5>
                            </div>
                        </div>
                        <div class="d-flex col">
                            <div>
                                <p style="color:#848493; ;">Spouse’s</p>
                                <h5>28 Year</h5>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="display: flex;">
                        <div class="d-flex col">
                            <div>
                                <p class="text-start" style="color:#848493; ">Self</p>
                                <h5>32 Year</h5>
                            </div>
                        </div>
                        <div class="d-flex col">
                            <div>
                                <p style="color:#848493; ;">Spouse’s</p>
                                <h5>28 Year</h5>
                            </div>
                        </div>
                    </div>
                </div><br>
                <div class="card col">
                    <h4 class="mt-5">Premium Breakup</h4>
                    <br>
                    <div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">InIDVidual Health Insurance</p>
                            <h6 class="float-end">₹1,000</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Net Premium</p>
                            <h6 class="float-end">₹1,000</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Add GST(18%)</p>
                            <h6 class="float-end">₹180</h6>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="font-body">Final Premium</p>
                            <h6 class="float-end">₹1,180</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="overlay" *ngIf="mobileVerificationDiv">
        <div class="popup2 col-md-4 col-11">
            <div class="modal-header">
                <h3>Sign In</h3>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="">
                    <div class="flex-center flex-column-fluid">
                        <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="loginForm">
                            <div class="text-center mb-11">

                            </div>
                            <div class="fv-row mb-7">
                                <label class="input-label required" for="mobile">Mobile Number</label>
                                <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true" [searchCountryFlag]="false"
                                    [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India"
                                    [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone"
                                    formControlName="PhoneNumber" [inputId]="'phone_number'"
                                    (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

                                </ngx-intl-tel-input>
                            </div>
                            <div class="fv-row mb-3">
                                <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold">
                                    <div><label class="input-label required" for="password">Password</label></div>
                                    <a href="/forgotPassword" class="link-primary">Forgot Password ?</a>
                                </div>
                                <input [type]="this.passwordType" placeholder="Enter Password" name="password"
                                    autocomplete="off" class="form-control bg-transparent" formControlName="Password" />
                                <i class="fa fa-eye-slash field-icon" (click)="ShowPassword()" aria-hidden="true"></i>
                            </div>
                            <div class="d-flex">
                                <input type="checkbox">&nbsp;
                                <span class="remember mt-1">Remember me</span>
                            </div>
                            <div class="d-grid mb-5 mt-5">
                                <button class="btn btn-primary" type="submit" id="kt_sign_in_submit"
                                    [disabled]="loginForm.invalid || ServicesDown" (click)="submit()">
                                    <span class="indicator-label">Sign In</span>
                                </button>
                            </div>
                            <div class="text-center">
                                Don't have an account?
                                <a href="/signup" class="link-primary" (click)="quoteSignup()">Signup Here</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay" *ngIf="isviewbreakupplan">
        <div class="popup2 col-md-4 col-11">
            <div class="modal-header">
                <h3>Premium Breakup</h3>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="">
                    <div class="flex-center flex-column-fluid">
                        <form class="form" novalidate="novalidate" id="kt_sign_in_form">
                            <div class="summary_view">
                                <h4 class="mt-2"> <b> Net Premium - 1 year <span>₹ {{premiumObject.grosspremium}}</span></b></h4>
                                <hr>
                                <label class="mt-2"><b>  GST(18%) <span class="text-danger">₹ {{premiumObject.igst}}</span> </b></label>
                                <hr>
                                <h4 class="mt-2"><b>  Total Premium <span>₹ {{premiumObject.finalPremium}}</span></b> </h4>
                                <!-- <button class="proceed_btn">Proceed with Plan</button> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay" *ngIf="isviewFeatures">
        <div class="popup2 col-md-8 col-11">
            <div class="modal-header">
                <h3>Features</h3>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="">
                    <div class="flex-center flex-column-fluid">
                        <form class="form" novalidate="novalidate" id="kt_sign_in_form">
                            <div class="featureDiv">
                                <div class="features_tabs">
                                    <ul>
                                        <li (click)="featuresList('coverage')" [ngClass]="{'active_tab1': tab1 }">
                                            Coverage
                                        </li>
                                        <li (click)="featuresList('waiting period')" [ngClass]="{'active_tab1': tab2 }">
                                            Waiting periods</li>
                                        <li (click)="featuresList('Additional Features')"
                                            [ngClass]="{'active_tab1': tab3 }">Additional Features</li>
                                        <li (click)="featuresList('Value Added Service')"
                                            [ngClass]="{'active_tab1': tab4 }">Value Added Services</li>
                                        <li (click)="featuresList('Cashless Hospitals')"
                                            [ngClass]="{'active_tab1': tab5 }">
                                            Cashless Hospitals({{cashlesshospitalsCount}})</li>
                                        <li (click)="featuresList('Documents Upload')"
                                            [ngClass]="{'active_tab1': tab6 }">
                                            Policy Documents</li>

                                    </ul>
                                </div>
                                <div class="features_tabs_content">
                                    <div class="content-block" *ngIf="iscoveragefeatures">
                                        <h5>Coverage</h5>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(0)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 0, 'fa-chevron-up': openItemIndex === 0 }"
                                                    aria-hidden="true"></i>
                                                Claims paid within 3 months
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Claims
                                                paid
                                                within 3 months means that when you make a health insurance claim, the
                                                insurance company aims to settle and pay you within a short 3-month
                                                period.
                                            </div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(1)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 1, 'fa-chevron-up': openItemIndex === 1 }"
                                                    aria-hidden="true"></i>
                                                Room rent limit
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }"> Room
                                                Rent
                                                Limit is the maximum amount your insurance will pay per day for the
                                                hospital
                                                room. It helps manage costs during hospitalization.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(2)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 2, 'fa-chevron-up': openItemIndex === 2 }"
                                                    aria-hidden="true"></i>
                                                Restoration of cover
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 2 }"> If you
                                                use
                                                up your coverage during the year, this feature gives you extra
                                                protection to
                                                cover more medical expenses.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(3)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 3, 'fa-chevron-up': openItemIndex === 3 }"
                                                    aria-hidden="true"></i>
                                                Renewal Bonus
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }"> Renewal
                                                Bonus is like a reward in health insurance. If you renew your policy
                                                without
                                                making claims, you get extra coverage or a discount as a bonus.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(4)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 4, 'fa-chevron-up': openItemIndex === 4 }"
                                                    aria-hidden="true"></i>
                                                Cashless hospitals
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">
                                                Cashless
                                                Hospitals means you don't pay money upfront for medical bills. Your
                                                health
                                                insurance directly covers the hospital expenses, making it hassle-free
                                                for
                                                you during treatment.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(5)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 5, 'fa-chevron-up': openItemIndex === 5 }"
                                                    aria-hidden="true"></i>
                                                Co-pay
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }"> Co-pay
                                                means you and the insurance company share the cost of medical expenses.
                                                You
                                                pay less, and they cover the rest.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(6)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 6, 'fa-chevron-up': openItemIndex === 6 }"
                                                    aria-hidden="true"></i>
                                                Pre-hospitalization coverage
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }">
                                                Pre-hospitalization coverage means your health insurance takes care of
                                                medical expenses incurred before your hospital stay, such as doctor
                                                visits,
                                                tests, and medications.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(7)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 7, 'fa-chevron-up': openItemIndex === 7 }"
                                                    aria-hidden="true"></i>
                                                Post-hospitalization coverage
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">
                                                Post-hospitalization coverage means your health insurance still helps
                                                after
                                                leaving the hospital. It pays for some costs like medicines or follow-up
                                                check-ups, ensuring your recovery is supported.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(8)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 8, 'fa-chevron-up': openItemIndex === 8 }"
                                                    aria-hidden="true"></i>
                                                Day care treatment
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }"> Day
                                                care
                                                treatment covers medical procedures or treatments that don't require an
                                                overnight hospital stay. It includes short, specialized treatments like
                                                minor surgeries, dialysis or chemotherapy.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(9)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 9, 'fa-chevron-up': openItemIndex === 9 }"
                                                    aria-hidden="true"></i>
                                                Hospitalization at home
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">
                                                Hospitalization at home means your insurance covers medical care and
                                                treatment at your own home instead of in a hospital, making recovery
                                                more
                                                comfortable.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(10)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 10, 'fa-chevron-up': openItemIndex === 10 }"
                                                    aria-hidden="true"></i>
                                                Ambulance charges
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">
                                                Ambulance
                                                charges cover the cost of taking you to the hospital in an emergency.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-block" *ngIf="iswaitingperiodfeatures">
                                        <h5>Waiting periods</h5>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(11)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 11, 'fa-chevron-up': openItemIndex === 11 }"
                                                    aria-hidden="true"></i>
                                                Existing Illness cover
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 11 }"> The
                                                time
                                                you need to wait before the insurance covers pre-existing health
                                                conditions.
                                                For example, if you have diabetes, or asthma or thyroid before getting
                                                the
                                                insurance, the expenses for these treatment might not be covered until
                                                the
                                                waiting period is over.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(12)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 12, 'fa-chevron-up': openItemIndex === 12 }"
                                                    aria-hidden="true"></i>
                                                Maternity cover
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 12 }">
                                                Maternity
                                                cover helps cover the costs of having a baby. It includes expenses like
                                                hospitalization, delivery, and sometimes pre/post-natal care. There's a
                                                certain time you have to wait after getting health insurance before
                                                using
                                                maternity benefits.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(13)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 13, 'fa-chevron-up': openItemIndex === 13 }"
                                                    aria-hidden="true"></i>
                                                Initial Waiting Period
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 13 }"> It is
                                                a
                                                time when certain benefits won't be available right after you buy the
                                                policy. For example, if you have a health issue, the insurance might not
                                                cover it during this initial period. It helps prevent misuse and keeps
                                                premiums lower.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(14)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 14, 'fa-chevron-up': openItemIndex === 14 }"
                                                    aria-hidden="true"></i>
                                                Specific Illness cover
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 14 }"> It
                                                helps
                                                pay for treatment and related costs for specific diseases like
                                                cataracts,
                                                ENT disorders, osteoporosis, hernia, joint replacement surgery, etc. The
                                                time period varies from one insurance plan to another.</div>
                                        </div>
                                    </div>
                                    <div class="content-block" *ngIf="isadditionalfeatures">
                                        <h5>Additional Features</h5>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(15)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 15, 'fa-chevron-up': openItemIndex === 15 }"
                                                    aria-hidden="true"></i>
                                                Alternate medicine (AYUSH)
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 15 }"> This
                                                feature covers covers treatments beyond traditional medicine. This
                                                includes
                                                Ayurveda, Yoga, Unani, Siddha, and Homeopathy.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(16)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 16, 'fa-chevron-up': openItemIndex === 16 }"
                                                    aria-hidden="true"></i>
                                                Worldwide coverage
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 16 }">
                                                Worldwide
                                                coverage means your health insurance works not just in your home country
                                                but
                                                all around the world. So, if you travel or need medical help abroad,
                                                your
                                                insurance has your back.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(17)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 17, 'fa-chevron-up': openItemIndex === 17 }"
                                                    aria-hidden="true"></i>
                                                Consumables Coverage
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 17 }"> This
                                                feature helps pay for daily medical items used during your treatment.
                                                Examples include bandages, syringes, and dressings, easing your
                                                out-of-pocket expenses.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(18)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 18, 'fa-chevron-up': openItemIndex === 18 }"
                                                    aria-hidden="true"></i>
                                                Domestic evacuation
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 18 }">
                                                Domestic
                                                Evacuation covers the cost of moving you to a better medical facility
                                                within
                                                your country for specialized treatment.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(19)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 19, 'fa-chevron-up': openItemIndex === 19 }"
                                                    aria-hidden="true"></i>
                                                Cover for organ donor
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 19 }"> Cover
                                                for
                                                Organ Donor means your health insurance helps with medical costs when
                                                someone donates an organ. For example, it can cover the donor's hospital
                                                bills or related expenses.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(20)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 20, 'fa-chevron-up': openItemIndex === 20 }"
                                                    aria-hidden="true"></i>
                                                Animal bite vaccination
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 20 }"> This
                                                feature helps pay for vaccines needed after an animal bite such as dog
                                                bite
                                                or cat bite etc..</div>
                                        </div>
                                    </div>
                                    <div class="content-block" *ngIf="isvalueaddedfeatures">
                                        <h5>Value Added Services</h5>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(21)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 21, 'fa-chevron-up': openItemIndex === 21 }"
                                                    aria-hidden="true"></i>
                                                Free health checkup
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 21 }"> The
                                                insurance company pays for your health checkup like blood tests,
                                                screenings,
                                                and consultations to detect potential health issues early.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(22)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 22, 'fa-chevron-up': openItemIndex === 22 }"
                                                    aria-hidden="true"></i>
                                                E-consultation
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 22 }">
                                                E-consultation allows you to talk to a doctor online. It covers virtual
                                                appointments for medical advice, prescriptions, and guidance.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(23)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 23, 'fa-chevron-up': openItemIndex === 23 }"
                                                    aria-hidden="true"></i>
                                                Discount on Renewal
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 23 }"> Earn a
                                                discount on your health insurance renewal by staying active and fit.
                                            </div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(24)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 24, 'fa-chevron-up': openItemIndex === 24 }"
                                                    aria-hidden="true"></i>
                                                Daily cash allowance
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 24 }"> "Daily
                                                Cash Allowance means getting a fixed amount daily for additional
                                                expenses
                                                during hospital stays. Examples include covering food, transportation,
                                                or
                                                other personal needs.</div>
                                        </div>
                                        <div class="faq-item">
                                            <div class="faq-question" (click)="toggleAnswer(25)">
                                                <i class="fa faq-icon"
                                                    [ngClass]="{ 'fa-chevron-down': openItemIndex !== 25, 'fa-chevron-up': openItemIndex === 25 }"
                                                    aria-hidden="true"></i>
                                                Out patient consultation benefits
                                            </div>
                                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 25 }">
                                                Outpatient
                                                Consultation Benefits" in health insurance cover the costs of seeing a
                                                doctor without being admitted to a hospital.</div>
                                        </div>
                                    </div>
                                    <div class="content-block" *ngIf="iscashlesshospitals">
                                        <h5>Cashless Hospitals</h5>
                                    </div>
                                    <div class="content-block" *ngIf="isdocumentsupload">
                                        <h5>Policy Documents</h5>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <app-footer></app-footer> -->

    <div *ngIf="isPayment">
        <app-lifeline-payment [royalDetails]="royalDetails"></app-lifeline-payment>
    </div>