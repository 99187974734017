import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { GeneralService } from 'src/app/Services/general.service';
import { PolicyService } from 'src/app/Services/policy.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-customer-policy-list',
  templateUrl: './customer-policy-list.component.html',
  styleUrls: ['./customer-policy-list.component.css']
})
export class CustomerPolicyListComponent {
  CustomerPolicyList:any =[];
  agentList:any;
  agentName:string = "";
  filterForm: FormGroup;
  todayDate = new Date();
  yearList : any = [];
  monthList:{text:string,value:number}[] = [];
  showPolicyList:{text:string,value:number}[] = [{text:"Both",value:2},{text:"External",value:1},{text:"Internal",value:0}];
  currentYear: number =this.todayDate.getFullYear();
  currentMonth: number = this.todayDate.getMonth() + 1;  
  monthNameList: {text:string,value:number}[] = [{text:'Jan',value:1},{text:'Feb',value:2},{text:'Mar',value:3},{text:'Apr',value:4},{text:'May',value:5},{text:'Jun',value:6},{text:'Jul',value:7},{text:'Aug',value:8},{text:'Sep',value:9},{text:'Oct',value:10},{text:'Nov',value:11},{text:'Dec',value:12}];
constructor(private agentprofileService : AgentprofileService,private spinner: NgxSpinnerService,private router: Router,private generalService :GeneralService,private fb: FormBuilder,private policyService:PolicyService )
{  

}

ngOnInit(){
  this.filterForm = this.fb.group({
    year: [this.currentYear, Validators.required], 
    month: [this.currentMonth, Validators.required],
    agentId: [this.generalService.getUniqueuserid(), Validators.required],
    showPolicy: [2, Validators.required],
  });

  for (var i = 2020; i <= this.currentYear + 1; i++) {
    this.yearList.push(i);
  }
  this.loadCustomerList();
  this.loadAgentList();
  this.loadYearMonthList();
}
async loadCustomerList(){
  let response = await this.agentprofileService.GetCustomerByAgentIdMonthYear(parseInt(this.filterFormControl['year'].value) , parseInt(this.filterFormControl['month'].value),this.filterFormControl['agentId'].value,this.filterFormControl['showPolicy'].value,);
  if (response?.isSuccess && response.result) {
    this.CustomerPolicyList = response.result;
  }
}

async loadAgentList(){
  let res = await this.agentprofileService.AgentDropDown();
  if (res != null && res.result) {
    this.agentList = res.result.map((item:any)=> {return {text:item.name,value:item.uniqueUserId,id:item.uniqueUserId}});
    let selectedAgent = this.agentList.filter((f:any)=>{return f.id == this.generalService.getUniqueuserid()}).map((m:any)=>{return m.text});        
    this.agentName = selectedAgent != null && selectedAgent != undefined && selectedAgent.length > 0 ?  selectedAgent[0] : "";
  }
}

get filterFormControl() {
  return this.filterForm.controls;
}

onAgentSelection(data:AutoCompleteItem)
{
  this.filterForm.get('agentId')?.setValue(data.id);
}

loadYearMonthList(){
  this.monthList = this.monthNameList;
  // this.monthList = [];
  // if(parseInt(this.filterFormControl['year'].value) == this.currentYear){
  //   for (var i = 0; i < this.currentMonth; i++) {
  //     this.monthList.push(this.monthNameList[i]);
  //   }
  // }
  // else
  // {
  //   this.monthList = this.monthNameList;
  // }  
}

addPolicy(){
  this.router.navigateByUrl('/ImportPolicy',{ state: { id:"0" }});
}

purchasePolicy(){
  this.router.navigate(['/']);
}

edit(policyId:any){
  this.router.navigateByUrl('/ImportPolicy',{ state: { id:policyId }});
}

async downloadPolicy(policyId:any){
  this.spinner.show();
  const response = await this.policyService.DownloadPolicy(policyId);
  if (response?.isSuccess && response.result && response.result.code ==1) 
  {
    const downloadLink = document.createElement('a');
    const fileName = response.result.data2??'policy.pdf';
    downloadLink.href = response.result.data;
    downloadLink.download = fileName;
    downloadLink.target = "_blank";
    downloadLink.click();
    this.spinner.hide();
  }
  else
    {
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: "Policy document is not generated yet, please try after some time.",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      }); 
    }
  }
}
