import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PolicyService } from '../Services/policy.service';
import { InsuranceService } from '../Services/insurance.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.css']
})
export class PolicyDetailsComponent implements AfterViewInit {

  isExpired: boolean = false;
  isAll: boolean = true;
  isActive: boolean = true;
  isinactive: boolean = false;
  policyDetailsList:any =[];
  policyDetailsActiveList:any =[];
  policyDetailsExpiredList:any =[];
  policyDetailsInCompleteList:any =[];
  policyDetails:any ={};
  isSelectPolicy: boolean = false;
  selectedPolicy:string="";
  showDownloadButton: boolean = false;
  moment = moment;
  today = new Date();
  isResult:boolean =false;
  constructor(private fb: FormBuilder, private policyService: PolicyService, private spinner: NgxSpinnerService,private insuranceService: InsuranceService,private router: Router) {

  }
  async ngOnInit() {
  }

  ngAfterViewInit() {
    this.GetPolicyDetails();
  }

  async onClick() {  
    this.spinner.show();
    const response = await this.policyService.DownloadPolicy(this.policyDetails.policyIdEnc);
    if (response?.isSuccess && response.result && response.result.code ==1)
    {
      const downloadLink = document.createElement('a');
      downloadLink.href = response.result.data;
      downloadLink.download = response.result.data2;
      downloadLink.target = "_blank";
      downloadLink.click();
      this.spinner.hide();
    }
    else
    {
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: "Policy document is not generated yet, please try after some time.",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      }); 
    }
  //   if(this.policyDetails.insuranceCompany == 2 || this.policyDetails.insuranceCompany == 14 || this.policyDetails.insuranceCompany == 28 || this.policyDetails.insuranceCompany == 7 || this.policyDetails.insuranceCompany == 8   || this.policyDetails.insuranceCompany == 9 || this.policyDetails.insuranceCompany == 3 || this.policyDetails.insuranceCompany == 11 || this.policyDetails.insuranceCompany == 4)  
  //   await this.showPdf();
  // else if(this.policyDetails.insuranceCompany == 1)
  //  await  this.showGoDigitPdf();
  }

  async generatePolicyPDF()
  {
    const response = await this.policyService.GeneratePolicyPDF(this.policyDetails.policyNumber,this.policyDetails.insuranceCompany,this.policyDetails.policyIdEnc);
    debugger;
    if (response?.isSuccess) {
      
      let linkSource =response.result;
      if (!(this.policyDetails.insuranceCompany==8  || this.policyDetails.insuranceCompany == 9))
      {
        linkSource = 'data:application/pdf;base64,' +linkSource;
      }      
      const downloadLink = document.createElement('a');
      const fileName = 'policy.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.target = "_blank";
      downloadLink.click();

    }
  }

  async showPdf() {

    this.spinner.show();

    if(this.policyDetails.insuranceCompany == 2 && (this.policyDetails.policyNumber=='' || this.policyDetails.policyNumber=="null" || this.policyDetails.policyNumber==null ||  (this.policyDetails.policyNumber!=null && !this.policyDetails.policyNumber.toLowerCase().startsWith("v"))))
    {
      try {
      
        const response = await this.insuranceService.GetTransactionHistory(this.policyDetails.quoteId);
        
        if (response?.isSuccess) {
          this.policyDetails.policyNumber = response.result.item1.data.policyNumber;
          this.generatePolicyPDF();          
        } else {
          console.error('Error in GetTransactionHistory:', response?.message);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
    else
    {
      this.generatePolicyPDF();
    }

 
    this.spinner.hide();
  }

  async showGoDigitPdf() {

    this.spinner.show();
    let request = { ApplicationId: this.policyDetails.applicationId, InsuranceCompany: this.policyDetails.insuranceCompany.toString() }
    const response = await this.policyService.GetPolicyReceipt(request);
    if (response?.isSuccess) {
      const linkSource =response.result;
      const downloadLink = document.createElement('a');
      const fileName = 'policy.pdf';  
      downloadLink.href = 'data:application/pdf;base64,' +linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    this.spinner.hide();
  }

  async GetPolicyDetails() {
    this.spinner.show();
    const response = await this.policyService.GetPolicyDetails();
    if (response?.isSuccess) {
      this.isResult = true;
      this.policyDetailsList = response.result;        
      this.policyDetailsActiveList = this.policyDetailsList.filter((f:any)=> f.policyValue.policyStatus === 1 && moment(f.policyValue.EndDate)>=moment(this.today));
      this.policyDetailsExpiredList = this.policyDetailsList.filter((f:any)=> f.policyValue.policyStatus === 1 && moment(f.policyValue.EndDate)<moment(this.today));
      this.policyDetailsInCompleteList=this.policyDetailsList.filter((f:any)=> f.policyValue.policyStatus != 1 );
    }
    else
    {
      this.isResult = true;
    }
    this.spinner.hide();
  }

  setStatus(status:number, endDate:any ){
    if(status === 1 && moment(endDate)< moment(this.today)){
      return 'Expired';
    }
    else if (status === 1 && moment(endDate)>=moment(this.today))
    {
      return 'Active';
    }
    else
    {
      return 'In Complete';
    }
  }

  async showPolicyDetails(policyNo:string,showDownloadButton:boolean) {
    this.selectedPolicy = policyNo;
    this.spinner.show();
    const response = await this.policyService.GetPolicyById1(policyNo);
    if (response?.isSuccess) {
      this.isSelectPolicy = true;
      ;
      this.policyDetails = response.result;
      this.showDownloadButton = showDownloadButton;
      window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }
    this.spinner.hide();
  }



  changeTab(value: string){
    this.isSelectPolicy = false;
    if(value == 'all'){
     this.isAll = true;
     this.isActive = false;
     this.isExpired = false;
     this.isinactive = false;
    }
    else if(value == 'active'){
      this.isAll = false;
     this.isActive = true;
     this.isExpired = false;
     this.isinactive = false;
    }
    else if(value == 'Inactive'){
      this.isAll = false;
     this.isActive = false;
     this.isinactive = true;
     this.isExpired = false;
    }
    else if(value == 'expired'){
      this.isAll = false;
     this.isActive = false;
     this.isExpired = true;
     this.isinactive = false;
    }
  }

  async continueJourney(){
    this.router.navigate(['/VehicleOwnerDetails',{q:123456,u:0,p:this.policyDetails.policyIdEnc,s:this.policyDetails?.policyValue?.policyStatus}]);
  }

  async makePaymen(){
    let url = this.policyDetails.paymentRequestObject?.actualPaymentUrl;

    if(url != null && url != undefined && url != ""){
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.click();
    }
    else
    {
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: "Payment link is expired.",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      }); 
    }

  }

  async checkStatus(){
    this.spinner.show();
    const response = await  this.insuranceService.PreInspStatus({quoteId:this.policyDetails.quoteId,insuranceCompany:this.policyDetails.insuranceCompany,status:this.policyDetails.policyValue.policyStatus});
    if (response?.isSuccess && response?.result && response?.result?.code == 1) {
      this.policyDetails.policyValue.policyStatus = response?.result?.subcode??7; //7 : IncpectionTriggered
    }

    swal.fire({
      position: 'center',
      imageUrl: "../../assets/alerts/"+ (response?.result?.subcode == 5 ? "success" : "warning") +".png",
      imageHeight: 89,
      title: response?.result?.subcode == 5 ? "Your vehicle inspections is approved." : response?.result?.subcode == 6 ? "Your vehicle inspections is rejected, please retry with fresh proposal." : "Your vehicle inspections is still under process",
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      cancelButtonColor: response?.result?.subcode == 5 ?"#0DB561" : "#DF3A4D",
    }); 

    this.spinner.hide();
  }
}


