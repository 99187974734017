import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { GeneralService } from 'src/app/Services/general.service';
import { SignupService } from 'src/app/Services/signup.service';
import { UserService } from 'src/app/Services/user.service';
import swal from 'sweetalert2';
import * as jspdf from 'jspdf';
import * as moment from 'moment';
import { PostCertificateComponent } from '../components/post-certificate/post-certificate.component';
import { LookUpService } from 'src/app/Services/lookup.service';
import { Item } from 'src/app/Models/Item';
import { alphabetOnly, alphabetWithSpaces } from 'src/app/functions/validation-functions';
@Component({
  selector: 'app-agent-profile',
  templateUrl: './agent-profile.component.html',
  styleUrls: ['./agent-profile.component.css']
})
export class AgentProfileComponent {
isKYC:boolean=false;
isActive:boolean=false;
isInactive:boolean=true;
isBank:boolean=false;
isProfile:boolean=true;
BankPopup:boolean=false;
trainingPop:boolean=false;
examPopup:boolean=false;
edit:boolean=false;
page:string='';
// kycUpdate:boolean=true;
// bankUpdate:boolean=true;
// kycProcess:boolean=false;
// bankProcess:boolean=false;
trainingProcess:boolean=false;
ExamComplete:boolean=false;
kycVerify:boolean=false;
bankVerify:boolean=false;
earning:boolean=false;
fullname: any;
email: any ;
gender:any;
uniqueuserid: any;
mobile: any;
getkyc:any;
getBank:any;
saveProfileForm: FormGroup;
language: any;
languageText:string = "";
value: any;
languageList:Item[] = [];
agentAct:any;
kycStatus:number = 0;
bankStatus:number = 0;
trainingStatus:number = 0;
examStatus:number = 0;
earningStatus:number = 0;
trainingEndAt:any;
trainingEndAtText:string;
examEndAt:any;
examEndAtStr:any;
todayDate : string = moment(new Date()).format("DD MMM, YYYY");
private intervalId: any;
public remainingTime: number = 0;
public countdownText:string;
pospCode:string;
agentStatus:number = 0;
@ViewChild(PostCertificateComponent) postCertificateComponent: PostCertificateComponent;
constructor(private signupService : SignupService,private agentprofileService : AgentprofileService,private spinner: NgxSpinnerService, private userservice: UserService,private fb: FormBuilder,private route:Router, private generalService : GeneralService, private lookUpService :LookUpService){
  this.agentAct=  localStorage.getItem("active");
  if(this.agentAct=="true")
    {
      this.isActive=true;
      this.isInactive=false;
    }
  this.saveProfileForm = this.fb.group({
    "firstName": ['', [Validators.required, Validators.pattern('^[a-zA-Z- ]*')]],
    "lastName": ['', [Validators.required, Validators.pattern('^[a-zA-Z- ]*')]],
    "userEmail": ['', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    "language": ['', [Validators.required]],
    "phoneNumber":['']
  });

  this.getLanguageList();
  this.getUserDetails();

  // this.languageList =  [
  //   {value:"1",text:"English"},
  //   {value:"2",text:"Hindi"},
  //   {value:"3",text:"Telugu"},
  //   {value:"4",text:"Gujarati"},
  //   {value:"5",text:"Marathi"},
  //   {value:"6",text:"Kannada"},
  //   {value:"7",text:"Malyalam"},
  //   {value:"8",text:"Tamil"},
  //   {value:"9",text:"Bengali"},
  //   {value:"10",text:"Punjabi"},
  //   {value:"11",text:"Odiya"}];
}

async updateAgentInfo(){
  // this.saveProfileForm.get('FirstName')?.setValue(this.saveProfileForm.value.FirstName);
  // this.saveProfileForm.get('LastName')?.setValue(this.saveProfileForm.value.LastName);
  // this.saveProfileForm.get('userEmail')?.setValue(this.saveProfileForm.value.userEmail );
  // this.saveProfileForm.get('language')?.setValue(this.saveProfileForm.value.language );

  let obj={
    "firstName":  this.saveProfileForm.value.firstName,
  "lastName": this.saveProfileForm.value.lastName,
    "userEmail": this.saveProfileForm.value.userEmail ,
    "userLanguage": this.saveProfileForm.value.language,
    "uniqueuserid":  this.generalService.getUniqueuserid()
  } 
  const response = await this.signupService.UpdateAgentInfo(obj);

  if (response?.isSuccess == true && response?.result != null && response?.result != undefined && response?.result?.code == 1) {
    swal.fire({
      position: 'center',
      imageUrl: "../../assets/alerts/success.png",
      imageHeight: 89,
      title: response?.result?.message,
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: "#0DB561"
    },
  ).then(() => {
    this.reload();
  }); 
  }
  else
  {
    swal.fire({
      position: 'center',
      imageUrl: "../../assets/alerts/warning.png",
      imageHeight: 89,
      title: response?.result?.message ??"Something went wrong.",
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      cancelButtonColor: "#DF3A4D",
      customClass: {
        confirmButton: "error_btn",
      }
    });
  }  
  // let lang= this.saveProfileForm.value.language;
  // let Username= this.saveProfileForm.value.userName;
  // let UserMail= this.saveProfileForm.value.userEmail;
  // localStorage.setItem("language",lang);
  // let resut=response?.result;
  // localStorage.setItem("fullName",Username);
  // localStorage.setItem("mail",UserMail);

  
}
async getUserDetails() {
  this.spinner.show();
  let request = { uniqueUserId: this.generalService.getUniqueuserid() }
  const response = await this.userservice.AcquireUserInfo(request);
  if (response?.isSuccess) {
    this.email = response.result.userEmail;
    this.mobile = response.result.userMobile;
    this.fullname = `${response.result.firstName} ${response.result.lastName}`;
    this.gender = response.result.gender;
    this.language=response.result.language;
    this.setLanguageLabel();
    this.saveProfileForm.patchValue(response.result);
  }
  this.spinner.hide();
}
goto(name:string)
{
this.page=name;
if(this.page=='Aadhar-kyc')
{
  this.isKYC=true;
  this.isProfile=false;
  this.isBank=false; 
}
if(this.page=='Back-detail'){
    this.isBank=true;
    this.isProfile=false;
    this.isKYC=false;
}
if(this.page=='edit')
{
  this.edit=true;
}

if(this.page=='training' && this.kycStatus == 1 && this.bankStatus == 1)
{
  this.route.navigate(['/li-training']);
}
if(this.page=='Examination'  && this.kycStatus == 1 && this.bankStatus == 1 && this.trainingStatus == 1 && this.remainingTime == 0)
{
  if(this.examStatus == 1){
    this.postCertificateComponent.generatePDF();
  }
  else
  {
    this.route.navigate(['/li-exam']);
  }    
}
}
close(){
  this.edit=false;
}
closeModal()
{
  this.BankPopup=false;
  this.examPopup=false;
  this.trainingPop=false;

}
reload()
{
  location.reload();
}
tabs:string='1';
changeTab(pg:string)
{
  this.tabs=pg;
}

ngOnInit(): void {
  this.GetAgentStatus();
}

async GetAgentStatus() {
  let response = await this.agentprofileService.GetAgentStatus(this.generalService.getUniqueuserid());
  if(response?.isSuccess && response?.result){
    let agentStatuses = JSON.parse(response?.result);
    if(agentStatuses && agentStatuses.length > 0){ 
      let obj = agentStatuses[0];
      let kycStatus = [];
      kycStatus.push(obj.ADR_Status);
      kycStatus.push(obj.QUL_Status);
      kycStatus.push(obj.PAN_Status);
      kycStatus.push(obj.IMG_Status);
      this.kycStatus = Math.max(...kycStatus);
      this.bankStatus = obj.BNK_Status;
      this.trainingStatus = obj.TRN_Status;
      this.examStatus = obj.EXM_Status;
      this.trainingEndAt = obj.TrainingEndAt;
      this.examEndAt = obj.ExamEndAt;
      this.pospCode = obj.PospCode;
      this.agentStatus = obj.Agent_Status;
      //this.trainingEndAtText = ;
      this.earningStatus = this.kycStatus == 1 && this.bankStatus == 1 && this.trainingStatus == 1 && this.examStatus == 1 ? 1 : 0;
      this.generalService.setDataToLocalStorage("agentStatus", JSON.stringify(obj));
      if(this.examStatus == 0 && this.trainingStatus == 1){
        this.startCountdown(this.trainingEndAt);
      }
      else
      if(this.examStatus == 2 && this.trainingStatus == 1){
        this.startCountdown(this.examEndAt);        
      }
      if(this.examEndAt != null && this.examEndAt != ''){
        this.examEndAtStr = moment(this.examEndAt).format("DD MMM, YYYY");
      }
    }    
  }
}

private startCountdown(startAt :any): void {    
  this.intervalId = setInterval(() => {
      const now = new Date().getTime();
      this.remainingTime = (new Date(startAt).getTime() + (24 * 1000 * 60 * 60)) - now;

      //const now = new Date().getTime();    
      //this.remainingTime = this.remainingTime - 1000;    
      
      if (this.remainingTime < 0) {
        clearInterval(this.intervalId);
        this.remainingTime = 0;
        this.countdownText = 'Completed';
        if(this.examStatus == 2){
          this.GetAgentStatus();
        }
      } else {
        this.countdownText = this.updateCountdownText(this.remainingTime);
      }
  }, 1000);
}

public updateCountdownText(distance : number): string {
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return `${hours < 9 ? '0' + hours : hours}h ${minutes < 9 ? '0' + minutes : minutes}m ${seconds < 9 ? '0'+ seconds : seconds}s`;
}

async getLanguageList(){
  let response = await this.lookUpService.GetLanguageDetails(); 
  if (response?.isSuccess) {
    this.languageList = response.result;
    this.setLanguageLabel();
  }
}

onMultiSelectChange(selectItems: Item[]): void {
  if(selectItems && selectItems.length > 0){
    this.saveProfileForm.get("language")?.setValue(selectItems.map((item:Item) => {return item.id}).toString());
  }
  else
  {
    this.saveProfileForm.get("language")?.setValue("");
  }
}

setLanguageLabel(){
  if(this.language != null && this.language != undefined && this.language != "" && this.language != "0" && this.languageList?.length > 0)
    {
      let langList = this.language.split(",");
      this.languageText = this.languageList.filter((f:any)=> {return langList.includes(f.id.toString())}).map((m:any)=>{return m.name}).join(', ');

      this.languageList = this.languageList.map((obj:Item) => {
        return {...obj, checked : langList.includes(obj?.id?.toString())}
      })
    }
  }

  get saveProfileFormControl(){
    return this.saveProfileForm.controls;
  }

  alphabetOnly(event:any): boolean {
    return alphabetOnly(event);
  }

  alphabetWithSpaces(event:any): boolean {
    return alphabetWithSpaces(event);
  }
}

