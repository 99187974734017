import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[app-alphanumeric-mask]'
})
export class AlphaNuericMaskDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    let input = event.target.value;

    // Remove non-alphabet and non-numeric characters
    input = input.replace(/[^A-Za-z0-9]/g, '');

    this.el.nativeElement.value = input;
  }

}
