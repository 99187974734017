import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async InsertVehicleDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}vehicleInfo/InsertVehicleInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }
  async ManageVehicleDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}profile/ManageVehicleDetails`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }
  

  async GetVehiclesDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}vehicleInfo/GetVehicleInfo`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleCompanyDetails(data:any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleCompanyDetails`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleModelDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleModelDetails`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleVariantDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleVariantDetails`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleVariantFuelGVWDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleVariantFuelGVWDetails`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleFuelBodyTypeGVWDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleFuelBodyTypeGVWDetails`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetVehicleFuelDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetVehicleFuelDetails`,data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetPinCodeMasterCities() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/GetPinCodeMasterCities`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetPolicyCities(cityName?:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/PolicyCities?cityName=${cityName}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetPolicyCityRTOs(cityName?:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/PolicyCityRTOs?cityName=${cityName}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async RTODetails(cityName: string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}lookUpInfo/RTODetails?cityName=${cityName}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async getAddOnDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}GetAddOnsByCategory?category=${data}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }
  
}
