<div class="content-box bg-grey1" [ngClass]="!isUserLogged ? '' : 'user-dashboard'">
    <div class="container-fluid" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}" [ngClass]="!isUserLogged ? '' : 'user-route'">
        <div class="container "  style="background-color: #F3F3F4">
            <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="card mt-3 p-2 pb-6">
                            <div class="row mb-3 pb-6">
                                <div class="col-md-12 text-center pb-6">
                                    <img src="../../../../assets/images/success.jpg" alt="">
                                    <h4 style="color: green;">Your proposal has been submitted Successfully </h4>
                                    <img class="img-w-162" src="/assets/images/insurance_companies/{{paymentObject?.insuranceCompany}}.png" alt="">
                                    <span class="font-body d-block pb-6">Please click on below payment link to complete your policy journey</span>
                                    <a [href]="paymentObject?.paymentUrl" class="btb btn-primary p-3 rounded1">Click Here to Pay ₹ {{paymentObject?.premium??'0' | thousandSeparator}}</a><img title="Copy Link" alt="Copy Link"  class="icon_width_height_30_24 icon-main ml-3 cursor-pointer" src="../../../../assets/svgIcon/copy.svg" (click)="onCoplyClick()">
                                </div>
                            </div>                           
                        </div>                       
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>
