import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

   async GetIPAddress() {
    return this.http.get<any>(`${environment.APIUrl}/lookUpInfo/GetIpAddress`
    , {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }
    ).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }
 }