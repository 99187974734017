<div class=" mb-15" style="background-color: #f3f3f4;padding-left: 18px; padding-right: 18px;">
    <div class="row">
        <div class="col-md-12 mt-4">
            <h2>Bank Details</h2>
        </div>
    </div>
    <div class="row">
        <form class="form" novalidate="novalidate" [formGroup]="saveBankDetailForm">
            <div class="row">
                <div class="col-md-6 mt-4">
                    <div class="card p-5">
                        <div class="row">
                            <div class="col-md-6 pt-3">
                                <h4> Link Bank Account </h4>
                            </div>
                            <div class="col-md-6 text-right">
                                <button type="button" class="btn btn-back mr-2" [routerLink]="['/agent-profile']" (click)="exit()">Back</button>
                                <button class="btn btn-primary" (click)="submitBankDetails()" [disabled]="validBankDetails() || isBankUnderReview == true">Submit
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                           <div class="col-md-12 ">
                            <div class="col-md-6 mt-5">
                                <label for="">Account Number</label><span class="required"   ></span>
                                <input type="password" name="accountNumber" id="" class="form-control" formControlName="accountNumber"  placeholder="Account Number"  (keydown)="validateNumberInput($event)"  (input)="onInput($event)" [minlength]="9" [maxlength]="18">
                                <span class="text-danger" *ngIf="(BankDetails['accountNumber'].touched || AccSubmit) && this.saveBankDetailForm?.get('accountNumber')?.value?.length < 9 "> 
                                    Account number must have 9 digits
                                </span>
                                <!-- <small>Minimum 14 digits are required.</small> -->
                            </div>
    
                            <div class="col-md-6 mt-5">
                                <label for="">Confirm Account Number</label><span class="required"></span>
                                <input type="number" name="confirmAccountNumber" id="" class="form-control" formControlName="confirmAccountNumber" placeholder="Confirm Account Number"  (keydown)="validateNumberInput($event)"  (input)="onInput($event)"  >
                                <!-- <small>Minimum 14 digits are required.</small> -->
                                <span class="text-danger"*ngIf="( BankDetails['confirmAccountNumber'].touched || AccSubmit)&& BankDetails['accountNumber'].value!=BankDetails['confirmAccountNumber'].value" > Account number must match.</span>
                            </div>
                            <div class="col-md-6 mt-5">
                                <label for="">Account Holder Name</label><span class="required"></span>
                                <input type="text" name="accountHolderName" id="" class="form-control" formControlName="accountHolderName" placeholder="Account Holder Name" (keypress)="alphabetOnly($event)" >
                            </div>
                            <div class="col-md-6 mt-5">
                                <label for="">IFSC Code</label><span class="required"></span>
                                <input type="text" name="ifscCode" id="" class="form-control" formControlName="ifscCode" placeholder="IFSC Code" (input)="checkIFSC()" (keypress)="alphaNumericOnly($event)" [readOnly]="!BankDetails['branchName'].errors?.['required']">
                                <div *ngIf="!button">
                                    <button type="submit" class="btn btn-primary validate" (click)="getIfsc()">Validate</button>
                                </div>
                                <div *ngIf="button">
                                    <button type="button" class="btn btn-primary verified" disabled>Verified</button>
                                </div>
                                <span *ngIf="(BankDetails['ifscCode'].touched )" [ngClass]="!BankDetails['bankName'].errors?.['required'] || !BankDetails['branchName'].errors?.['required'] ? 'text-success' : 'text-danger'">
                                {{msg}}
                                </span>
                            </div>
                            
                            <div class="col-md-6 mt-5">
                                <label for="">Bank Name</label><span class="required"></span>
                                <input type="text" name="bankName" value="{{ifscDetails.bankName}}" id="" class="form-control" formControlName="bankName" placeholder="Bank Name" (keypress)="alphabetOnly($event)">
                            </div>
                            
                            <div class="col-md-6 mt-5">
                                <label for="">Branch Name</label><span class="required"></span>
                                <input type="text" name="branchName" value="{{ifscDetails.branchName}}" id="" class="form-control" formControlName="branchName" placeholder="Branch Name" (keypress)="alphabetOnly($event)">
                            </div>
                            <div class="col-md-12 mt-3">
                                <span>Cancelled Cheque / Passbook / Bank Statement</span><span class="required"></span>
                            </div>
                            <div class="col-md-12 mt-4 text-center">
                                <label for="file-upload" class="custom-file-upload text-center">
                                    <img *ngIf="!imageUrl" src="../../../assets/svgIcon/uplod.svg" alt="" width="50">
                                    <p *ngIf="!imageUrl" class="mt-3 p-2">Upload Image</p>
                                    <img *ngIf="imageUrl" [src]="imageUrl" alt="Preview" height="100" width="100%"/><br>
                                    <p *ngIf="imageUrl" class="mt-3 p-2 success-text">Image Uploaded Successfully !</p>
                                </label>
                                <input id="file-upload" type="file" name="uploadPassbook" [disabled]="isBankUnderReview" (change)="onFileSelected($event)" accept="image/*">
                                <span class="text-main d-block text-center"> Note: Accepted JPEG, PNG image with max file size 5 MB</span> 
                            </div>
                            <div class="col-md-12 mt-2 text-center">
                                 <span style="color: red; text-align: center;">Note  :</span>&nbsp;Uploaded documents should clearly show account holder name, account number, and IFSC code. 
                            </div>
                            
                        </div>
                    </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button type="button" class="btn btn-back mr-2" [routerLink]="['/agent-profile']" (click)="exit()">Back</button>
                                <button class="btn btn-primary" (click)="submitBankDetails()" [disabled]="validBankDetails() || isBankUnderReview == true">Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="overlay" *ngIf="Popup">
    <div class="popup2">
      <!-- <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)=" close()">         
        </button>
      </div> -->
      <div class="model-ve">
        <div class=" row text-center">
          <div class="col-md-12 mt-3 p-3 text-center">
              <img src="../../../assets/images/green_double_circle_check_mark 1.png" alt="">
          </div>
          <div class="col-md-12 mt-3">
              <h6>"Bank details have been submitted successfully."</h6>
          </div>
          <div class="col-md-12 mt-3">
              <span>Congratulations! Bank details have been submitted successfully.</span>
          </div>
          <div class="col-md-12 mt-2">
              <button class="btn btn-sm btn-primary" (click)="back('bank')">
  Continue
              </button>
          </div>
   </div>
      </div>
    </div>
  </div>