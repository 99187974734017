import { APP_INITIALIZER, HostListener, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './Login/login.component';
import AuthInterceptor from './authinterceptor/authinterceptor';
import { LoginService } from './Services/login.service';
import { AuthGuard } from './authinterceptor/auth_guard';
import { AppConfig } from './Shared/AppConfig';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavasidebarComponent } from './navasidebar/navasidebar.component';
import { MenuService } from './Services/menu.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { RoleComponent } from './role/role.component';
import { RolepermissionsComponent } from './rolepermissions/rolepermissions.component';
import { ProfileComponent } from './profile/profile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserService } from './Services/user.service';
import { BusinessInfoComponent } from './business-info/business-info.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentService } from './Services/document.service';
import { UsersComponent } from './users/users.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { InsuranceService } from './Services/insurance.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FooterComponent } from './footer/footer.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CarouselComponent } from './carousel/carousel.component';
import { PolicyPaymentComponent } from './policypayment/policypayment.component';
import { HomeComponent } from './home/home.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { SettingsComponent } from './settings/settings.component';
import { QuoteDetailsComponent } from './quote-details/quote-details.component';
import { ProposaldetailsComponent } from './proposaldetails/proposaldetails.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NavbarComponent } from './navbar/navbar.component';
import { HealthComponent } from './health/health.component';
import { PropertyComponent } from './property/property.component';
import { ReviewvehicledetailsComponent } from './reviewvehicledetails/reviewvehicledetails.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { FamilyDetailsComponent } from './family-details/family-details.component';
import { AssetsComponent } from './assets/assets.component';
import { AddressesComponent } from './addresses/addresses.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DontKnowBikeNumberComponent } from './dont-know-bike-number/dont-know-bike-number.component';
import { QuoteService } from './Services/quote.service';
import { QuotesLoginComponent } from './Login/quotes-login/quotes-login.component';
import { VehicleQuoteDetailsComponent } from './vehicleinsurance/vehicle-quote-details/vehicle-quote-details.component';
import { BikeComponent } from './vehicleinsurance/bike/bike.component';
import { VehicleOwnerDetailsComponent } from './vehicleinsurance/vehicle-owner-details/vehicle-owner-details.component';
import { VehiclePolicyDetailsComponent } from './vehicleinsurance/vehicle-policy-details/vehicle-policy-details.component';
import { VehiclequotesComponent } from './vehicleinsurance/vehiclequotes/vehiclequotes.component';
import { VehiclePremiumInfoComponent } from './vehicleinsurance/vehicle-premium-info/vehicle-premium-info.component';
import { CarComponent } from './vehicleinsurance/car/car.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CareerComponent } from './career/career.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { CustomerComponent } from './customer/customer.component';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { ManageagentcustomerComponent } from './manageagentcustomer/manageagentcustomer.component';
import { ManagevehicleComponent } from './managevehicle/managevehicle.component';
import { ManagepropertyComponent } from './manageproperty/manageproperty.component';
import { CancellationrefundComponent } from './cancellationrefund/cancellationrefund.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ContentComponent } from './content/content.component';
import { GrivanceredressalComponent } from './grivanceredressal/grivanceredressal.component';
import { TestimonialComponent } from './home/testimonial/testimonial.component';
import { TermInsuranceComponent } from './lifeinsurance/term-insurance/term-insurance.component';
import { GroupTermInsuranceComponent } from './lifeinsurance/group-term-insurance/group-term-insurance.component';
import { SinglepayinsuranceComponent } from './lifeinsurance/singlepayinsurance/singlepayinsurance.component';
import { RoyalSundaramPaymentComponent } from './vehicleinsurance/royal-sundaram-payment/royal-sundaram-payment.component';
import { TaxiInsuranceComponent } from './motorinsurance/taxi-insurance/taxi-insurance.component';
import { CarInsuranceComponent } from './motorinsurance/car-insurance/car-insurance.component';
import { CommercialVehicleInsuranceComponent } from './motorinsurance/commercial-vehicle-insurance/commercial-vehicle-insurance.component';
import { ThirdPartyCarInsuranceComponent } from './motorinsurance/third-party-car-insurance/third-party-car-insurance.component';
import { ThirdPartyTwoWheelerInsuranceComponent } from './motorinsurance/third-party-two-wheeler-insurance/third-party-two-wheeler-insurance.component';
import { AgGridModule } from 'ag-grid-angular';
import { CustomerpopoverComponent } from './customer/customerpopover/customerpopover.component';
import { TwoWheelerInsuranceComponent } from './motorinsurance/two-wheeler-insurance/two-wheeler-insurance.component';
import { FamilyHealthPolicyComponent } from './healthinsurance/family-health-policy/family-health-policy.component';
import { IndividualHealthPolicyComponent } from './healthinsurance/individual-health-policy/individual-health-policy.component';
import { GroupHealthInsuranceComponent } from './healthinsurance/group-health-insurance/group-health-insurance.component';
import { SeniorHealthPolicyComponent } from './healthinsurance/senior-health-policy/senior-health-policy.component';
import { CriticalIllnessPolicyComponent } from './healthinsurance/critical-illness-policy/critical-illness-policy.component';
import { ArogyaSanjivniPolicyComponent } from './healthinsurance/arogya-sanjivni-policy/arogya-sanjivni-policy.component';
import { TaxSavingPlanComponent } from './savingplan/tax-saving-plan/tax-saving-plan.component';
import { MoneyBackPlanComponent } from './savingplan/money-back-plan/money-back-plan.component';
import { GuaranteedReturnPlanComponent } from './savingplan/guaranteed-return-plan/guaranteed-return-plan.component';
import { ULIPComponent } from './savingplan/ulip/ulip.component';
import { PensionPlanComponent } from './savingplan/pension-plan/pension-plan.component';
import { ChildPlanComponent } from './savingplan/child-plan/child-plan.component';
import { ContactusComponent } from './contactus/contactus.component';
import { BikeDetailsComponent } from './bought-new-bike/bike-details/bike-details.component';
import { HowWeHelpYouComponent } from './home/how-we-help-you/how-we-help-you.component';
import { WhatIsInsuranceComponent } from './home/what-is-insurance/what-is-insurance.component';
import { FeatureComponent } from './home/feature/feature.component';
import { InsuranceBannerComponent } from './home/insurance-banner/insurance-banner.component';
import { AndroidBannerComponent } from './home/android-banner/android-banner.component';
import { PartnerComponent } from './home/partner/partner.component';
import { NeedHelpComponent } from './home/need-help/need-help.component';
import { BikeInsuranceQuoteComponent } from './bought-new-bike/bike-insurance-quote/bike-insurance-quote.component';
import { InsuranceOwnerDetailComponent } from './bought-new-bike/insurance-owner-detail/insurance-owner-detail.component';
import { InsurancePolicyDetailComponent } from './bought-new-bike/insurance-policy-detail/insurance-policy-detail.component';
import { CarQuoteDetailComponent } from './bought-new-car/car-quote-detail/car-quote-detail.component';
import { CarInsuranceQuoteComponent } from './bought-new-car/car-insurance-quote/car-insurance-quote.component';
import { AddTestimonialComponent } from './home/testimonial/add-testimonial/add-testimonial.component';
import { PaymentCardComponent } from './payment/bike/payment-card/payment-card.component';
import { FailureComponent } from './payment/bike/failure/failure.component';
import { SuccessComponent } from './payment/bike/success/success.component';
import { ComparisonComponent } from './vehicleinsurance/bike/comparison/comparison.component';
import { VacancyComponent } from './career/vacancy/vacancy.component';
import { carInsurancePolicyDetailComponent } from './bought-new-car/carInsurance-policy-detail/carInsurance-policy-detail.component';
import { carInsuranceOwnerDetailComponent } from './bought-new-car/carInsurance-owner-detail/carInsurance-owner-detail.component';
import { BikeInsuranceDetailComponent } from './vehicleinsurance/bike/bike-insurance-detail/bike-insurance-detail.component';
import { BikeInsuranceOwnerDetailComponent } from './vehicleinsurance/bike/bike-insurance-owner-detail/bike-insurance-owner-detail.component';
import { BikeInsurancePolicyDetailComponent } from './vehicleinsurance/bike/bike-insurance-policy-detail/bike-insurance-policy-detail.component';
import { PersonalInfoComponent } from './eKYC/personal-info/personal-info.component';
import { HelpComponent } from './help/help.component';
import { VerifyComponent } from './eKYC/verify/verify.component';
import { MultiDropdownComponent } from './components/multi-dropdown/multi-dropdown.component';
import { HeaderMenuComponent } from './navbar/header-menu/header-menu.component';
import { InputPincodeMaskDirective } from './directive/input-pincode-mask.directive';
import { PhonenumberMaskDirective } from './directive/phonenumber-mask.directive';
import { NameMaskDirective } from './directive/name-mask.directive';
import { PancardMaskDirective } from './directive/pancard-mask.directive';
import { YearMaskDirective } from './directive/year-mask.directive';
import { RtoMaskDirective } from './directive/rto-mask.directive';
import { AlphaNuericMaskDirective } from './directive/alpha-nueric-mask.directive';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { HealthinsurancePolicyComponent } from './healthinsurance/healthinsurance-policy/healthinsurance-policy.component';
import { HealthinsurancePlanListComponent } from './healthinsurance/healthinsurance-plan-list/healthinsurance-plan-list.component';
import { HealthinsurancePlanComponent } from './healthinsurance/healthinsurance-plan/healthinsurance-plan.component';
import { HealthinsuranceDetailsComponent } from './healthinsurance/healthinsurance-details/healthinsurance-details.component';
import { NgChartsModule } from 'ng2-charts';
import { BusinesshomepageComponent } from './agent/businesshomepage/businesshomepage.component';
import { AgentfooterComponent } from './agent/agentfooter/agentfooter.component';
import { AgentcustomerComponent } from './agent/agentcustomer/agentcustomer.component';
import { CommonModule } from '@angular/common';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { LifelinePaymentComponent } from './healthinsurance/lifeline-payment/lifeline-payment.component';
import { RegisterbusinessComponent } from './registerbusiness/registerbusiness.component';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { AgentDashboardComponent } from './agent/agent-dashboard/agent-dashboard.component';
import { SidebarmenuComponent } from './agent/sidebarmenu/sidebarmenu.component';
import { FutureGeneralPaymentComponent } from './vehicleinsurance/future-general-payment/future-general-payment.component';
import { AgentProfileComponent } from './agent/agent-profile/agent-profile.component';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';
export function initializeApp(appConfig: AppConfig) {

  return () => appConfig.load();

}
import { AgentKycVerificationComponent } from './agent/agent-kyc-verification/agent-kyc-verification.component';
import { AgentBankDetailsComponent } from './agent/agent-bank-details/agent-bank-details.component';
import { AgenttrainingComponent } from './agent/agenttraining/agenttraining.component';
import { LiPosptrainingComponent } from './agent/agenttraining/li-posptraining/li-posptraining.component';
import { GiPosptrainingComponent } from './agent/agenttraining/gi-posptraining/gi-posptraining.component';
import { LiexamComponent } from './agent/agenttraining/liexam/liexam.component';
import { AgentnavbarComponent } from './agent/agentnavbar/agentnavbar.component';
import { CdTimerModule } from 'angular-cd-timer';
import { CertificateComponent } from './agent/agenttraining/certificate/certificate.component';
import { CommercialVechiclesComponent } from './commercial/commercial-vechicles/commercial-vechicles.component';
import { CommercialPlanListComponent } from './commercial/commercial-plan-list/commercial-plan-list.component';
import { DontKnowCommercialVechicleComponent } from './dont-know-commercial-vechicle/dont-know-commercial-vechicle.component';
import { BoughtNewCommercialVechiclesComponent } from './bought-new-commercial-vechicles/bought-new-commercial-vechicles.component';
import { DetailsComponent } from './commercial/details/details.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { SummaryPageComponent } from './commercial/summary-page/summary-page.component';
import { HealthSummaryComponent } from './health/health-summary/health-summary.component';
import { PropertyInsuranceComponent } from './Property_Insurance/property-insurance/property-insurance.component';
import { PropertyPolicyComponent } from './Property_Insurance/property-policy/property-policy.component';
import { PropertySummaryComponent } from './Property_Insurance/property-summary/property-summary.component';
import { CommercialPolicyComponent } from './Commercial_Insurance/commercial-policy/commercial-policy.component';
import { TravelinsurancePolicyComponent } from './travelinsurance/travelinsurance-policy/travelinsurance-policy.component';
import { ShriramgeneralPaymentComponent } from './vehicleinsurance/shriramgeneral-payment/shriramgeneral-payment.component';
import { PolicyQuotesComponent } from './travelinsurance/policy-quotes/policy-quotes.component';
import { PartnerHomeComponent } from './partner-home/partner-home.component';
import { TravelDetailsComponent } from './travelinsurance/travel-details/travel-details.component';
import { TravelSummaryComponent } from './travelinsurance/travel-summary/travel-summary.component';
import { HospitalComponent } from './Bussiness_Ragistration/hospital/hospital.component';
import { HospitalBussinessVarificationComponent } from './Bussiness_Ragistration/hospital-bussiness-varification/hospital-bussiness-varification.component';
import { HospitalBankdetailsComponent } from './Bussiness_Ragistration/hospital-bankdetails/hospital-bankdetails.component';
import { DoctorComponent } from './Bussiness_Ragistration/Doctor_Ragistration/doctor/doctor.component';
import { VerificationComponent } from './Bussiness_Ragistration/Doctor_Ragistration/verification/verification.component';
import { DoctorBankDetailsComponent } from './Bussiness_Ragistration/Doctor_Ragistration/doctor-bank-details/doctor-bank-details.component';
import { RelianceGeneralPaymentComponent } from './vehicleinsurance/reliance-general-payment/reliance-general-payment.component';
import { MedicalProfileComponent } from './Bussiness_Ragistration/Madical_Ragistration/medical-profile/medical-profile.component';
import { MedicalVerificationComponent } from './Bussiness_Ragistration/Madical_Ragistration/medical-verification/medical-verification.component';
import { MedicalBankDetailsComponent } from './Bussiness_Ragistration/Madical_Ragistration/medical-bank-details/medical-bank-details.component';
import { GarageProfileComponent } from './Bussiness_Ragistration/Garage_Registration/garage-profile/garage-profile.component';
import { GatageVarificationComponent } from './Bussiness_Ragistration/Garage_Registration/garage-varification/garage-varification.component';
import { GatageBankDetailsComponent } from './Bussiness_Ragistration/Garage_Registration/garage-bank-details/garage-bank-details.component';
import { InfluencerProfileComponent } from './Bussiness_Ragistration/Influencer_Registration/influencer-profile/influencer-profile.component';
import { InfluencerVerficationComponent } from './Bussiness_Ragistration/Influencer_Registration/influencer-verfication/influencer-verfication.component';
import { InfluencerBankComponent } from './Bussiness_Ragistration/Influencer_Registration/influencer-bank/influencer-bank.component';
import { DynamicComponentsComponent } from './components/dynamic-components/dynamic-components.component';
import { HDFCERGOPaymentComponent } from './vehicleinsurance/hdfcergo-payment/hdfcergo-payment.component';
import { IniversalSompoPaymentComponent } from './vehicleinsurance/payment-component/iniversal-sompo-payment/iniversal-sompo-payment.component';
import { DynamicMemberComponent } from './components/dynamic-member/dynamic-member.component';
import { environment } from 'src/environments/environment';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RecaptchaV3Module } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { AddPolicyComponent } from './components/add-policy/add-policy.component';
import { PaymentProgressComponent } from './payment/bike/payment-progress/payment-progress.component';
import { CustomerPolicyListComponent } from './components/customer-policy-list/customer-policy-list.component';
import { PostCertificateComponent } from './agent/components/post-certificate/post-certificate.component';
import { AgentCommissionComponent } from './agent/agent-commission/agent-commission.component';
import { KycResponseComponent } from './payment/bike/kyc-response/kyc-response.component';
import { RegNumberMaskDirective } from './directive/reg-number-mask.directive';
import { DocumentPopoverComponent } from './documents/document-popover/document-popover.component';
export class ClickjackingInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      request = request.clone({

          setHeaders: {

              'X-Frame-Options': 'SAMEORIGIN'

          }

      });

      return next.handle(request);

  }

}


@NgModule({

  declarations: [
    DetailsComponent,
    CommercialVechiclesComponent,
    CommercialPlanListComponent,
    DontKnowCommercialVechicleComponent,
    BoughtNewCommercialVechiclesComponent,
    VehicleQuoteDetailsComponent,
    AgentKycVerificationComponent,
    AgentBankDetailsComponent,
    HealthinsurancePolicyComponent,
    AgentProfileComponent,
    HealthinsurancePlanListComponent,
    HealthinsurancePlanComponent,
    HealthinsuranceDetailsComponent,
    VerifyComponent,
    SuccessComponent,
    carInsuranceOwnerDetailComponent,
    carInsurancePolicyDetailComponent,
    AppComponent,
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,
    ResetPasswordComponent,
    DashboardComponent,
    NavasidebarComponent,
    UnauthorizedComponent,
    RoleComponent,
    RolepermissionsComponent,
    ProfileComponent,
    BusinessInfoComponent,
    DocumentsComponent,
    UsersComponent,
    InsuranceComponent,
    FooterComponent,
    PolicyDetailsComponent,
    ForgotPasswordComponent,
    CarouselComponent,
    PolicyPaymentComponent,
    HomeComponent,
    TermsconditionsComponent,
    PrivacypolicyComponent,
    SettingsComponent,
    QuoteDetailsComponent,
    ProposaldetailsComponent,
    VehicleQuoteDetailsComponent,
    VehicleOwnerDetailsComponent,
    VehiclePolicyDetailsComponent,
    VehiclequotesComponent,
    VehiclePremiumInfoComponent,
    NavbarComponent,
    HealthComponent,
    BikeComponent,
    CarComponent,
    PropertyComponent,
    ReviewvehicledetailsComponent,
    PersonalDetailsComponent,
    FamilyDetailsComponent,
    AssetsComponent,
    AddressesComponent,
    ChangePasswordComponent,
    DontKnowBikeNumberComponent,
    QuotesLoginComponent,
    AboutusComponent,
    CareerComponent,
    SitemapComponent,
    DisclaimerComponent,
    TermsConditionComponent,
    CustomerComponent,
    AddVehicleComponent,
    AddDocumentComponent,
    ManageagentcustomerComponent,
    ManagevehicleComponent,
    ManagepropertyComponent,
    CancellationrefundComponent,
    FaqsComponent,
    ContentComponent,
    GrivanceredressalComponent,
    TestimonialComponent,
    TermInsuranceComponent,
    GroupTermInsuranceComponent,
    SinglepayinsuranceComponent,
    RoyalSundaramPaymentComponent,
    TaxiInsuranceComponent,
    CarInsuranceComponent,
    CommercialVehicleInsuranceComponent,
    ThirdPartyCarInsuranceComponent,
    ThirdPartyTwoWheelerInsuranceComponent,
    CustomerpopoverComponent,
    TwoWheelerInsuranceComponent,
    FamilyHealthPolicyComponent,
    IndividualHealthPolicyComponent,
    GroupHealthInsuranceComponent,
    SeniorHealthPolicyComponent,
    CriticalIllnessPolicyComponent,
    ArogyaSanjivniPolicyComponent,
    TaxSavingPlanComponent,
    MoneyBackPlanComponent,
    GuaranteedReturnPlanComponent,
    ULIPComponent,
    PensionPlanComponent,
    ChildPlanComponent,
    ContactusComponent,
    BikeDetailsComponent,
    HowWeHelpYouComponent,
    WhatIsInsuranceComponent,
    FeatureComponent,
    InsuranceBannerComponent,
    AndroidBannerComponent,
    PartnerComponent,
    NeedHelpComponent,
    BikeInsuranceQuoteComponent,
    InsuranceOwnerDetailComponent,
    InsurancePolicyDetailComponent,
    CarQuoteDetailComponent,
    CarInsuranceQuoteComponent,
    AddTestimonialComponent,
    PaymentCardComponent,
    FailureComponent,
    ComparisonComponent,
    VacancyComponent,
    BikeInsuranceDetailComponent,
    BikeInsuranceOwnerDetailComponent,
    BikeInsurancePolicyDetailComponent,
    PersonalInfoComponent,
    HelpComponent,
    MultiDropdownComponent,
    HeaderMenuComponent,
    InputPincodeMaskDirective,
    PhonenumberMaskDirective,
    NameMaskDirective,
    PancardMaskDirective,
    YearMaskDirective,
    RtoMaskDirective,
    AlphaNuericMaskDirective,
    AutocompleteComponent,
    LifelinePaymentComponent,
    RegisterbusinessComponent,
    ThousandSeparatorPipe,
    AgentDashboardComponent,
    SidebarmenuComponent,
    FutureGeneralPaymentComponent,
    BusinesshomepageComponent,
    AgentfooterComponent,
    AgentcustomerComponent,
    AgenttrainingComponent,
    LiPosptrainingComponent,
    GiPosptrainingComponent,
    LiexamComponent,
    AgentnavbarComponent,
    CertificateComponent,
    SummaryPageComponent,
    HealthSummaryComponent,
    PropertyInsuranceComponent,
    PropertyPolicyComponent,
    PropertySummaryComponent,
    CommercialPolicyComponent,
    TravelinsurancePolicyComponent,
    ShriramgeneralPaymentComponent,
    PolicyQuotesComponent,
    PartnerHomeComponent,
    TravelDetailsComponent,
    TravelSummaryComponent,
    HospitalComponent,
    HospitalBussinessVarificationComponent,
    HospitalBankdetailsComponent,
    DoctorComponent,
    VerificationComponent,
    DoctorBankDetailsComponent,
    TravelSummaryComponent,
    RelianceGeneralPaymentComponent,
    MedicalProfileComponent,
    MedicalVerificationComponent,
    MedicalBankDetailsComponent,
    GarageProfileComponent,
    GatageVarificationComponent,
    GatageBankDetailsComponent,
    InfluencerProfileComponent,
    InfluencerVerficationComponent,
    InfluencerBankComponent,
    DynamicComponentsComponent,
    HDFCERGOPaymentComponent,
    IniversalSompoPaymentComponent,
    DynamicMemberComponent,
    AddPolicyComponent,
    PaymentProgressComponent,
    CustomerPolicyListComponent,
    PostCertificateComponent,
    AgentCommissionComponent,
    KycResponseComponent,
    RegNumberMaskDirective,
    DocumentPopoverComponent
  ],

  imports: [
    CdTimerModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    NgSelectModule,
    PdfViewerModule,
    SocialLoginModule,
    ModalModule.forRoot(),
    AgGridModule,
    CommonModule,
    NgChartsModule,
    NgImageSliderModule,
    TooltipModule.forRoot(),
    RecaptchaV3Module,
    RecaptchaModule,
    RecaptchaFormsModule,
],

  providers: [
      {
        provide: 'SocialAuthServiceConfig',
      useValue: {
          autoLogin: true,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider('654230831370-a114u4r22ehgpfrods4irbj91eoerq9a.apps.googleusercontent.com',
              {
                  // scopes : environment.auth.scopes,
                  prompt: 'none'   // '' | 'none' | 'consent' |  'select_account'
                  }),
              },
                {
                    id: FacebookLoginProvider.PROVIDER_ID,
                  provider: new FacebookLoginProvider('1442566939524545')
              }
            ],
              onError: (err) => {
                  console.error(err);
            }
          } as SocialAuthServiceConfig,
        },
        {

          provide: HTTP_INTERCEPTORS,
    
          useClass: ClickjackingInterceptor,
    
          multi: true
    
    
        },
          { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, AuthGuard, LoginService,
          AppConfig, MenuService, UserService, DocumentService, InsuranceService, QuoteService,
          {
              provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig], multi: true
        },
        {
          provide: RECAPTCHA_SETTINGS,
          useValue: {
            siteKey: environment.recaptcha.siteKey
          } as RecaptchaSettings,
        },
      ],
        entryComponents: [CustomerpopoverComponent],
        bootstrap: [AppComponent]
    })
      export class AppModule {
          //Sticky Header
        isSticky = false;
        @HostListener('window:scroll', ['$event'])
        onScroll(event: Event): void {
            // Add logic here to determine when to make the header sticky
          const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
          if (scrollPosition >= 100) {
              this.isSticky = true;
        } else {
              this.isSticky = false;
        }
      }
    }
      