import { state } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validator, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { filter } from 'rxjs';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { PincodeMaster } from 'src/app/Models/signUp.component.model';
import { InsuranceService } from 'src/app/Services/insurance.service';
import { LookUpService } from 'src/app/Services/lookup.service';
import { PropertyPolicyComponent } from '../property-policy/property-policy.component';
import { GeneralService } from 'src/app/Services/general.service';

@Component({
  selector: 'app-property-insurance',
  templateUrl: './property-insurance.component.html',
  styleUrls: ['./property-insurance.component.css']
})
export class PropertyInsuranceComponent implements OnInit {
  isHomePopup: boolean = false;
  isShopPopup: boolean = false;
  isOfficePopup: boolean = false;
  pages: string = '1';
  shopPage: string = '1';
  officePage: string = '1'
  isShopOwner: boolean = true;
  isShowRented: boolean = false;
  isOfficeOwner: boolean = true;
  isOfficeRented: boolean = false;
  rentedButton: boolean = false;
  firstbutton: boolean = true;
  first: boolean = true;
  second: boolean = false;
  third: boolean = false;
  thirdButton: boolean = true;
  fourthButton: boolean = true;

  four: boolean = false;
  five: boolean = false;
  Shopfirst: boolean = true;
  Shopsecond: boolean = false;
  Shopthird: boolean = false;
  Shopfour: boolean = false;
  Shopfive: boolean = false;
  Officefirst: boolean = true;
  Officesecond: boolean = false;
  Officethird: boolean = false;
  Officefour: boolean = false;
  Officefive: boolean = false;
  six: boolean = false;
  seven: boolean = false;
  verify: boolean = false;
  scroll: boolean = false;
  scroll1: boolean = false;
  scroll2: boolean = false;
  Office1: boolean = true;
  Office2: boolean = true;
  Office3: boolean = true;
  Office4: boolean = true;
  Office5: boolean = true;
  shop1: boolean = true;
  shop2: boolean = true;
  shop3: boolean = true;
  shop4: boolean = true;
  shop5: boolean = true;
  expeiry: boolean = false;
  shopType: boolean = false;
  homeRentedDetails: FormGroup;
  buildingAge: any = [];
  HomeDetails: FormGroup;
  officeType: boolean = false;
  shopDetail: FormGroup;
  officeDetail: FormGroup;
  homeExpiry: FormGroup;
  propertyInsuranceForm: FormGroup;
  shopeExpiry: FormGroup;
  officeExpiry: FormGroup;
  pincodeMaster: PincodeMaster[] = [];
  cityList: string[] = [];
  address: any
  location: FormGroup;
  officeLocation: FormGroup;
  homeLocation: FormGroup;
  homeLocationrent: FormGroup;
  pincodeList: AutoCompleteItem[] = [];
  stateList: PincodeMaster[] = [];

  isPropertyPage: boolean = true;
  showPreviousPolicyCompanyError: boolean = false;
  previousPolicyCompany: string = "";
  insurancePartnersList: AutoCompleteItem[] = [];
  @ViewChild(PropertyPolicyComponent) propertyPolicyComponent: PropertyPolicyComponent;
  constructor(private router: Router, private fb: FormBuilder, private lookUpService: LookUpService, private insuranceService: InsuranceService, private generalService: GeneralService) {
    this.loadInsurancePartners();
    this.getAge();


    this.propertyInsuranceForm = fb.group({
      "insuranceFor": [""],//home/shop/office 
      "propertyType": [""],//owned/rented
      "isNew": [false],
      "homeExpiry": {},
      "shopeExpiry": {},
      "officeExpiry": {},
      "HomeDetails": {},
      "homeRentedDetails": {},
      "shopDetail": {},
      "officeDetail": {},
      "location": {},
      "officeLocation": {},
      "homeLocation": {},
      "homeLocationrent": {},
      "cover": []
    })

    this.homeExpiry = fb.group({
      "expiryPolicy": ["", Validators.required],
      "expiryCompany": ["", Validators.required],
      "expiryDate": ["", Validators.required],
      "claimPolcy": ["", Validators.required],
      "bonus": [""],
    });

    this.shopeExpiry = fb.group({
      "policyStatus": [""],
      "existingCompany": [""],
      "PolicyDate": [""],
      "exsitingclaim": [""],
      "NCB": [""],
      "Shopname": ["", Validators.required],
      "BusinessType": ["Select Your business type", Validators.required],
    });
    this.officeExpiry = fb.group({
      "ExpiryStatus": [""],
      "companyPolicy": [""],
      "PolicyDateExisting": [""],
      "NCBclaim": [""],
      "NCBbonus": [""],
      "NameCompany": ["", Validators.required],
      // "BusinessType":["",Validators.required],
    });
    this.HomeDetails = fb.group({
      "CarpetArea": ["", Validators.required],
      "Rate": ["", Validators.required],
      "Value_of_Building": ["", Validators.required],
      "Floors": ["Select Floors", Validators.required],
      "Rooms": ["", Validators.required],
      "Age": ["Select Age", Validators.required],
      "contentValue": ["", Validators.required],
    });
    this.homeRentedDetails = fb.group({
      "Value_of_content": ["", Validators.required]
    });
    this.shopDetail = fb.group({
      "Area": [""],
      "RateFt": [""],
      "buildingValue": [""],
      "contentVal": ["", Validators.required],
      "Furniture": ["", Validators.required],
      "stokVal": ["", Validators.required],
      "goodHeld": ["", Validators.required],
    });
    this.officeDetail = fb.group({
      "Area_C": [""],
      "RatePerSqrf": [""],
      "buildValue": [""],
      "ContentVal": ["", Validators.required],
      "fixtures": ["", Validators.required],
      "stockVal": ["", Validators.required],
      "trustVal": ["", Validators.required],
    });
    this.location = fb.group({
      "pinCode": ["", Validators.required],
      "State": [""],
      "Citys": ["", Validators.required],
    });
    this.officeLocation = fb.group({
      "officeCode": ["", Validators.required],
      "officeState": [""],
      "officeCity": ["", Validators.required],
    });
    this.homeLocation = fb.group({
      "HomeCode": ["", Validators.required],
      "ownerState": [""],
      "ownerCity": ["", Validators.required],
    });
    this.homeLocationrent = fb.group({
      "rentCode": ["", Validators.required],
      "rentState": [""],
      "rentCity": ["", Validators.required],
    });
  }
  images: any[] = [
    './assets/partners/new/digit.png',
    './assets/partners/new/sompo.png',
    './assets/partners/new/shriram.png',
    './assets/partners/new/reliance.png'
  ]
  images1: any[] = [
    './assets/partners/new/icici.png',
    './assets/partners/new/oriental.png',
    './assets/partners/new/bajaj.png',
    './assets/partners/new/national.png'
  ]
  showAllImages = false;
  openItemIndex = -1;
  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  text = '';
  types = 0;
  getValue(types: number) {
    this.types = types;
    if (types == 1) {
      this.text = 'Covers the full cost of repairs or replacements without accounting for depreciation, ensuring you receive the maximum claim amount.';
    }
    else if (types == 2) {
      this.text = 'Consumable cover reimburses the cost of items like engine oil, nuts, bolts, and other consumables used during bike repairs.';
    }
    else if (types == 3) {
      this.text = 'Return to Invoice cover reimburses the difference between your bike’s insured declared value and its original invoice value in case of total loss or theft.';
    }
    else if (types == 4) {
      this.text = 'Roadside assistance provides emergency support for issues like breakdowns, flat tires, or fuel shortages, ensuring help is available wherever you are.';
    }
    else if (types == 5) {
      this.text = 'Engine and gearbox protection covers repair or replacement costs for damage caused to the engine and transmission system due to issues like water ingress or mechanical failure.';
    }
    else if (types == 6) {
      this.text = 'Daily allowance provides a fixed amount of compensation for each day your bike is under repair at a garage, helping to cover alternative transportation costs.';
    }

  }

  ngOnInit(): void {
    this.getValue(1);
  }
  shopPopup() {
    this.isShopPopup = true;
    this.propertyInsuranceForm.get("insuranceFor")?.setValue("shop");
  }
  homePopup() {
    this.isHomePopup = true;
    this.propertyInsuranceForm.get("insuranceFor")?.setValue("home");
  }
  officePopup() {
    this.isOfficePopup = true;
    this.propertyInsuranceForm.get("insuranceFor")?.setValue("office");
  }
  closeModal() {
    this.isShopPopup = false;
    this.isOfficePopup = false;
    this.isHomePopup = false;
    this.scroll = false;
    this.scroll1 = false;
    this.scroll2 = false;
    //location.reload();
  }
  selectedPolicy: string = '';
  policy(val: string) {
    this.officeType = true;
    this.selectedPolicy = val;
    this.shopType = true;
    this.scroll2 = true;
    this.scroll1 = true;
    if (this.selectedPolicy == 'new') {
      this.isButton = false;
      this.scroll = false;
    }
    if (this.selectedPolicy == 'existing') {
      this.isButton = true;
    }
    if (this.selectedPolicy == 'newShop') {
      this.scroll1 = false;
    }
    if (this.selectedPolicy == 'OfficeNew') {
      this.scroll2 = false;
    }

    this.propertyInsuranceForm.get("isNew")?.setValue(this.selectedPolicy == 'new' || this.selectedPolicy == 'newShop' || this.selectedPolicy == 'OfficeNew');
    console.log(this.propertyInsuranceForm.value)
  }
  isButton: boolean = true;
  homeType: string = '';
  home(ty: string) {
    this.homeType = ty;
    this.expeiry = true;
    // this.isButton=false;
    if (this.homeType == 'rented') {
      this.firstbutton = false;
      this.rentedButton = true;
    }
    if (this.homeType == 'owned') {
      this.firstbutton = true;
      this.rentedButton = false;
    }
    this.propertyInsuranceForm.get("propertyType")?.setValue(this.homeType);
  }
  ShopPage(sp: string) {

    this.shopPage = sp;
    if (this.shopPage == '1') {
      this.Shopfirst = true;
      this.Shopsecond = false;
      this.Shopthird = false;
      this.Shopfour = false;
      this.Shopfive = false;

    }
    if (this.shopPage == '2') {
      this.Shopfirst = false;
      this.Shopsecond = true;
      this.Shopthird = false;
      this.Shopfour = false;
      this.Shopfive = false;

    }
    if (this.shopPage == '3') {
      this.Shopfirst = false;
      this.Shopsecond = false;
      this.Shopthird = true;
      this.Shopfour = false;
      this.Shopfive = false;
    }

    if (this.shopPage == '4') {
      this.Shopfirst = false;
      this.Shopsecond = false;
      this.Shopthird = false;
      this.Shopfour = true;
      this.Shopfive = false;
    }

    if (this.shopPage == '5') {
      this.Shopfirst = false;
      this.Shopsecond = false;
      this.Shopthird = false;
      this.Shopfour = false;
      this.Shopfive = true;
      this.propertyInsuranceForm.get("location")?.setValue(this.location.value);
    }
    if (this.shopPage == '6') {
      this.Shopfirst = false;
      this.Shopsecond = false;
      this.Shopthird = false;
      this.Shopfour = false;
      this.Shopfive = false;

    }
    if (this.shopPage == '7') {
      this.Shopfirst = false;
      this.Shopsecond = false;
      this.Shopthird = false;
      this.Shopfour = false;
      this.Shopfive = false;

    }
  }
  OfficePage(sp: string) {
    this.officePage = sp;
    if (this.officePage == '1') {
      this.Officefirst = true;
      this.Officesecond = false;
      this.Officethird = false;
      this.Officefour = false;
      this.Officefive = false;

    }
    if (this.officePage == '2') {
      this.Officefirst = false;
      this.Officesecond = true;
      this.Officethird = false;
      this.Officefour = false;
      this.Officefive = false;

    }
    if (this.officePage == '3') {
      this.Officefirst = false;
      this.Officesecond = false;
      this.Officethird = true;
      this.Officefour = false;
      this.Officefive = false;

    }

    if (this.officePage == '4') {
      this.Officefirst = false;
      this.Officesecond = false;
      this.Officethird = false;
      this.Officefour = true;
      this.Officefive = false;

    }

    if (this.officePage == '5') {
      this.Officefirst = false;
      this.Officesecond = false;
      this.Officethird = false;
      this.Officefour = false;
      this.Officefive = true;

    }

  }
  img: string = '1';
  Page(pg: string) {
    this.img = pg;
    this.pages = pg;
    if (this.pages == '1') {

      this.rentedButton = false;
      this.firstbutton = false; this.firstbutton = true;
      this.first = true;
      this.second = false;
      this.third = false;
      this.four = false;
      this.five = false;
      this.six = false;
      this.seven = false;
      this.verify = false;

    }

    if (this.pages == '2') {

      this.rentedButton = false;
      this.firstbutton = false;
      this.first = false;
      this.second = true;
      this.third = false;
      this.four = false;
      this.five = false;
      this.six = false;
      this.seven = false;
      this.verify = false;

    }

    if (this.pages == '3') {
      this.rentedButton = false;

      this.firstbutton = false;
      this.first = false;
      this.second = false;
      this.third = true;
      this.four = false;
      this.five = false;
      this.six = false;
      this.seven = false;
      this.verify = false;

    }

    if (this.pages == '4') {

      this.rentedButton = false;
      this.firstbutton = false;
      this.first = false;
      this.second = false;
      this.third = false;
      this.four = true;
      this.five = false;
      this.six = false;
      this.seven = false;
      this.verify = false;
    }

    if (this.pages == '5') {

      this.rentedButton = false;
      this.firstbutton = false;
      this.first = false;
      this.second = false;
      this.third = false;
      this.four = false;
      this.five = true;
      this.six = false;
      this.seven = false;
      this.verify = true;
    }
    if (this.pages == '6') {

      this.rentedButton = false;
      this.firstbutton = false;
      this.first = false;
      this.second = false;
      this.third = false;
      this.four = false;
      this.five = false;
      this.six = true;
      this.seven = false;
      this.verify = false;
    }
    if (this.pages == '7') {

      this.rentedButton = false;
      this.firstbutton = false;
      this.first = false;
      this.second = false;
      this.third = false;
      this.four = false;
      this.five = false;
      this.six = false;
      this.seven = true;
      this.verify = true;
    }

  }

  coverType: string = '';
  cover(co: string) {
    this.coverType = co;
    this.thirdButton = false;
  }
  dwellingType: string = '';
  dwelling(dl: string) {
    this.dwellingType = dl;
    this.fourthButton = false;
  }
  construcType: string = '';
  construct(con: string) {
    this.construcType = con;
  }
  getQuotes() {
    this.propertyInsuranceForm.get("shopDetail")?.setValue(this.shopDetail.value);
    this.propertyInsuranceForm.get("location")?.setValue(this.location.value);
    this.propertyInsuranceForm.get("shopeExpiry")?.setValue(this.shopeExpiry.value);

    this.propertyInsuranceForm.get("homeExpiry")?.setValue(this.homeExpiry.value);
    this.propertyInsuranceForm.get("HomeDetails")?.setValue(this.HomeDetails.value);
    this.propertyInsuranceForm.get("homeLocation")?.setValue(this.homeLocation.value);
    this.propertyInsuranceForm.get("homeLocationrent")?.setValue(this.homeLocationrent.value);
    this.propertyInsuranceForm.get("homeRentedDetails")?.setValue(this.homeRentedDetails.value);

    this.propertyInsuranceForm.get("officeDetail")?.setValue(this.officeDetail.value);
    this.propertyInsuranceForm.get("officeExpiry")?.setValue(this.officeExpiry.value);
    this.propertyInsuranceForm.get("officeLocation")?.setValue(this.officeLocation.value);

    console.log(this.propertyInsuranceForm.value);
    this.isPropertyPage = false;
    this.isHomePopup = false;
    this.isShopPopup = false;
    this.isOfficePopup = false;
    this.propertyPolicyComponent.getQuotes(this.propertyInsuranceForm.value);
    //this.router.navigate(['/property-policy'])
  }

  type: string = ';'
  Type(st: string) {
    this.type = st;
    this.shop1 = false;
    this.Office1 = false;
    if (this.type == "owned") {
      this.isShopOwner = true;
      this.isShowRented = false;
    }
    if (this.type == "rented") {
      this.isShopOwner = false;
      this.isShowRented = true;
    }
    if (this.type == "Officeowner") {
      this.isOfficeOwner = true;
      this.isOfficeRented = false;
    }
    if (this.type == "Officerented") {
      this.isOfficeOwner = false;
      this.isOfficeRented = true;
    }
    this.propertyInsuranceForm.get("propertyType")?.setValue(this.type == "owned" || this.type == "Officeowner" ? "owned" : "rented");
  }
  expiryValue: string;
  expiry(event: string) {
    this.scroll = true;

    this.expiryValue = event;
  }
  Shopexpiry(event: string) {

    this.expiryValue = event;
  }
  claimPolicy: string;
  claim(event: string) {
    // this.scroll=true;
    this.claimPolicy = event;
  }
  Shopclaim(event: string) {

    this.claimPolicy = event;
    // this.shop2=false;
  }

  async getAge() {
    this.buildingAge = [];
    for (let i = 1980; i <= 2024; i++) {
      this.buildingAge.push(i);
    }
  }
  sum: string;
  Insured(val: string) {
    this.shop2 = false;
    this.sum = val;
    this.Office2 = false;
  }
  covers() {
    this.Office4 = false;
  }
  isStock: boolean = false;
  isContent: boolean = false;
  isStructure: boolean = false;
  isOfficeStock: boolean = false;
  isOfficeContent: boolean = false;
  isOfficeStructure: boolean = false;
  scrolstyle: boolean = false;
  scrolstyle1: boolean = false;
  building: string;
  content: string;
  stock: string;
  ShopCover(val: string) {
    let selectedCovers = [];
    if (val == 'structure') {
      this.isStructure = !this.isStructure;
      this.building = "structure";
      localStorage.setItem('structure', this.building);
    }

    if (val == 'content') {
      this.content = 'content';
      localStorage.setItem('content', this.content);
      this.isContent = !this.isContent;
    }
    if (val == 'stock') {
      this.isStock = !this.isStock;
      this.stock = 'stock'
      localStorage.setItem('stock', this.stock);
    }
    if (this.isStructure && this.isContent && this.isStock) {
      this.scrolstyle = true;
    }
    else {
      this.scrolstyle = false;;

    }
    this.shop4 = false;

    if (this.isStructure == true) {
      selectedCovers.push("structure")
    }
    if (this.isContent == true) {
      selectedCovers.push("content")
    }
    if (this.isStock == true) {
      selectedCovers.push("stock")
    }

    this.propertyInsuranceForm.get("cover")?.setValue(selectedCovers);
  }

  OfficeCover(val: string) {
    if (val == 'Officestructure') {
      this.isOfficeStructure = !this.isOfficeStructure;
    }
    if (val == 'OffilceContent') {
      this.isOfficeContent = !this.isOfficeContent;
    }
    if (val == 'Offilcestock') {
      this.isOfficeStock = !this.isOfficeStock;
    }
    if (this.isOfficeStructure && this.isOfficeContent && this.isOfficeStock) {
      this.scrolstyle1 = true;
    }
    else {
      this.scrolstyle1 = false;;

    }
    this.Office4 = false;
  }
  bsType: string = '';
  isOther: boolean = false;
  BussinessType(bs: string) {
    this.bsType = bs;
    if (this.bsType == "Others") {
      this.isOther = true;
    }
    else {
      this.isOther = false;
    }

  }
  OfficeState: any;
  ownerState: any;
  rentState: any;
  myState: any;
  async pincodeUserChangeEvent(pincode: any) {
    const res = await this.lookUpService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.pincodeMaster = res.result;
      this.cityList = this.uniqueBy(this.pincodeMaster, "district");
      // this.pincodeMasterId = this.pincodeMaster?.id;
      this.myState = this.pincodeMaster && this.pincodeMaster.length > 0 ? this.pincodeMaster[0].state : '';
      this.OfficeState = this.pincodeMaster && this.pincodeMaster.length > 0 ? this.pincodeMaster[0].state : '';
      this.ownerState = this.pincodeMaster && this.pincodeMaster.length > 0 ? this.pincodeMaster[0].state : '';
      this.rentState = this.pincodeMaster && this.pincodeMaster.length > 0 ? this.pincodeMaster[0].state : '';
    }

  }


  async changeCity(value: any) {
    this.address = this.pincodeMaster.filter(x => x.id == value);

  }

  //shop
  previousPolicyCompanyChangeShop(data: any) {
    this.shopeExpiry.get('existingCompany')?.setValue(data.target.value);
  }

  onPrevCompSelectionShop(data: AutoCompleteItem) {
    this.shopeExpiry.get('existingCompany')?.setValue(data.text);
    this.previousPolicyCompany = data.text;
  }

  onPrevCompBlurShop(data: any) {
    this.shopeExpiry.get('existingCompany')?.setValue(data);
    this.previousPolicyCompany = data;
  }

  //business
  previousPolicyCompanyChangeBusiness(data: any) {
    this.officeExpiry.get('companyPolicy')?.setValue(data.target.value);
  }

  onPrevCompSelectionBusiness(data: AutoCompleteItem) {
    this.officeExpiry.get('companyPolicy')?.setValue(data.text);
    this.previousPolicyCompany = data.text;
  }

  onPrevCompBlurBusiness(data: any) {
    this.officeExpiry.get('companyPolicy')?.setValue(data);
    this.previousPolicyCompany = data;
  }

  //home
  previousPolicyCompanyChangeHome(data: any) {
    this.homeExpiry.get('expiryCompany')?.setValue(data.target.value);
  }

  onPrevCompSelectionHome(data: AutoCompleteItem) {
    this.homeExpiry.get('expiryCompany')?.setValue(data.text);
    this.previousPolicyCompany = data.text;
  }

  onPrevCompBlurHome(data: any) {
    this.homeExpiry.get('expiryCompany')?.setValue(data);
    this.previousPolicyCompany = data;
  }

  // async changeState(state:any){
  // this.myState=this.pincodeMaster.filter(x => x.id == state)
  // }

  async loadInsurancePartners() {
    let response = await this.lookUpService.GetInsurancePartnersList();
    ;
    if (response?.isSuccess) {
      this.insurancePartnersList = response.result.map((m: any) => { return { text: m.insuranceName, value: m.insuranceName, id: m.insuranceName } });
    }
  }

  uniqueBy(arr: any, prop: any) {
    return this.generalService.uniqueBy(arr, prop);
  }
}
