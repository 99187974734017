<form [formGroup]="vehicleInfoForm" class="needs-validation" novalidate="" autocomplete="off">
  <div class="offcanvas-header" *ngIf="!IsProfile">
    <h4 class="offcanvas-title" id="offcanvasScrollingLabel">{{title}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" #closebtn></button>
  </div>
  <div class="offcanvas-body">
    <div class="">
      <h5>Select Vehicle Type</h5>
      <div class="row justify-content-between p-3">
        <div class="col card card-type p-4" [ngClass]="{'selected-card': vehicleInfoForm.get('vehicleType')?.value === 2}">
          <div class="d-flex justify-content-between">
            <span class="text-grey5">Two Wheeler</span>
            <input type="radio" class="mt-0 radio-button" id="vehicleType1" [value]="2" formControlName="vehicleType" [checked]="vehicleInfoForm.get('vehicleType')?.value === 2" (change)="getVehicleCompanyDetails(2)">
          </div>
        </div>&nbsp;&nbsp;
        <div class="col card p-4 card-type"  [ngClass]="{'selected-card': vehicleInfoForm.get('vehicleType')?.value === 1}">
          <div class="d-flex justify-content-between">
            <span class="text-grey5">Four Wheeler</span>
            <input type="radio" class="mt-0 radio-button" id="vehicleType2" [value]="1" formControlName="vehicleType" [checked]="vehicleInfoForm.get('vehicleType')?.value === 1" (change)="getVehicleCompanyDetails(1)">
          </div>
        </div>
      </div>

    </div>
    <div class="form-group">
      <label for="number" class="input-label required">Registration Number</label>
      <input type="text" class="form-control" placeholder="Registration Number" formControlName="registrationNumber" name="registrationNumber" oninput="this.value = this.value.toUpperCase()">
    </div>
    <div class="form-group">
      <label for="make" class="input-label required">Make</label>
      <select class="form-select form-select-custom" #myMake (change)='getVehicleModelDetails(myMake.value)'
              aria-label="Default select example" name="Make" formControlName="make">
        <option value="" selected>Select Make</option>
        <option *ngFor="let company of vehicleCompanyList" [value]="company">
          {{company}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="Model" class="input-label required">Model</label>
      <select class="form-select form-select-custom" #myModel (change)='getVehicleVariantDetails(myModel.value)' name="model"
              formControlName="model" aria-label="Default select example">
        <option value="" selected>Select Model</option>
        <option *ngFor="let model of vehicleModelList" [value]="model">
          {{model}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="make" class="input-label required">Variant</label>
      <select class="form-select form-select-custom" name="variant" formControlName="variant" aria-label="Default select example">
        <option value="" selected>Select Variant</option>
        <option *ngFor="let varaint of vehicleVariantList" [value]="varaint">
          {{varaint}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="make" class="input-label required">Registration Year</label>
      <select class="form-select form-select-custom" name="registrationYear" formControlName="registrationYear"
              aria-label="Default select example">
        <option value="" selected>Select Registration Year</option>
        <option *ngFor="let reg of registrationYear; let i = index;" [value]="reg">
          {{reg}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="number" class="input-label required">Policy Expiry Date</label>
      <input type="date" name="policyExpiryDate" class="form-control" formControlName="policyExpiryDate">
    </div>
    <div class="position-absolute bottom-0 w-100">
      <!-- <button class="btn btn-primary w-100" (click)="insertVehicleInfo()" disabled]="vehicleInfoForm.invalid" data-bs-dismiss="offcanvas">{{title}}</button> -->
      <button class="btn btn-primary w-100" [disabled]="vehicleInfoForm.invalid" data-bs-dismiss="offcanvas"
              (click)="insertVehicleInfo()">
        {{title}}
      </button>
    </div>
  </div>
</form>
