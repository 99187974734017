<div class="needs-validation">
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" id="offcanvasScrollingLabel">Add Customer</h4>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" #closebtn></button>
  </div>
  <div class="offcanvas-body">
    <form id="kt_modal_add_user_form" class="form" [formGroup]="customerForm">
      <div class="flex-column row" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
        <div class="col form-group">
          <label class="input-label required" for="name">First Name</label>
          <input type="text" placeholder="First Name" class="form-control" formControlName="firstName">
        </div>
        <div class="col form-group">
          <label class="input-label required" for="name">Last Name</label>
          <input type="text" placeholder="Last Name" class="form-control" formControlName="lastName">
        </div>
        <div class="col form-group">
          <label class="input-label required" for="mobile">Your Mobile</label>
          <div></div>
          <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                              [searchCountryFlag]="false" [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                              [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" [separateDialCode]="separateDialCode"
                              name="phone" formControlName="phoneNumber" [inputId]="'phone_number'"
                              (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

          </ngx-intl-tel-input>
        </div>
        <div class="col form-group">
          <label class="input-label required" for="Email">Your Email Address</label>
          <input type="text" placeholder="useremailadd@domain.com" class="form-control" formControlName="userEmail">
        </div>
        <div class="col form-group">
          <label class="input-label required" for="Date">Gender</label><br>
          <input class="my-3 radio-button" type="radio" id="male" name="gender" value="Male" formControlName="gender">
          Male
          &nbsp;&nbsp;
          <input class="my-3 radio-button" type="radio" id="female" name="gender" value="Female" formControlName="gender">
          Female &nbsp;&nbsp;
          <input class="my-3 radio-button" type="radio" id="other" name="gender" value="Other" formControlName="gender">
          Other
        </div>
        <div class="col form-group">
          <label class="input-label required" for="dob">Date of birth</label>
          <input type="date" placeholder="DD-MM-YYYY" name="dob" autocomplete="off" class="new-user form-control bg-transparent" formControlName="dob" />

        </div>
        <div class="col form-group">
          <label class="input-label required" for="Street">Street Address</label>
          <input type="text" placeholder="Street Address" class="form-control" formControlName="streetAddress">
        </div>
        <div class="col form-group">
          <label class="input-label required" for="Pincode">Your Pincode</label>
          <input type="text" placeholder="Pincode" class="form-control" formControlName="pincode" #pinCode
                 (blur)="pincodeUserChangeEvent(pinCode)">
          <!-- <label class="mt-2"> {{pincodeMaster.district}} {{pincodeMaster.state}} {{pincodeMaster.country}}</label> -->
        </div>
        <div class="col position-absolute bottom-0" >
          <button class="btn btn-primary w-100" (click)="submit()" [disabled]="customerForm.invalid">Save</button>
        </div>
      </div>

    </form>
  </div>
</div>
