import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { GeneralService } from 'src/app/Services/general.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-payment-progress',
  templateUrl: './payment-progress.component.html',
  styleUrls: ['./payment-progress.component.css']
})
export class PaymentProgressComponent {
  isUserLogged: boolean = false;
  @Input() paymentObject:any;
  //paymentUrl
  //insuranceCompany
  //premium
  constructor(private generalService : GeneralService,public clipboard: Clipboard){
    if (this.generalService.getUniqueuserid() != "") {
      this.isUserLogged = true;
    }
  }

  onCoplyClick(){
    this.clipboard.copy(this.paymentObject.paymentUrl); 
    this.showContentCopyMsg('Link coppied to clipboard',false);
  }

  showContentCopyMsg(msg:string, isError:boolean){
    swal.fire({
      position: 'center',
      imageUrl: "../../assets/alerts/" + (isError ? "warning" : "success") + ".png",
      imageHeight: 89,
      title: msg,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Ok',
      cancelButtonColor: isError ? "#DF3A4D" : "#0DB561",
    }); 
  }
}
