import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddVehicleComponent } from '../add-vehicle/add-vehicle.component';
import { AddDocumentComponent } from '../add-document/add-document.component';
import { CustomerViewModel } from '../Models/customer-model';
import { UserService } from '../Services/user.service';
import { ManageagentcustomerComponent } from '../manageagentcustomer/manageagentcustomer.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManagevehicleComponent } from '../managevehicle/managevehicle.component';
import { CustomerpopoverComponent } from './customerpopover/customerpopover.component';
import { AddPolicyComponent } from '../components/add-policy/add-policy.component';
import { DocumentService } from '../Services/document.service';
import { DocumentPopoverComponent } from '../documents/document-popover/document-popover.component';
import * as moment from 'moment';
import swal from 'sweetalert2';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  customerList: any = [];


  @ViewChild(ManageagentcustomerComponent) ManageagentcustomerComponent: ManageagentcustomerComponent;
  @ViewChild(AddDocumentComponent) AddDocumentComponent: AddDocumentComponent;
  @ViewChild(ManagevehicleComponent) ManagevehicleComponent: ManagevehicleComponent;
  @ViewChild(AddPolicyComponent) AddPolicyComponent: AddPolicyComponent;
  columnDefsCustomers: any[];
  columnDefsDocuments: any[];
  defaultColDef: { resizable: boolean; sortable: boolean; };
  paginationPageSize: number;
  gridOptions: any;
  customer:any;
  userDocumentList: any = [];
  docPanel:boolean = false;
  vehPanel:boolean = false;
  constructor(private userService: UserService, private spinner: NgxSpinnerService, private documentservice:DocumentService) {
    this.gridOptions = {
      context: {
        componentMember: this
      }
    };

  }

  async ngOnInit() {

    await this.loadGridSettings();
    await this.GridFieldsLoad();
    await this.LoadGridData();

    //for Documents
    this.GridDocumentFieldsLoad();
  }


  async loadGridSettings() {
    this.columnDefsCustomers = [];
    this.columnDefsDocuments = [];
    this.defaultColDef = { resizable: true, sortable: true };
    this.paginationPageSize = 10;
    return "";
  }


  async GridFieldsLoad() {
    let Name = 'Name';
    let Email = 'Email';
    let PhoneNumber = 'Phone Number';
    let Status = 'Status';
    let StreetAddress = 'Street Address';
    let PinCode = 'Pincode';
    let Action = 'Action';

    let columnDefs = [
      { headerName: Action, field: "uniqueUserId", headerTooltip: Action, cellRenderer: CustomerpopoverComponent, "width": 80, "minWidth": 80, sortable: false, cellClass:["text-center"] },
      { headerName: Name, field: "Name", headerTooltip: Name, cellRenderer: this.namecellrender, autoHeight: true, cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly"], flex: 1 },
      { headerName: Email, field: "userEmail", headerTooltip: Email, autoHeight: true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"],  flex: 1 },
      { headerName: PhoneNumber, field: "phoneNumber", headerTooltip: PhoneNumber, autoHeight: true, cellClass: ["ag-focus", "clippingtext"],  flex: 1 },
      { headerName: StreetAddress, field: "streetAddress", headerTooltip: StreetAddress, autoHeight: true, cellClass: ["ag-focus", "clippingtext"], flex: 1 },
      { headerName: PinCode, field: "pincode", headerTooltip: PhoneNumber, autoHeight: true, cellClass: ["ag-focus", "clippingtext"], flex: 1 },
      { headerName: Status, field: "Status", headerTooltip: Status, cellRenderer: this.statuscellrender, autoHeight: true, flex: 1 }
      
    ];

    this.columnDefsCustomers = columnDefs;
    return "";
  }



  namecellrender(param: any) {
    return `${param.data.firstName} ${param.data.lastName}`;
  }

  statuscellrender(param: { data: { isActive: any; }; value: string; }) {
    if (param.data.isActive) {
      return `Active`;
    }
    else {
      return 'Inactive';
    }
  }
  async LoadGridData() {
    this.spinner.show();
    this.customerList = [];
    const response = await this.userService.GetAgentCustomerList();
    if (response?.isSuccess) {
      this.customerList = response.result;
      this.spinner.hide();
    }

  }
  onGridReadyCustomers(params: any) {
    this.LoadGridData();
  }


  async EditCustomer(customer: any) {
    this.customer = customer;  
    this.GetUserDocuments();  
  }

  async EditCustomer1(customer: any) {
    await this.ManageagentcustomerComponent.EditCustomer(customer);    
  }

  addDocument(uniqueUserId: string) {
    setTimeout(() => { this.AddDocumentComponent.UpdateUniqueUserId(uniqueUserId) }, 100);
  }

  addVehicle(uniqueUserId: string) {
    setTimeout(() => { this.ManagevehicleComponent.UpdateUniqueUserId(uniqueUserId) }, 100);
  }

  addPolicy(uniqueUserId: string) {  
    setTimeout(() => { this.AddPolicyComponent.UpdateUniqueUserId(uniqueUserId) }, 100);
  }

  editDocument(document: any) {
    setTimeout(() => { this.AddDocumentComponent.updateDocumentData(document) }, 100);
  }

  async downloadDocument(doc: any) {
    this.spinner.show();
    var base64 = "";
    let fileName = ""
    if(doc?.documentBase64?.length > 100){
      base64 = doc.documentBase64;
      fileName = doc.documentUniqueId+ "."+ this.GetFileExtension(base64);
    }
    else
    {
      const response = await this.documentservice.DownloadDocument(doc.documentUniqueId,"","");
      if (response?.isSuccess && response?.result?.code == 1 ) {
        base64 = response.result?.data;
        fileName = response.result?.data2;          
      }
    }
    if(base64 != null && base64 != undefined || base64 != ""){
      const downloadLink = document.createElement('a');    
      downloadLink.href = base64;
      downloadLink.download = fileName;
      downloadLink.target = "_blank";
      downloadLink.click();
      this.spinner.hide();
    }
    else{
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: "Document not found!",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      }); 
    }
  }

  async GetUserDocuments() {
    this.spinner.show();
    let request = {userId : this.customer.uniqueUserId}
    const response = await this.documentservice.GetUserDocuments(request);
    if (response?.isSuccess) {
      this.userDocumentList = response.result.documents;
    }
    this.spinner.hide();
  }

  toggleCollapse(panel : string, collapsed:boolean){
    switch(panel){
      case 'doc' : 
      this.docPanel = !collapsed;
      break;
      case 'veh' : 
      this.vehPanel = !collapsed;
      break;
    }
  }

  async GridDocumentFieldsLoad() {
    let DocumentTypeName = 'Document';
    let DocumentNumber = 'Document Number';
    let WhenCreated = 'When Uploaded';
    let Action = 'Action';

    let columnDefs = [
      { headerName: Action, field: "documentUniqueId", headerTooltip: Action, cellRenderer: DocumentPopoverComponent, "width": 90, "minWidth": 90, sortable: false, cellClass:["text-center"] },
      { headerName: DocumentTypeName, field: "documentTypeName", headerTooltip: DocumentTypeName, autoHeight: true, cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly"], flex: 1 },
      { headerName: DocumentNumber, field: "documentNumber", headerTooltip: DocumentNumber, autoHeight: true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], flex: 1 },
      { headerName: WhenCreated, field: "whenCreated", headerTooltip: WhenCreated, cellRenderer: this.docCraatedcellrender, autoHeight: true, cellClass: ["ag-focus", "clippingtext"], flex: 1 }
    ];

    this.columnDefsDocuments = columnDefs;
    return "";
  }
  
  docCraatedcellrender(param:any){
    return `${moment(param.data.whenCreated).format('DD MMM, YYYY hh:mm:ss')}`;
  }

  GetFileExtension(base64String:string)
  {
    if (base64String.startsWith("data:") && base64String.indexOf(";base64,") > 0)
      {
          // Extract base64 string from the input
          let base64Index = base64String.indexOf(";base64,") + ";base64,".length;
          let base64Prefix = base64String.substring(0,base64Index);
          base64String = base64String.substring(base64Index);                
      }
      let data= base64String.substring(0, Math.min(5, base64String.length));      
 
      switch (data.toUpperCase())
      {
          case "IVBOR":
              return "png";
          case "/9J/4":
              return "jpg";
          case "AAAAF":
              return "mp4";
          case "JVBER":
              return "pdf";
          case "AAABA":
              return "ico";
          case "UMFYI":
              return "rar";
          case "E1XYD":
              return "rtf";
          case "U1PKC":
              return "txt";
          case "MQOWM":
          case "77U/M":
              return "srt";
          default:
              return "";
      }
  }
}
