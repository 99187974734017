import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[app-rto-mask]'
})
export class RtoMaskDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    let input = event.target.value;

    // Remove non-alphabet and non-numeric characters
    input = input.replace(/[^A-Z0-9]/g, '');

    // Limit the input to 4 characters (2 letters and 2 numbers)
    input = input.slice(0, 4);

    //input = input.replace(/[A-Z]{2}[0-9]{2}/g,'');

    // Separate the letters and numbers (e.g., "RJ30")
    if (input.length >0 && input.length <2) {
      input = input.replace(/[0-9]/g, '');
    }
    if (input.length >= 2) {
      input = input.slice(0, 2) + input.slice(2).replace(/[^0-9]/g, '');
    }

    this.el.nativeElement.value = input;
  }

}
