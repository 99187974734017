import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse, ApiResponseNew } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async AuthenticateUser(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}login/GetAuthentication`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: HttpErrorResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.error.message;
      res.value = errorRes.error.result;
      return res;
    });
  }

  async GetPartnerAuthentication(data: any,userinfo:any="") {
    return this.http.post<ApiResponse>(`${environment.APIUrl}login/PartnerAuthentication?a=${data}&u=${userinfo}`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: HttpErrorResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.error.message;
      return res;
    });
  }
  

  ResetPassword(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}login/ResetPassword`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }


  ForgotPasword(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}login/ForgotPasword`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  

  async StatusCheck() {
    const url = `${environment.APIUrl}login/VerifyAPI`;
    try {
      const response = await this.http.get(url, {
        headers: new HttpHeaders().set('NoAuthorization', 'True'),
        responseType: 'text'
      }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  async SendOtp(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/SendOtp`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }
  async ValidateOTP(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/ValidateOTPInternal`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }

}
