import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[app-name-mask]'
})
export class NameMaskDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    let input = event.target.value;

    // Remove non-alphabet characters and multiple spaces
    input = input.replace(/[^a-zA-Z\s]/g, '').replace(/\s+/g, ' ');

    this.el.nativeElement.value = input;
  }

}
