import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { LoginService } from '../Services/login.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { MenuService } from '../Services/menu.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { InsuranceService } from 'src/app/Services/insurance.service';
import { NavbarComponent } from 'src/app/navbar/navbar.component';
import { AutoCompleteItem, Item } from '../Models/Item';
import { LookUpService } from '../Services/lookup.service';
import { PincodeMaster } from 'src/app/Models/signUp.component.model';
import { GeneralService } from '../Services/general.service';
import { HealthQuestionForm } from '../Models/HeathQuestionForm';
import { HealthPriceBreakup } from '../Models/vehicle-component.model';
import { AgentprofileService } from '../Services/agentprofile.service';
import * as moment from 'moment';
@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent {
  @Output() gotoEvent = new EventEmitter<any>();
  //#Added By Dinesh
  @Input() healthData: any;
  pincodeMaster: PincodeMaster[] = [];
  planList: any = [];
  finalPremium: string = "";
  premiumObject: any;
  isPayment: boolean = false;
  royalDetails: any;

  //Dinesh End

  ageList: any[];
  feetList: any[];
  inchList: any[];
  isEditPolicy: boolean = false;
  proposalForm: FormGroup;
  membersForm: FormGroup;
  medicalForm: FormGroup;
  nomineeForm: FormGroup;
  vehicleForm: FormGroup;
  showExistingPolicyControls: boolean = true;
  userForm: FormGroup;
  @Input() ownerDetails: any;
  titleList: string[] = ["Mr", "Mrs", "Ms"];
  districtList: AutoCompleteItem[] = [];
  insurancePartnersList: AutoCompleteItem[] = [];
  stateList: AutoCompleteItem[] = [];
  pincodeList: AutoCompleteItem[] = [];
  cityList: AutoCompleteItem[] = [];
  RelationShipList: { id: number; name: string; }[];
  submittedAddress: boolean = false;
  submittedNominee: boolean = false;
  submittedVehicle: boolean = false;
  submittedPolicy: boolean = false;
  isUserLogged: boolean = false;
  royalHealthObj: any;

  today = new Date();
  maxDate = '';
  defaultDate = "";
  cashlesshospitalsCount: number = 0;
  @Input() quoteData: any
  priceBreakup: HealthPriceBreakup = {};

  insuranceDetailsScreen_one: boolean = true;
  insuranceDetailsScreen_two: boolean = false;
  addressInfo: FormGroup;
  @Input() isBike: boolean;
  @Output() action = new EventEmitter();
  // @ViewChild(HeaderMenuComponent) navbarComponent: HeaderMenuComponent;
  constructor(private loginService: LoginService, private router: Router, private fb: FormBuilder,
    private spinner: NgxSpinnerService, private _menuservice: MenuService, public modalRef: BsModalRef,
    private insuranceService: InsuranceService, private _lookupService: LookUpService, private generalService: GeneralService, private agentprofileService: AgentprofileService) {

    this.maxDate = moment(this.today).format('YYYY-MM-DD');
    this.getAge();
    this.getFeet();
    this.getInch();
    this.loginForm = this.fb.group({
      "PhoneNumber": ['', Validators.required],
      "Password": ['', [Validators.required]],
    });
    this.proposerForm = this.fb.group({
      // "proposerSelf": [''],
      "proposerFirstName": ['', [Validators.required]],
      "proposerLastName": ['', [Validators.required]],
      // "proposerGender": ['', [Validators.required]],
      "proposerPancard": ['', [Validators.required, Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "proposerEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "proposerMobile": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "selfDOB": ['', [Validators.required]],
    });

    this.addressInfo = this.fb.group({
      "flatNumber": ['', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "proposerPinCode": ['', [Validators.required]],
      "proposerState": [''],
      "proposerCity": [''],
      "district": [''],
    });

    this.insurerForm = this.fb.group({
      "selfFirstName": ['', [Validators.required]],
      "selfLastName": ['', [Validators.required]],
      "selfDOB": ['', [Validators.required]],
      "selfHeightFeet": ['', [Validators.required]],
      "selfHeightInches": ['', [Validators.required]],
      "selfWeight": ['', [Validators.required]],
      "spouseFirstName": [''],
      "spouseLastName": [''],
      "spouseDOB": [''],
      "spouseHeightFeet": [''],
      "spouseHeightInches": [''],
      "spouseWeight": [''],
      "fatherFirstName": [''],
      "fatherLastName": [''],
      "fatherDOB": [''],
      "fatherHeightFeet": [''],
      "fatherHeightInches": [''],
      "fatherWeight": [''],
      "motherFirstName": [''],
      "motherLastName": [''],
      "motherDOB": [''],
      "motherHeightFeet": [''],
      "motherHeightInches": [''],
      "motherWeight": [''],
      "grandPaFirstName": [''],
      "grandPaLastName": [''],
      "grandPaDOB": [''],
      "grandPaHeightFeet": [''],
      "grandPaHeightInches": [''],
      "grandPaWeight": [''],
      "grandMaFirstName": [''],
      "grandMaLastName": [''],
      "grandMaDOB": [''],
      "grandMaHeightFeet": [''],
      "grandMaHeightInches": [''],
      "grandMaWeight": [''],
      "fatherLawFirstName": [''],
      "fatherLawLastName": [''],
      "fatherLawDOB": [''],
      "fatherLawHeightFeet": [''],
      "fatherLawHeightInches": [''],
      "fatherLawWeight": [''],
      "motherLawFirstName": [''],
      "motherLawLastName": [''],
      "motherLawDOB": [''],
      "motherLawHeightFeet": [''],
      "motherLawHeightInches": [''],
      "motherLawWeight": [''],
      "uncleFirstName": [''],
      "uncleLastName": [''],
      "uncleDOB": [''],
      "uncleHeightFeet": [''],
      "uncleHeightInches": [''],
      "uncleWeight": [''],
      "auntFirstName": [''],
      "auntLastName": [''],
      "auntDOB": [''],
      "auntHeightFeet": [''],
      "auntHeightInches": [''],
      "auntWeight": [''],
      "son1FirstName": [''],
      "son1LastName": [''],
      "son1DOB": [''],
      "son1HeightFeet": [''],
      "son1HeightInches": [''],
      "son1Weight": [''],
      "son2FirstName": [''],
      "son2LastName": [''],
      "son2DOB": [''],
      "son2HeightFeet": [''],
      "son2HeightInches": [''],
      "son2Weight": [''],
      "son3FirstName": [''],
      "son3LastName": [''],
      "son3DOB": [''],
      "son3HeightFeet": [''],
      "son3HeightInches": [''],
      "son3Weight": [''],
      "son4FirstName": [''],
      "son4LastName": [''],
      "son4DOB": [''],
      "son4HeightFeet": [''],
      "son4HeightInches": [''],
      "son4Weight": [''],
      "dau1FirstName": [''],
      "dau1LastName": [''],
      "dau1DOB": [''],
      "dau1HeightFeet": [''],
      "dau1HeightInches": [''],
      "dau1Weight": [''],
      "dau2FirstName": [''],
      "dau2LastName": [''],
      "dau2DOB": [''],
      "dau2HeightFeet": [''],
      "dau2HeightInches": [''],
      "dau2Weight": [''],
      "dau3FirstName": [''],
      "dau3LastName": [''],
      "dau3DOB": [''],
      "dau3HeightFeet": [''],
      "dau3HeightInches": [''],
      "dau3Weight": [''],
      "dau4FirstName": [''],
      "dau4LastName": [''],
      "dau4DOB": [''],
      "dau4HeightFeet": [''],
      "dau4HeightInches": [''],
      "dau4Weight": [''],
      "selfOccupation": [''],
      "spouseOccupation": [''],
      "son1Occupation": [''],
      "son2Occupation": [''],
      "son3Occupation": [''],
      "son4Occupation": [''],
      "dau1Occupation": [''],
      "dau2Occupation": [''],
      "dau3Occupation": [''],
      "dau4Occupation": [''],
      "FatherOccupation": [''],
      "MotherOccupation": [''],
      "grandPaOccupation": [''],
      "grandMaOccupation": [''],
      "fatherLawOccupation": [''],
      "motherLawOccupation": [''],
      "uncleOccupation": [''],
      "auntOccupation": [''],



    });
    this.nomineeForm = this.fb.group({
      "nomineeFirstName": ['', [Validators.required]],
      "nomineeLastName": ['', [Validators.required]],
      "nomineeDOB": ['', [Validators.required, Validators.pattern('[0-9]{2}')]],
      "nomineeRelationWithProposer": [''],
      // "nomineeContactNumber": ['', [Validators.required]],
      "proposerGender": ['', [Validators.required]],
    });

    // sessionStorage.clear();
    // this.StatusCheck();
  }
  ngOnInit() {

    //#region  Added By Dinesh B
    this.getHealthQuotes();
    console.log(this.healthData, "Health Getting Data");
    console.log(this.quoteData, "Quote Getting Data");

    //#endregion 

    this.userForm = this.fb.group({
      "firstName": ['', [Validators.required]],
      "phoneNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "countryCode": [''],
      "email": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "dob": [''],
      "gender": ['Male', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "pincode": ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      "pincodeId": [''],
      "dateOfBirth": ['', [Validators.required]],
      "panNumber": ['', [Validators.required, Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "flatNo": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "district": [''],
      "state": ['', [Validators.required]],
      "title": ['Mr'],
    });


    if (this.healthData.spouseSelected) {
      this.insurerForm.get('spouseFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('spouseLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('spouseDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('spouseOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('spouseHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('spouseHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('spouseWeight')?.setValidators([Validators.required]);

    }

    if (this.healthData.firstSon) {
      this.insurerForm.get('son1FirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('son1LastName')?.setValidators([Validators.required]);
      this.insurerForm.get('son1DOB')?.setValidators([Validators.required]);
      this.insurerForm.get('son1Occupation')?.setValidators([Validators.required]);
      this.insurerForm.get('son1HeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('son1HeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('son1Weight')?.setValidators([Validators.required]);

    }
    if (this.healthData.secondSon) {
      this.insurerForm.get('son2FirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('son2LastName')?.setValidators([Validators.required]);
      this.insurerForm.get('son2DOB')?.setValidators([Validators.required]);
      this.insurerForm.get('son2Occupation')?.setValidators([Validators.required]);
      this.insurerForm.get('son2HeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('son2HeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('son2Weight')?.setValidators([Validators.required]);

    }


    if (this.healthData.thirdSon) {
      this.insurerForm.get('son3FirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('son3LastName')?.setValidators([Validators.required]);
      this.insurerForm.get('son3DOB')?.setValidators([Validators.required]);
      this.insurerForm.get('son3Occupation')?.setValidators([Validators.required]);
      this.insurerForm.get('son3HeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('son3HeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('son3Weight')?.setValidators([Validators.required]);

    }

    if (this.healthData.fourthSon) {
      this.insurerForm.get('son4FirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('son4LastName')?.setValidators([Validators.required]);
      this.insurerForm.get('son4DOB')?.setValidators([Validators.required]);
      this.insurerForm.get('son4Occupation')?.setValidators([Validators.required]);
      this.insurerForm.get('son4HeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('son4HeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('son4Weight')?.setValidators([Validators.required]);

    }
    if (this.healthData.firstdaughter) {
      this.insurerForm.get('dau1FirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('dau1LastName')?.setValidators([Validators.required]);
      this.insurerForm.get('dau1DOB')?.setValidators([Validators.required]);
      this.insurerForm.get('dau1Occupation')?.setValidators([Validators.required]);
      this.insurerForm.get('dau1HeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('dau1HeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('dau1Weight')?.setValidators([Validators.required]);

    }

    if (this.healthData.secondDaughter) {
      this.insurerForm.get('dau2FirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('dau2LastName')?.setValidators([Validators.required]);
      this.insurerForm.get('dau2DOB')?.setValidators([Validators.required]);
      this.insurerForm.get('dau2Occupation')?.setValidators([Validators.required]);
      this.insurerForm.get('dau2HeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('dau2HeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('dau2Weight')?.setValidators([Validators.required]);

    }
    if (this.healthData.thirdDaughter) {
      this.insurerForm.get('dau3FirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('dau3LastName')?.setValidators([Validators.required]);
      this.insurerForm.get('dau3DOB')?.setValidators([Validators.required]);
      this.insurerForm.get('dau3Occupation')?.setValidators([Validators.required]);
      this.insurerForm.get('dau3HeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('dau3HeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('dau3Weight')?.setValidators([Validators.required]);

    }
    if (this.healthData.fourthDaughter) {
      this.insurerForm.get('dau4FirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('dau4LastName')?.setValidators([Validators.required]);
      this.insurerForm.get('dau4DOB')?.setValidators([Validators.required]);
      this.insurerForm.get('dau4Occupation')?.setValidators([Validators.required]);
      this.insurerForm.get('dau4HeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('dau4HeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('dau4Weight')?.setValidators([Validators.required]);



    }
    if (this.healthData.fatherSelected) {
      this.insurerForm.get('fatherFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('FatherOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherWeight')?.setValidators([Validators.required]);

    }
    if (this.healthData.motherSelected) {
      this.insurerForm.get('motherFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('motherLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('motherDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('MotherOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('motherHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('motherHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('motherWeight')?.setValidators([Validators.required]);

    }
    
    if (this.healthData.grandPaSelected) {
      this.insurerForm.get('grandPaFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('grandPaLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('grandPaDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('grandPaOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('grandPaHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('grandPaHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('grandPaWeight')?.setValidators([Validators.required]);

    }
    
    if (this.healthData.grandMaSelected) {
      this.insurerForm.get('grandMaFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('grandMaLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('grandMaDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('grandMaOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('grandMaHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('grandMaHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('grandMaWeight')?.setValidators([Validators.required]);

    }
    if (this.healthData.fatherLawSelected) {
      this.insurerForm.get('fatherLawFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherLawLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherLawDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherLawOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherLawHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherLawHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('fatherLawWeight')?.setValidators([Validators.required]);

    }
    if (this.healthData.MotherLawSelected) {
      this.insurerForm.get('motherLawFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('motherLawLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('motherLawDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('motherLawOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('motherLawHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('motherLawHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('motherLawWeight')?.setValidators([Validators.required]);

    }

    if (this.healthData.uncleSelected) {
      this.insurerForm.get('uncleFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('uncleLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('uncleDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('uncleOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('uncleHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('uncleHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('uncleWeight')?.setValidators([Validators.required]);

    }
    if (this.healthData.auntSelected) {
      this.insurerForm.get('auntFirstName')?.setValidators([Validators.required]);
      this.insurerForm.get('auntLastName')?.setValidators([Validators.required]);
      this.insurerForm.get('auntDOB')?.setValidators([Validators.required]);
      this.insurerForm.get('auntOccupation')?.setValidators([Validators.required]);
      this.insurerForm.get('aunteHeightFeet')?.setValidators([Validators.required]);
      this.insurerForm.get('aunteHeightInches')?.setValidators([Validators.required]);
      this.insurerForm.get('aunteWeight')?.setValidators([Validators.required]);

    }
    // this.vehicleForm = this.fb.group({
    //   "city": [""],
    //   "rTO": [""],
    //   "make": [""],
    //   "model": [""],
    //   "variant": [""],
    //   "registrationYear": [""],
    //   "registrationNumber": ["", [Validators.required, Validators.pattern('^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{2}[0-9]{4}$')]],
    //   "chasisNumber": ["", [Validators.required]],
    //   "engineNumber": ["", [Validators.required]],
    //   "registrationDate": ["", [Validators.required]],
    //   "claimsMadeInPreviousPolicy": ["No"],
    //   "previousPolicyExpiryDate": ["", [Validators.required]],
    //   "ncbprevious": [""],
    //   "typeOfCover": [""],
    //   "vehicleIdv": [""],
    //   "previousPolicyCompany": ["", [Validators.required]],
    //   "previousPolicyNumber": ["", [Validators.required]],
    //   "ncbCurrent": [""],
    //   "firstName": [""],
    //   "lastName": [""],
    //   "vehiclecategory": [""],
    //   "isPreviousPolicyHolder": [""],
    //   "previousPolicyType": ["", [Validators.required]],
    //   "fuelType": [""],
    //   "engineCapacityAmount": [""],
    //   "PACover": [""],
    //   "RegisteredInTheNameOf": [""],
    //   "isValidDrivingLicenseAvailable": [""],
    //   "premium": [""],
    //   "isFinanced": [""],
    //   "financedValue": [""],
    //   "financierName": [""],
    // })

    // this.loadRelationships();
    // this.userForm.get("firstName")?.setValue(this.ownerDetails?.firstName);
    //   this.userForm.get("lastName")?.setValue(this.ownerDetails?.lastName);
    //   if( sessionStorage.getItem('uniqueuserid')){
    //     this.isUserLogged = true;
    //   }
    //   this.loadInsurancePartners();

    //  this.getStatesByCountry('India');
  }

  async pincodeUserChangeEvent(pincode: any) {
    this.addressInfo.get("proposerState")?.patchValue("");
    this.addressInfo.get("proposerCity")?.patchValue("");
    // this.proposerForm.get("proposerPinCode")
    const res = await this._lookupService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.pincodeMaster = res.result;
      this.addressInfo.get("proposerState")?.patchValue(this.pincodeMaster[0].state);
      this.addressInfo.get("proposerCity")?.patchValue(this.pincodeMaster[0].district);

      // this.pincodeMasterId = this.pincodeMaster?.id;
    }
  }
  quoteId: string = "";
  async getHealthQuotes() {
    this.planList = [];
    this.spinner.show();
    
    let response;
    if (this.generalService.getAccessToken() != "") {
      response = await this.insuranceService.GetHealtQuotes(this.quoteData);
    }
    else {
      response = await this.insuranceService.GetOpenHealthQuotes(this.quoteData);
    }

    //const response = await this.insuranceService.GetHealtQuotes(this.quoteData);
    if (response != null) {
      this.planList.push(response?.result?.premiumdetails?.quoteDetails);
      this.quoteId = response?.result?.premiumdetails?.quoteId
      this.finalPremium = this.planList[0]?.finalPremium;
      this.premiumObject = {
        finalPremium: this.planList[0]?.finalPremium,
        grosspremium: this.planList[0]?.grosspremium,
        igst: this.planList[0]?.igst
      }
    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'No Quotes Available',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    this.spinner.hide();
  }



  async continue() {

    this.submitted = true;

    this.fetchData();

    console.log(this.quoteData, "Quote Data");

    this.insuranceDetailsScreen_one = false;
    this.insuranceDetailsScreen_two = true;
    this.royalHealthObj = {
      // insurer1Weight: this.insurerForm?.value?.insurer1Weight?.toString(),
      // child1Weight :this.insurerForm?.value?.child1Weight?.toString(),
      // child2Weight:this.insurerForm?.value?.child2Weight?.toString(),
      proposerMobile: this.proposerForm?.value?.proposerMobile?.toString(),
      // insurer2Weight:this.insurerForm?.value?.insurer2Weight?.toString(),
      nomineeMobileNumber: this.nomineeForm?.value?.nomineeContactNumber?.toString(),
      proposerEmergencyMobile: this.proposerForm?.value?.proposerMobile?.toString(),
      proposerSelf: "true",
      nomineeContactNumber: this.nomineeForm?.value?.nomineeContactNumber?.toString(),
      // insurer2Name:this.insurerForm?.value?.insurer2Name?.toString(),
      // insurer2DOB:this.insurerForm?.value?.insurer2DOB?.toString(),
      // insurer1HeightFeet:this.insurerForm?.value?.insurer1HeightFeet?.toString(),
      nomineeDOB: this.nomineeForm?.value?.nomineeDOB?.toString(),
      proposerFirstName: this.proposerForm?.value?.proposerFirstName?.toString(),
      proposerLastName: this.proposerForm?.value?.proposerLastName?.toString(),
      proposerGender: this.healthData.selfGender,

      //proposerGender: 'Male',
      proposerPancard: this.proposerForm?.value?.proposerPancard?.toString(),
      // insurer1DOB:this.insurerForm?.value?.insurer1DOB?.toString(),
      proposerEmail: this.proposerForm?.value?.proposerEmail?.toString(),
      nomineeRelationWithProposer: this.nomineeForm?.value?.nomineeRelationWithProposer?.toString(),
      //nomineeFullName: this.nomineeForm?.value?.nomineeFullName?.toString(),
      nomineeFirstName: this.nomineeForm?.value?.nomineeFirstName?.toString(),
      nomineeLastName: this.nomineeForm?.value?.nomineeLastName?.toString(),
      nomineeGender: this.nomineeForm?.value?.proposerGender?.toString(),
      QuoteId: this.quoteId,
      noOfAdults: this.quoteData?.noOfAdults,
      noOfChilds: this.quoteData?.noOfChilds,
      noOfPersons: this.quoteData?.noOfPersons,
      InsurerDetails: this.insurerDetails1,
      FlatNo: this.addressInfo?.value?.flatNumber,
      StreetAddress: this.addressInfo?.value?.streetAddress,
      Pincode: this.addressInfo?.value?.proposerPinCode,
      State: this.addressInfo?.value?.proposerState,
      City: this.addressInfo?.value?.proposerCity,
      District: this.addressInfo?.value?.district,
      premiumObject: this.premiumObject,
    }

  }

  async paySecurly() {

    this.spinner.show();

    const response: any = await this.insuranceService.HealthCreateQuote(this.royalHealthObj)
    if (response?.isSuccess && response?.result?.code == 1) {
      if (response?.result?.royalSundaramResponse != null) {
        this.royalDetails = response?.result?.royalSundaramResponse;
        this.isPayment = true;
      }

      else {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: `Policy Created Successfully!`,
          showConfirmButton: false,
          timer: 5000,
        });
        this.router.navigate(['policyDetails']);
      }
    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: response?.result?.message,
        showConfirmButton: false,
        timer: 5000,
      });
    }
    this.spinner.hide();



    // let phone = this.proposalForm.get('phoneNumber')?.value;
    // if (phone.dialCode) {
    //   this.proposalForm.get('countryCode')?.setValue(phone.dialCode);
    //   this.proposalForm.get('phoneNumber')?.setValue(phone.number.replace(phone.dialCode, '').replace(/\s/g, ""));
    // }

    // if (this.proposalForm.valid && this.nomineeForm.valid && this.membersForm.valid) {
    //   let obj = {
    //     UserDetails: this.proposalForm.value,
    //     NomineeDetails: this.nomineeForm.value,
    //     vehicleData:this.membersForm.value
    //   }
    // }
  }

  insurerDetails: any[] = [];
  insurerDetails1: any[] = [];
  activePanel: string = "";
  toggleMembersQuestion(panel: string) {
    ;
    this.activePanel = this.activePanel == panel ? "" : panel;;
  }
  formatInsurerDetails() {
    this.insurerDetails = [];
    ;
    let selfInsurer = {
      relationshipWithProposer: "Self",
      firstName: this.proposerForm?.get('proposerFirstName')?.value,
      lastName: this.proposerForm?.get('proposerLastName')?.value,
      //gender: this.proposerForm?.get('proposerGender')?.value,
      dateOfBirth: this.insurerForm.get('selfDOB')?.value,
      height: this.insurerForm.get('selfHeightFeet')?.value,
      weight: this.insurerForm.get('selfWeight')?.value,
      for: "self",
      selfGender: this.healthData.selfGender,
      gender: this.healthData.selfGender,
      medicalQuestion: {}
    }

    this.insurerDetails.push(selfInsurer);
    ;
    if (this.healthData.spouseSelected) {
      let spouseObj = {
        relationshipWithProposer: "Spouse",
        firstName: this.insurerForm.get('spouseFirstName')?.value,
        lastName: this.insurerForm.get('spouseLastName')?.value,
        gender: this.healthData.selfGender == "Male" ? "FeMale" : "Male",
        dateOfBirth: this.insurerForm.get('spouseDOB')?.value,
        height: this.insurerForm.get('spouseHeightFeet')?.value,
        weight: this.insurerForm.get('spouseWeight')?.value,
        for: "spouse",
        medicalQuestion: {}
      }
      this.insurerDetails.push(spouseObj);
    }

    if (this.healthData.selectedSonAge1 > 0) {
      let son1Obj = {
        relationshipWithProposer: "Son",
        firstName: this.insurerForm.get('son1FirstName')?.value,
        lastName: this.insurerForm.get('son1LastName')?.value,
        gender: "Male",
        dateOfBirth: this.insurerForm.get('son1DOB')?.value,
        height: this.insurerForm.get('son1HeightFeet')?.value,
        weight: this.insurerForm.get('son1Weight')?.value,
        for: "son1",
        medicalQuestion: {}
      }
      this.insurerDetails.push(son1Obj);
    }
    if (this.healthData.selectedSonAge2 > 0) {
      let son2Obj = {
        relationshipWithProposer: "Son",
        firstName: this.insurerForm.get('son2FirstName')?.value,
        lastName: this.insurerForm.get('son2LastName')?.value,
        gender: "Male",
        dateOfBirth: this.insurerForm.get('son2DOB')?.value,
        height: this.insurerForm.get('son2HeightFeet')?.value,
        weight: this.insurerForm.get('son2Weight')?.value,
        for: "son2",
        medicalQuestion: {}
      }
      this.insurerDetails.push(son2Obj);
    }
    if (this.healthData.selectedSonAge3 > 0) {
      let son3Obj = {
        relationshipWithProposer: "Son",
        firstName: this.insurerForm.get('son3FirstName')?.value,
        lastName: this.insurerForm.get('son3LastName')?.value,
        gender: "Male",
        dateOfBirth: this.insurerForm.get('son3DOB')?.value,
        height: this.insurerForm.get('son3HeightFeet')?.value,
        weight: this.insurerForm.get('son3Weight')?.value,
        for: "son3",
        medicalQuestion: {}
      }
      this.insurerDetails.push(son3Obj);
    }
    if (this.healthData.selectedSonAge4 > 0) {
      let son4Obj = {
        relationshipWithProposer: "Son",
        firstName: this.insurerForm.get('son4FirstName')?.value,
        lastName: this.insurerForm.get('son4LastName')?.value,
        gender: "Male",
        dateOfBirth: this.insurerForm.get('son4DOB')?.value,
        height: this.insurerForm.get('son4HeightFeet')?.value,
        weight: this.insurerForm.get('son4Weight')?.value,
        for: "son4",
        medicalQuestion: {}
      }
      this.insurerDetails.push(son4Obj);
    }
    if (this.healthData.selectedDauAge1 > 0) {
      let dau1Obj = {
        relationshipWithProposer: "Daughter",
        firstName: this.insurerForm.get('dau1FirstName')?.value,
        lastName: this.insurerForm.get('dau1LastName')?.value,
        gender: "Female",
        dateOfBirth: this.insurerForm.get('dau1DOB')?.value,
        height: this.insurerForm.get('dau1HeightFeet')?.value,
        weight: this.insurerForm.get('dau1Weight')?.value,
        for: "dau1",
        medicalQuestion: {}
      }
      this.insurerDetails.push(dau1Obj);
    }
    if (this.healthData.selectedDauAge2 > 0) {
      let dau2Obj = {
        relationshipWithProposer: "Daughter",
        firstName: this.insurerForm.get('dau2FirstName')?.value,
        lastName: this.insurerForm.get('dau2LastName')?.value,
        gender: "Female",
        dateOfBirth: this.insurerForm.get('dau2DOB')?.value,
        height: this.insurerForm.get('dau2HeightFeet')?.value,
        weight: this.insurerForm.get('dau2Weight')?.value,
        for: "dau2",
        medicalQuestion: {}
      }
      this.insurerDetails.push(dau2Obj);
    }
    if (this.healthData.selectedDauAge3 > 0) {
      let dau3Obj = {
        relationshipWithProposer: "Daughter",
        firstName: this.insurerForm.get('dau3FirstName')?.value,
        lastName: this.insurerForm.get('dau3LastName')?.value,
        gender: "Female",
        dateOfBirth: this.insurerForm.get('dau3DOB')?.value,
        height: this.insurerForm.get('dau3HeightFeet')?.value,
        weight: this.insurerForm.get('dau3Weight')?.value,
        for: "dau3",
        medicalQuestion: {}
      }
      this.insurerDetails.push(dau3Obj);
    }
    if (this.healthData.selectedDauAge4 > 0) {
      let dau4Obj = {
        relationshipWithProposer: "Daughter",
        firstName: this.insurerForm.get('dau4FirstName')?.value,
        lastName: this.insurerForm.get('dau4LastName')?.value,
        gender: "Female",
        dateOfBirth: this.insurerForm.get('dau4DOB')?.value,
        height: this.insurerForm.get('dau4HeightFeet')?.value,
        weight: this.insurerForm.get('dau4Weight')?.value,
        for: "dau4",
        medicalQuestion: {}
      }
      this.insurerDetails.push(dau4Obj);
    }
    if (this.healthData.fatherSelected) {
      let fatherObj = {
        relationshipWithProposer: "father",
        firstName: this.insurerForm.get('fatherFirstName')?.value,
        lastName: this.insurerForm.get('fatherLastName')?.value,
        gender: "Male",
        dateOfBirth: this.insurerForm.get('fatherDOB')?.value,
        height: this.insurerForm.get('fatherHeightFeet')?.value,
        weight: this.insurerForm.get('fatherHeightInches')?.value,
        for: "father",
        medicalQuestion: {}
      }
      this.insurerDetails.push(fatherObj);
    }
    if (this.healthData.motherSelected) {
      let motherObj = {
        relationshipWithProposer: "Mother",
        firstName: this.insurerForm.get('motherFirstName')?.value,
        lastName: this.insurerForm.get('motherLastName')?.value,
        gender: "Female",
        dateOfBirth: this.insurerForm.get('motherDOB')?.value,
        height: this.insurerForm.get('motherHeightFeet')?.value,
        weight: this.insurerForm.get('motherWeight')?.value,
        for: "mother",
        medicalQuestion: {}
      }
      this.insurerDetails.push(motherObj);
    }
    if (this.healthData.grandPaSelected) {
      let grandPaObj = {
        relationshipWithProposer: "GrandFather",
        firstName: this.insurerForm.get('grandPaFirstName')?.value,
        lastName: this.insurerForm.get('grandPaLastName')?.value,
        gender: "Male",
        dateOfBirth: this.insurerForm.get('grandPaDOB')?.value,
        height: this.insurerForm.get('grandPaHeightFeet')?.value,
        weight: this.insurerForm.get('grandPaWeight')?.value,
        for: "grandPa",
        medicalQuestion: {}
      }
      this.insurerDetails.push(grandPaObj);
    }
    if (this.healthData.grandMaSelected) {
      let grandMaObj = {
        relationshipWithProposer: "GrandMother",
        firstName: this.insurerForm.get('grandMaFirstName')?.value,
        lastName: this.insurerForm.get('grandMaLastName')?.value,
        gender: "Female",
        dateOfBirth: this.insurerForm.get('grandMaDOB')?.value,
        height: this.insurerForm.get('grandMaHeightFeet')?.value,
        weight: this.insurerForm.get('grandMaWeight')?.value,
        for: "grandMa",
        medicalQuestion: {}
      }
      this.insurerDetails.push(grandMaObj);
    }
    if (this.healthData.fatherLawSelected) {
      let fatherLawObj = {
        relationshipWithProposer: "Father-in-Law",
        firstName: this.insurerForm.get('fatherLawFirstName')?.value,
        lastName: this.insurerForm.get('fatherLawLastName')?.value,
        gender: "Male",
        dateOfBirth: this.insurerForm.get('fatherLawDOB')?.value,
        height: this.insurerForm.get('fahterLawHeightFeet')?.value,
        weight: this.insurerForm.get('fatherLawWeight')?.value,
        for: "fatherLaw",
        medicalQuestion: {}
      }
      this.insurerDetails.push(fatherLawObj);
    }
    if (this.healthData.motherLawSelected) {
      let motherLawObj = {
        relationshipWithProposer: "Mother-in-Law",
        firstName: this.insurerForm.get('motherLawFirstName')?.value,
        lastName: this.insurerForm.get('motherLawLastName')?.value,
        gender: "Female",
        dateOfBirth: this.insurerForm.get('motherLawDOB')?.value,
        height: this.insurerForm.get('motherLawHeightFeet')?.value,
        weight: this.insurerForm.get('motherLawWeight')?.value,
        for: "motherLaw",
        medicalQuestion: {}
      }
      this.insurerDetails.push(motherLawObj);
    }
    if (this.healthData.uncleSelected) {
      let uncleObj = {
        relationshipWithProposer: "Uncle",
        firstName: this.insurerForm.get('uncleFirstName')?.value,
        lastName: this.insurerForm.get('uncleLastName')?.value,
        gender: "Male",
        dateOfBirth: this.insurerForm.get('uncleDOB')?.value,
        height: this.insurerForm.get('uncleHeightFeet')?.value,
        weight: this.insurerForm.get('uncleWeight')?.value,
        for: "uncle",
        medicalQuestion: {}
      }
      this.insurerDetails.push(uncleObj);
    }
    if (this.healthData.auntSelected) {
      let auntObj = {
        relationshipWithProposer: "Aunt",
        firstName: this.insurerForm.get('auntFirstName')?.value,
        lastName: this.insurerForm.get('auntLastName')?.value,
        gender: "Female",
        dateOfBirth: this.insurerForm.get('auntDOB')?.value,
        height: this.insurerForm.get('auntHeightFeet')?.value,
        weight: this.insurerForm.get('auntWeight')?.value,
        for: "aunt",
        medicalQuestion: {}
      }
      this.insurerDetails.push(auntObj);
    }
    console.log(this.insurerDetails, "Insurer Array Test");

    this.bindMedicalQuestions();

  }

  get valPropControls() {
    return this.proposerForm.controls;
  }

  get valAddressPropControls() {
    return this.addressInfo.controls;
  }

  get valMemControls() {
    return this.insurerForm.controls;
  }

  get valNomControls() {
    return this.nomineeForm.controls;
  }


  loadRelationships() {
    this.RelationShipList = [
      { id: 1, name: "Father" },
      { id: 2, name: "Mother" },
      { id: 3, name: "Brother" },
      { id: 4, name: "Sister" },
      { id: 5, name: "Son" },
      { id: 6, name: "Daughter" },
      { id: 7, name: "Uncle" },
      { id: 8, name: "Aunt" },
      { id: 9, name: "Spouse" },
      { id: 11, name: "Grand Son" },
      { id: 12, name: "Grand Daughter" }
    ]
    // [{ id: 1, name: "Spouse" }, { id: 2, name: "Parent" }, { id: 3, name: "Child" }, { id: 4, name: "Sibling" },
    // { id: 5, name: "Grandparent" }, { id: 6, name: "Grandchild" }, { id: 7, name: "Cousin" }, { id: 8, name: "AuntUncle" },
    // { id: 9, name: "NieceNephew" }, { id: 10, name: "Friend" }, { id: 11, name: "Colleague" }, { id: 12, name: "Acquaintance" }];
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
  }

  alphaNumericOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }
  // Details(name:string):void{
  //   if(name == 'members' && this.detail =='proposer')
  //   {
  //     this.submitted = true;
  //     if(this.userFormControl['firstName'].valid && 
  //     this.userFormControl['phoneNumber'].valid && this.userFormControl['email'].valid &&
  //     this.userFormControl['panNumber'].valid  )
  //     {
  //       this.detail=name;
  //     }
  //   }
  //   else if(name == 'medical' && this.detail =='members')
  //   {
  //     this.submittedAddress = true;
  //     this.submittedNominee = true;
  //     // if(this.nomineeFormControl['spouseName'].valid )
  //     // {
  //     //   this.detail=name;
  //     // }
  //     this.detail=name;
  //   }
  //   // else if(name == 'nominee' && this.detail =='nominee')
  //   // {
  //   //   this.submittedNominee = true;
  //   //   // if(this.nomineeForm.valid)
  //   //   // {
  //   //   //   this.detail=name;
  //   //   // }
  //   // }

  // }
  get userFormControl() {
    return this.userForm.controls;
  }

  get nomineeFormControl() {
    return this.nomineeForm.controls;
  }

  // get vehicleFormControl() {
  //   return this.vehicleForm.controls;
  // }

  editPolicy() {
    this.isEditPolicy = !this.isEditPolicy;
  }


  policyTypeList =
    [
      { value: '', text: 'Select Previous Policy Type' },
      { value: 'Comprehensive', text: 'Standard/Comprehensive Coverage' },
      { value: 'Thirdparty', text: 'Third-Party Liability Coverage' },
      { value: 'NA', text: 'I am Not Sure' }
    ];

  async loadInsurancePartners() {
    // let response = await this._lookupService.GetInsurancePartnersList();
    //   if (response?.isSuccess) {
    //     this.insurancePartnersList = response.result.map((m:any)=> {return {text:m.insuranceName,value:m.insuranceName,id:m.insuranceName}});

    //   }
  }

  async getStatesByCountry(country: string) {
    let response = await this._lookupService.getStatesListByCountry(country);
    if (response?.isSuccess) {
      this.stateList = response.result.map((item: any) => { return { text: item.name, value: item.name, id: item.name } });
    }
  }

  async getDistrictsByState(state: string) {
    let response = await this._lookupService.getDistrictsListByState(state);
    if (response?.isSuccess) {
      this.districtList = response.result.map((item: any) => { return { text: item.name, value: item.name, id: item.name } });
    }
  }

  async getPincodesByDistrict(district: string) {
    let response = await this._lookupService.getPincodeByDistrict(district);
    if (response?.isSuccess) {
      //this.pincodeList = response.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      this.cityList = response.result.map((item: any) => { return { text: item.cityVillageArea, value: item.cityVillageArea, text1: item.pincode, id: item.id } });
    }
  }

  onStateSelection(data: AutoCompleteItem) {
    this.getDistrictsByState(data.text);
    this.addressInfo.get('proposerState')?.setValue(data.text);
    console.log(this.userForm.value);
  }

  onDistrictSelection(data: AutoCompleteItem) {
    this.addressInfo.get('proposerCity')?.setValue(data.text);
    this.getPincodesByDistrict(data.text);
    console.log(this.userForm.value);
  }

  onPincodeSelection(data: AutoCompleteItem) {
    this.getDistrictsByState(data.pincodeMaster?.state?.toUpperCase() ?? "");
    this.getPincodesByDistrict(data.pincodeMaster?.district?.toUpperCase() ?? "");
    this.addressInfo.get('proposerPinCode')?.setValue(data.pincodeMaster?.pincode);
    // this.addressInfo.get('pincodeId')?.setValue(data.pincodeMaster?.pincode?.toUpperCase());
    this.addressInfo.get('proposerCity')?.setValue(data.pincodeMaster?.cityVillageArea?.toUpperCase());
    this.addressInfo.get('district')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    this.addressInfo.get('proposerState')?.setValue(data.pincodeMaster?.state?.toUpperCase());
  }

  onCitySelection(data: AutoCompleteItem) {

    this.addressInfo.get('proposerCity')?.setValue(data.text);
  }

  onStateBlur(data: any) {
    if (data == "") {
      this.addressInfo.get('proposerState')?.setValue(data);
    }
  }

  onPincodeBlur(data: any) {
    if (data == "") {
      this.addressInfo.get('proposerPinCode')?.setValue(data);
      //this.userForm.get('pincodeId')?.setValue(data);
    }
  }

  onCityBlur(data: any) {
    // if(data == ""){
    this.addressInfo.get('proposerCity')?.setValue(data);
    // }
  }

  onDistrictBlur(data: any) {
    this.addressInfo.get('proposerCity')?.setValue(data);
    this.addressInfo.get('district')?.setValue(data);
  }

  // onPrevCompSelection(data: AutoCompleteItem) {
  //   this.vehicleForm.get('previousPolicyCompany')?.setValue(data.text);
  // }

  // onPrevCompBlur(data: any) {
  //   this.vehicleForm.get('previousPolicyCompany')?.setValue(data);
  // }

  activeTabs: number = 1;
  insurancePlan(val: any) {
    if (val == 'plan1') {
      this.activeTabs = 1;
    }
    if (val == 'plan2') {
      this.activeTabs = 2;
    }
    if (val == 'plan3') {
      this.activeTabs = 3;
    }
  }
  mobileVerificationDiv: boolean = false;
  isInsurancePlan: boolean = false;
  isviewbreakupplan: boolean = false;
  isviewFeatures: boolean = false;

  buynow() {
    this.isInsurancePlan = true;
    // this.mobileVerificationDiv = true; // commneted  by  dinesh
  }
  closeModal() {
    this.mobileVerificationDiv = false;
    this.isviewbreakupplan = false;
    this.isviewFeatures = false;
  }
  viewBreakupPlan() {
    this.isviewbreakupplan = true;
  }
  loginForm: FormGroup;
  proposerForm: FormGroup;
  insurerForm: FormGroup;
  AlertMessage: string = '';
  passwordShow: boolean = false;
  IsAlert: boolean = false;
  public passwordType: string = PasswordType.pwd;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  ServicesDown: boolean = false;
  signSubject: Subject<any> = new Subject<any>();
  list: any = [];



  async StatusCheck() {
    this.spinner.show();
    try {
      const response = await this.loginService.StatusCheck();
      if (response === ``) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: `Website currently down, apologize for the inconvenience.`,
          showConfirmButton: false,
          timer: 3000,
        });
        this.ServicesDown = true;
      }

    }
    catch (error) {
      console.log(error);
    }
    this.spinner.hide();
  }

  async submit() {
    ;
    try {
      this.spinner.show();

      if (this.loginForm.get('PhoneNumber')?.value != null && typeof (this.loginForm.get('PhoneNumber')?.value) == 'object') {
        let phone = this.loginForm.get('PhoneNumber')?.value;
        this.loginForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      }

      const request = { PhoneNumber: this.loginForm.get('PhoneNumber')?.value, Password: window.btoa(this.loginForm.get('Password')?.value), OTP_FOR: "CUST" }
      const res = await this.loginService.AuthenticateUser(request);

      if (res?.isSuccess) {
        this.mobileVerificationDiv = false;
        this.isInsurancePlan = true;
        let data = res.result;
        this.generalService.setAccessToken(data.accessToken);
        this.generalService.setUniqueuserid(data.uniqueUserId);

        await this.getDistributorSettings();
        //this.router.navigate(['/dashboard']);
        ///if(this.list[0].vehicleData.vehiclecategory =="2WN")
        //  this.router.navigate(['bike']);
        //  else
        //  this.router.navigate(['car']);
        sessionStorage.setItem('quotes_data', JSON.stringify(this.list[0].quote));
        sessionStorage.setItem('vehicle_data', JSON.stringify(this.list[0].vehicleData));
        sessionStorage.setItem('IsMockup', JSON.stringify(this.list[0].IsMockup));
        ;
        //this.navbarComponent.isUserLogged = true;
        //this.headerMenuComponent.isUserLogged = true;
        this.action.emit(true);

        this.modalRef.hide();
        this.mobileVerificationDiv = false;
        this.isInsurancePlan = true;
      } else {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: res?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinner.hide();
    }
  }


  async getDistributorSettings() {
    const response = await this._menuservice.GetDistributorSettings();
    if (response?.isSuccess) {
      sessionStorage.setItem('distributor_settings', response.result);
    }
  }
  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }
  onCountryChangeNew(e: any) {
    this.loginForm.get('phoneNumber')?.setValue("");
  }

  // closeModal() {
  //   this.modalRef.hide();
  // }

  signUp: string = 'quote-signUp';

  quoteSignup() {
    localStorage.setItem('routeName', this.signUp);
  }

  confirmdetails() {
    // this.dataSubject.next();

  }
  showIDVTooltip: boolean = false;
  minIdv: number = 0;
  addOnnsItems: Item[] = [];
  selectedAddOnIds?: any[] = [];

  sort_quote: string = "";
  quoteList: any = [];
  quoteListTP: any = [];
  quoteListTP1: any = [];
  policyFor: string = "Individual";

  sortQuoteList = [
    { value: "", text: 'Sort By' },
    { value: "sort_i", text: 'IDV high to low' },
    { value: "sort_c", text: 'Claim Settled high to low' },
    { value: "sort_p", text: 'Premium low to high' }
  ];

  sortQuote() {
    if (this.sort_quote == "sort_i") {
      this.quoteList = this.quoteList.sort((a: any, b: any) => b.idv > a.idv ? 1 : -1);
    }
    else if (this.sort_quote == "sort_c") {
      this.quoteList = this.quoteList.sort((a: any, b: any) => b.claimSettlementRatio > a.claimSettlementRatio ? 1 : -1);
    }
    else if (this.sort_quote == "sort_p") {
      this.quoteList = this.quoteList.sort((a: any, b: any) => a.grossPremium > b.grossPremium ? 1 : -1);
    }
  }
  openIDVModal() {
    this.showIDVTooltip = true;
  }
  onAddOnnChange(event: any) {
    let selectedAddOnn = event.target.value;
    if (event.target.checked) {
      if (this.selectedAddOnIds?.includes(selectedAddOnn) == false) {
        this.selectedAddOnIds.push(selectedAddOnn)
      }
    }
    else {
      if (this.selectedAddOnIds?.includes(selectedAddOnn)) {
        this.selectedAddOnIds = this.selectedAddOnIds.filter((f: any) => { return f != selectedAddOnn });
      }
    }
  }
  reCalculateQuote() {

  }
  goTo() {
    this.gotoEvent.emit('vehicle_quote');
  }
  isPopupFormVisible: boolean = false;
  cutomerDetail: boolean = false;

  openPopupForm() {
    this.isPopupFormVisible = true;
  }

  closePopupForm() {
    this.isPopupFormVisible = false;
  }

  updateVehicleDetailsForm(data: any) {


    {
      //this.insuranceformData.vehicleDetails.=data.varient;
      this.isPopupFormVisible = false;
    }

    // .vehicleDetails.city  = 
    // data.manufacture
    // data.model
    // data.rto
    // data.varient
    // data.year
  }
  get proposalFormControl() {
    return this.proposalForm.controls;
  }



  get membersFormControl() {
    return this.membersForm.controls;
  }
  detail: string = 'proposer';
  isproposal: boolean = true;
  ismember: boolean = false;
  ismedical: boolean = false;
  isnominee: boolean = false;
  submitted: boolean = false;


  isproposerDetails: boolean = true;
  ismemberDetails: boolean = false;
  ismedicalDetails: boolean = false;
  isnomineeDetails: boolean = false;
  isproposerAddress: boolean = false;
  proposerPancard: string = "";

  InsuranceDetails(value: any) {
    if (value == 'proposer') {

    }
    if (value == 'address') {
      this.ismemberDetails = false;
      this.isproposerDetails = false;
      this.ismedicalDetails = false;
      this.isnomineeDetails = false;
      this.isproposerAddress = true;
      this.detail = 'address';
    }
    if (value == 'member') {
      this.ismemberDetails = true;
      this.isproposerDetails = false;
      this.ismedicalDetails = false;
      this.isnomineeDetails = false;
      this.isproposerAddress = false;
      this.detail = 'members';
      this.proposerPancard = this.proposerForm?.value?.proposerPancard;
      this.insurerForm.get('selfFirstName')?.setValue(this.proposerForm?.value?.proposerFirstName);
      this.insurerForm.get('selfLastName')?.setValue(this.proposerForm?.value?.proposerLastName)

    }
    if (value == 'nominee') {
      this.ismemberDetails = false;
      this.isproposerDetails = false;
      this.ismedicalDetails = false;
      this.isnomineeDetails = true;
      this.isproposerAddress = false;
      this.detail = 'nominee';
    }
    if (value == 'medical') {
      this.ismemberDetails = false;
      this.isproposerDetails = false;
      this.ismedicalDetails = true;
      this.isnomineeDetails = false;
      this.isproposerAddress = false;
      this.detail = 'medical';
      if (this.insurerDetails.length == 0)
        this.formatInsurerDetails();
    }
    if (value == 'nominee') {
      this.ismemberDetails = false;
      this.isproposerDetails = false;
      this.ismedicalDetails = false;
      this.isnomineeDetails = true;
      this.isproposerAddress = false;
      this.detail = 'nominee';
    }
  }

  backFunction(value: any) {
    if (value == 'proposer') {
      this.ismemberDetails = false;
      this.isproposerDetails = true;
      this.ismedicalDetails = false;
      this.isnomineeDetails = false;
      this.isproposerAddress = false;
      this.detail = 'proposer';
    }
    if (value == 'address') {
      this.ismemberDetails = false;
      this.isproposerDetails = false;
      this.ismedicalDetails = false;
      this.isnomineeDetails = false;
      this.isproposerAddress = true;
      this.detail = 'address';
    }
    if (value == 'member') {
      this.ismemberDetails = true;
      this.isproposerDetails = false;
      this.ismedicalDetails = false;
      this.isnomineeDetails = false;
      this.isproposerAddress = false;
      this.detail = 'members';

    }
    if (value == 'medical') {
      this.ismemberDetails = false;
      this.isproposerDetails = false;
      this.ismedicalDetails = true;
      this.isnomineeDetails = false;
      this.isproposerAddress = false;
      this.detail = 'medical';
    }
    if (value == 'nominee') {
      this.ismemberDetails = false;
      this.isproposerDetails = false;
      this.ismedicalDetails = false;
      this.isnomineeDetails = true;
      this.isproposerAddress = false;
      this.detail = 'nominee';

    }
  }
  viewFeatures() {
    this.isviewFeatures = true;
  }
  iscoveragefeatures: boolean = true;
  iswaitingperiodfeatures: boolean = false;
  isadditionalfeatures: boolean = false;
  isvalueaddedfeatures: boolean = false;
  iscashlesshospitals = false;
  isdocumentsupload = false;
  tab1: boolean = true;
  tab2: boolean = false;
  tab3: boolean = false;
  tab4: boolean = false;
  tab5: boolean = false;
  tab6: boolean = false;

  featuresList(val: any) {
    if (val == 'coverage') {
      this.iscoveragefeatures = true;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = false;
      this.tab1 = true;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = false;
      this.tab6 = false;
    }
    if (val == 'waiting period') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = true;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = false;
      this.tab1 = false;
      this.tab2 = true;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = false;
      this.tab6 = false;
    }
    if (val == 'Additional Features') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = true;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = false;
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = true;
      this.tab4 = false;
      this.tab5 = false;
      this.tab6 = false;
    }
    if (val == 'Value Added Service') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = true;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = false;
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = true;
      this.tab5 = false;
      this.tab6 = false;
    }
    if (val == 'Cashless Hospitals') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = true;
      this.isdocumentsupload = false;
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = true;
      this.tab6 = false;
    }
    if (val == 'Documents Upload') {
      this.iscoveragefeatures = false;
      this.iswaitingperiodfeatures = false;
      this.isadditionalfeatures = false;
      this.isvalueaddedfeatures = false;
      this.iscashlesshospitals = false;
      this.isdocumentsupload = true;
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = false;
      this.tab6 = true;
    }
  }

  openItemIndex = -1;

  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  selectCard: boolean = false;
  selectCard1: boolean = false;
  selectCard2: boolean = false;
  selectCard3: boolean = false;
  selectCard4: boolean = false;
  selectCard5: boolean = false;
  selectCard6: boolean = false;
  selectCard7: boolean = false;
  selectCard8: boolean = false;
  selectCard9: boolean = false;
  selectCard10: boolean = false;
  selectCard22: boolean = false;
  selectCard23: boolean = false;
  selectedCard(val: any) {
    if (val == 'selectCard1') {
      this.selectCard1 = !this.selectCard1;

    }
    if (val == 'selectCard2') {
      this.selectCard2 = !this.selectCard2;
    }
    if (val == 'selectCard3') {
      this.selectCard3 = !this.selectCard3;
    }
    if (val == 'selectCard4') {
      this.selectCard4 = !this.selectCard4;
    }
    if (val == 'selectCard5') {
      this.selectCard5 = !this.selectCard5;
    }
    if (val == 'selectCard6') {
      this.selectCard6 = !this.selectCard6;
    }
    if (val == 'selectCard7') {
      this.selectCard7 = !this.selectCard7;
    }
    if (val == 'selectCard8') {
      this.selectCard8 = !this.selectCard8;
    }
    if (val == 'selectCard9') {
      this.selectCard9 = !this.selectCard9;
    }
    if (val == 'person Ten') {
      this.selectCard10 = !this.selectCard10;
    }
    if (val == 'Self') {
      this.selectCard22 = true;
      this.selectCard23 = false;
    }
    if (val == 'Spouse') {
      this.selectCard23 = true;
      this.selectCard22 = false;
    }
  }
  Up: boolean = false;
  Up1: boolean = false;
  Up2: boolean = false;
  Up3: boolean = false;
  down: boolean = true;
  down1: boolean = true;
  down2: boolean = true;
  down3: boolean = true;
  chUp: boolean = false;
  chDw: boolean = true;

  chilVal: string = '';
  value: string = '';
  dropDown(val: string) {
    this.value = val;
    if (this.value == 'down') {
      this.down = false;
      this.Up = true;
    }
    if (this.value == 'up') {
      this.down = true;
      this.Up = false;
    }
    if (this.value == 'down1') {
      this.down1 = false;
      this.Up1 = true;
    }
    if (this.value == 'up1') {
      this.down1 = true;
      this.Up1 = false;
    }


  }
  child: boolean = false;
  ChildDown() {
    this.down2 = false;
    this.Up2 = true;
    this.child = true;

  }
  ChildUp() {
    this.down2 = true;
    this.Up2 = false;
    this.child = false;
  }
  firstPage: boolean = false;
  fisrtDetail() {
    this.firstPage = true;
    alert(this.firstPage);
  }
  async getAge() {
    this.ageList = [];
    for (var i = 18; i <= 100; i++) {
      this.ageList.push(i)
    }
  }
  async getFeet() {
    this.feetList = [];
    for (var i = 1; i <= 8; i++) {
      this.feetList.push(i)
    }
  }

  async getInch() {
    this.inchList = [];
    for (var i = 0; i <= 11; i++) {
      this.inchList.push(i)
    }
  }



  getSavedData(data: any) {
    //console.log(data);
    if (data) {
      // this.insurerDetails = this.insurerDetails.map((m:any)=>{
      //   return {...m, medicalQuestion : data.data }
      // })
      ;
      for (let i = 0; i < this.insurerDetails.length; i++) {
        if (data.for == this.insurerDetails[i].for) {
          this.insurerDetails[i].medicalQuestion = data.data;
        }
      }
      console.log(this.insurerDetails);
    }
  }

  bindMedicalQuestions() {
    this.insurerDetails = this.insurerDetails.map((m: any) => {
      let fields = JSON.parse(JSON.stringify(this.required_input_fields));
      fields.for = m.for;
      //fields.formFields.forEach((v:FormField)=>
      for (let i = 0; i < fields.formFields.length; i++) {
        let v = fields.formFields[i];
        v.id = m.for + "_" + v.id;
        v.name = m.for + "_" + v.name;
        if (v.cascadeItems && v.cascadeItems.formFields && v.cascadeItems.formFields.length > 0) {
          //v.cascadeItems.formFields.forEach((v1:FormField)=>
          for (let j = 0; j < v.cascadeItems.formFields.length; j++) {
            let v1 = v.cascadeItems.formFields[j];
            v1.id = v.id + "_" + v1.id;
            v1.name = v.name + "_" + v1.name;
            if (v1.cascadeItems && v1.cascadeItems.formFields && v1.cascadeItems.formFields.length > 0) {
              //v1.cascadeItems.formFields.forEach((v2:FormField)=>
              for (let k = 0; k < v1.cascadeItems.formFields.length; k++) {
                let v2 = v1.cascadeItems.formFields[k];
                v2.id = v1.id + "_" + v2.id;
                v2.name = v1.name + "_" + v2.name;
                if (v2.cascadeItems && v2.cascadeItems.formFields && v2.cascadeItems.formFields.length > 0) {
                  //v1.cascadeItems.formFields.forEach((v2:FormField)=>
                  for (let l = 0; l < v2.cascadeItems.formFields.length; l++) {
                    let v3 = v2.cascadeItems.formFields[l];
                    v3.id = v2.id + "_" + v3.id;
                    v3.name = v2.name + "_" + v3.name;

                  };
                }
              };
            }
          }
        }
      }
      return { ...m, medicalQuestion: (fields) }
    });
  }

  async getPanCardDetails() {
    this.spinner.show();
    let obj = {
      docNum: 1,
      source: '',
      id_number: this.proposerForm.get('proposerPancard')?.value
    }
    const response = await this.agentprofileService.GetAgentKYCDetails(obj);
    console.log(response);
    debugger;
    if (response?.isSuccess) {
      this.spinner.hide();

      this.defaultDate = response.result.dob;
      let panDetails = response.result;;
      this.proposerForm.get('selfDOB')?.setValue(panDetails?.dob ?? '');
      if (panDetails?.full_name != null && panDetails?.full_name != undefined && panDetails?.full_name.length > 5) {
        let nameArray = panDetails?.full_name.split(' ');
        switch (nameArray.length) {
          case 2:
            this.proposerForm.get('proposerFirstName')?.setValue(nameArray[0]);
            this.proposerForm.get('proposerLastName')?.setValue(nameArray[1]);
            break;
          case 3:
            this.proposerForm.get('proposerFirstName')?.setValue(nameArray[0] + ' ' + nameArray[1]);
            this.proposerForm.get('proposerLastName')?.setValue(nameArray[2]);
            break;
          case 4:
            this.proposerForm.get('proposerFirstName')?.setValue(nameArray[0] + ' ' + nameArray[1] + ' ' + nameArray[2]);
            this.proposerForm.get('proposerLastName')?.setValue(nameArray[3]);
            break;
        }
      }
    }
    else {
      this.spinner.hide();
    }
  }



  async onPincodeChange(data: any) {
    if (data.length >= 3) {
      const res = await this._lookupService.pincodeMasterDetails(data);
      if (res != null && res.result) {
        this.pincodeList = res.result.map((item: any) => { return { text: item.pincode, value: item.pincode, text1: item.cityVillageArea, id: item.id, pincodeMaster: item } });
      }
    }

  }


  fetchData() {
    this.insurerDetails1 = this.insurerDetails.map((m: any) => {
      let fields = JSON.parse(JSON.stringify(m.medicalQuestion));
      fields.for = m.for;
      //fields.formFields.forEach((v:FormField)=>
      for (let i = 0; i < fields.formFields.length; i++) {
        let v = fields.formFields[i];
        let vId = v.id;
        let vName = v.name;
        v.id = v.id.replace(m.for + '_', '');
        v.name = v.name.replace(m.for + '_', '');
        if (v.cascadeItems && v.cascadeItems.formFields && v.cascadeItems.formFields.length > 0) {
          //v.cascadeItems.formFields.forEach((v1:FormField)=>
          for (let j = 0; j < v.cascadeItems.formFields.length; j++) {
            let v1 = v.cascadeItems.formFields[j];
            let v1Id = v1.id;
            let v1Name = v1.name;
            v1.id = v1.id.replace(vId + '_', '');
            v1.name = v1.name.replace(vName + '_', '');
            if (v1.cascadeItems && v1.cascadeItems.formFields && v1.cascadeItems.formFields.length > 0) {
              //v1.cascadeItems.formFields.forEach((v2:FormField)=>
              for (let k = 0; k < v1.cascadeItems.formFields.length; k++) {
                let v2 = v1.cascadeItems.formFields[k];
                let v2Id = v2.id;
                let v2Name = v2.name;
                v2.id = v2.id.replace(v1Id + '_', '');
                v2.name = v2.name.replace(v1Name + '_', '');
                if (v2.cascadeItems && v2.cascadeItems.formFields && v2.cascadeItems.formFields.length > 0) {
                  //v1.cascadeItems.formFields.forEach((v2:FormField)=>
                  for (let l = 0; l < v2.cascadeItems.formFields.length; l++) {
                    let v3 = v2.cascadeItems.formFields[l];
                    v3.id = v3.id.replace(v2Id + '_', '');
                    v3.name = v3.name.replace(v2Name + '_', '');
                  };
                }
              };
            }
          }
        }
      }
      return { ...m, medicalQuestion: (fields) }
    });
    console.log(this.insurerDetails1);
  }


  required_input_fields: HealthQuestionForm = {
    formTitle: "Health insurance Questions",
    formSubTitle: "",
    formFields: [
      {
        id: "consultedDoc",
        title: "Within the last 2 years have you consulted a doctor or healthcare professional?(other than Preventive or Pre Employment Health Check-up)",
        type: "radio",
        isVisible: true,
        name: "consultedDoc",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "nameOfIllness",
              title: "Name of illness/injury suffering from or suffered in the past",
              type: "text",
              isVisible: true,
              name: "nameOfIllness",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "dateOfDiagnosis",
              title: "Date of first diagnosis",
              type: "date",
              isVisible: true,
              name: "dateOfDiagnosis",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "medicationDetail",
              title: "Treatment/medication",
              type: "text",
              isVisible: true,
              name: "medicationDetail",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "treatmentOutCome",
              title: "Treatment outcome",
              type: "text",
              isVisible: true,
              name: "treatmentOutCome",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            }
          ]
        }
      },
      {
        id: "detailedInvestigation",
        title: "Within the last 2 years have you undergone for any detailed investigation other than Preventive or Pre Employment Health Check-up.e.g. X-ray, CT Scan, biopsy, MRI, Sonography, etc",
        type: "radio",
        isVisible: true,
        name: "detailedInvestigation",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "nameOfIllness",
              title: "Name of illness/injury suffering from or suffered in the past",
              type: "text",
              isVisible: true,
              name: "nameOfIllness",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "dateOfDiagnosis",
              title: "Date of first diagnosis",
              type: "date",
              isVisible: true,
              name: "dateOfDiagnosis",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "medicationDetail",
              title: "Treatment/medication",
              type: "text",
              isVisible: true,
              name: "medicationDetail",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "treatmentOutCome",
              title: "Treatment outcome",
              type: "text",
              isVisible: true,
              name: "treatmentOutCome",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            }
          ]
        }
      },
      {
        id: "operation",
        title: "Within the last 5 years have you been to a hospital for an operation/medical treatment?",
        type: "radio",
        isVisible: true,
        name: "operation",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "nameOfIllness",
              title: "Name of illness/injury suffering from or suffered in the past",
              type: "text",
              isVisible: true,
              name: "nameOfIllness",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "dateOfDiagnosis",
              title: "Date of first diagnosis",
              type: "date",
              isVisible: true,
              name: "dateOfDiagnosis",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "medicationDetail",
              title: "Treatment/medication",
              type: "text",
              isVisible: true,
              name: "medicationDetail",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "treatmentOutCome",
              title: "Treatment outcome",
              type: "text",
              isVisible: true,
              name: "treatmentOutCome",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            }
          ]
        }
      },
      {
        id: "regularBasisMedicines",
        title: "Do you take tablets, medicines or drugs on a regular basis?",
        type: "radio",
        isVisible: true,
        name: "regularBasisMedicines",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "nameOfIllness",
              title: "Name of illness/injury suffering from or suffered in the past",
              type: "text",
              isVisible: true,
              name: "nameOfIllness",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "dateOfDiagnosis",
              title: "Date of first diagnosis",
              type: "date",
              isVisible: true,
              name: "dateOfDiagnosis",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "medicationDetail",
              title: "Treatment/medication",
              type: "text",
              isVisible: true,
              name: "medicationDetail",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "treatmentOutCome",
              title: "Treatment outcome",
              type: "text",
              isVisible: true,
              name: "treatmentOutCome",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            }
          ]
        }
      },
      {
        id: "experiencedHealthProblems",
        title: "Within the last 3 months have you experienced any health problems or medical conditions which you/proposed insured person have/has not seen a doctor for",
        type: "radio",
        isVisible: true,
        name: "experiencedHealthProblems",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "nameOfIllness",
              title: "Name of illness/injury suffering from or suffered in the past",
              type: "text",
              isVisible: true,
              name: "nameOfIllness",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "dateOfDiagnosis",
              title: "Date of first diagnosis",
              type: "date",
              isVisible: true,
              name: "dateOfDiagnosis",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "medicationDetail",
              title: "Treatment/medication",
              type: "text",
              isVisible: true,
              name: "medicationDetail",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "treatmentOutCome",
              title: "Treatment outcome",
              type: "text",
              isVisible: true,
              name: "treatmentOutCome",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            }
          ]
        }
      },
      {
        id: "seriousIllness",
        title: "Have any of the person proposed to be insured ever suffered from or taken treatment, or hospitalized for or have been recommended to take investigations/medication/surgery or undergone a surgery for any of the following – Diabetes; Hypertension; Ulcer/Cyst/Cancer; Cardiac Disorder; Kidney or Urinary Tract Disorder; Disorder of muscle/bone/joint; Respiratory disorder; Digestive tract or gastrointestinal disorder; Nervous System disorder; Mental Illness or disorder, HIV or AIDS",
        type: "radio",
        isVisible: true,
        name: "seriousIllness",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "nameOfIllness",
              title: "Name of illness/injury suffering from or suffered in the past",
              type: "text",
              isVisible: true,
              name: "nameOfIllness",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "dateOfDiagnosis",
              title: "Date of first diagnosis",
              type: "date",
              isVisible: true,
              name: "dateOfDiagnosis",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "medicationDetail",
              title: "Treatment/medication",
              type: "text",
              isVisible: true,
              name: "medicationDetail",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "treatmentOutCome",
              title: "Treatment outcome",
              type: "text",
              isVisible: true,
              name: "treatmentOutCome",
              options: ["Yes", "No"],
              selectedValue: "",
              placeholder: "",
              errorText: "",
              isRequired: true,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            }
          ]
        }
      },
      {
        id: "alcohol",
        title: "Alcohol",
        type: "radio",
        isVisible: true,
        name: "alcohol",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "alcohol_quanlity",
              title: "Quantity in MiliLiter",
              type: "number",
              isVisible: true,
              name: "alcohol_quanlity",
              options: ["Yes", "No"],
              selectedValue: "0",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "alcohol_years",
              title: "No. of Years",
              type: "number",
              isVisible: true,
              name: "alcohol_years",
              options: ["Yes", "No"],
              selectedValue: "0",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "alcohol_habit_in_past",
              title: "These habit has been in the past?",
              type: "radio",
              isVisible: true,
              name: "alcohol_habit_in_past",
              options: ["Yes", "No"],
              selectedValue: "No",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {
                formTitle: "",
                formSubTitle: "",
                formFields: [
                  {
                    id: "alcohol_habit_left_date",
                    title: "Habit left date",
                    type: "date",
                    isVisible: true,
                    name: "alcohol_habit_left_date",
                    options: ["Yes", "No"],
                    selectedValue: "1912-12-12",
                    placeholder: "",
                    errorText: "",
                    isRequired: false,
                    maxLength: 0,
                    minLength: 0,
                    isCascade: false,
                    cascadeItems: {}
                  },
                  {
                    id: "alcohol_habit_left_reason",
                    title: "Habit left reason",
                    type: "textarea",
                    isVisible: true,
                    name: "alcohol_habit_left_reason",
                    options: ["Yes", "No"],
                    selectedValue: "",
                    placeholder: "",
                    errorText: "",
                    isRequired: false,
                    maxLength: 0,
                    minLength: 0,
                    isCascade: false,
                    cascadeItems: {}
                  }
                ]
              }
            }
          ]
        }
      },
      {
        id: "smoking",
        title: "Smoking",
        type: "radio",
        isVisible: true,
        name: "smoking",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "smoking_quanlity",
              title: "Quantity Per Day",
              type: "number",
              isVisible: true,
              name: "smoking_quanlity",
              options: ["Yes", "No"],
              selectedValue: "0",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "smoking_years",
              title: "No. of Years",
              type: "number",
              isVisible: true,
              name: "smoking_years",
              options: ["Yes", "No"],
              selectedValue: "0",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "smoking_habit_in_past",
              title: "These habit has been in the past?",
              type: "radio",
              isVisible: true,
              name: "smoking_habit_in_past",
              options: ["Yes", "No"],
              selectedValue: "No",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {
                formTitle: "",
                formSubTitle: "",
                formFields: [
                  {
                    id: "smoking_habit_left_date",
                    title: "Habit left date",
                    type: "date",
                    isVisible: true,
                    name: "smoking_habit_left_date",
                    options: ["Yes", "No"],
                    selectedValue: "1912-12-12",
                    placeholder: "",
                    errorText: "",
                    isRequired: false,
                    maxLength: 0,
                    minLength: 0,
                    isCascade: false,
                    cascadeItems: {}
                  },
                  {
                    id: "smoking_habit_left_reason",
                    title: "Habit left reason",
                    type: "textarea",
                    isVisible: true,
                    name: "smoking_habit_left_reason",
                    options: ["Yes", "No"],
                    selectedValue: "",
                    placeholder: "",
                    errorText: "",
                    isRequired: false,
                    maxLength: 0,
                    minLength: 0,
                    isCascade: false,
                    cascadeItems: {}
                  }
                ]
              }
            }
          ]
        }
      },
      {
        id: "tobacco",
        title: "Tobacco",
        type: "radio",
        isVisible: true,
        name: "tobacco",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "tobacco_quanlity",
              title: "Quantity in Gram",
              type: "number",
              isVisible: true,
              name: "tobacco_quanlity",
              options: ["Yes", "No"],
              selectedValue: "0",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "tobacco_years",
              title: "No. of Years",
              type: "number",
              isVisible: true,
              name: "tobacco_years",
              options: ["Yes", "No"],
              selectedValue: "0",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "tobacco_habit_in_past",
              title: "These habit has been in the past?",
              type: "radio",
              isVisible: true,
              name: "tobacco_habit_in_past",
              options: ["Yes", "No"],
              selectedValue: "No",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {
                formTitle: "",
                formSubTitle: "",
                formFields: [
                  {
                    id: "tobacco_habit_left_date",
                    title: "Habit left date",
                    type: "date",
                    isVisible: true,
                    name: "tobacco_habit_left_date",
                    options: ["Yes", "No"],
                    selectedValue: "1912-12-12",
                    placeholder: "",
                    errorText: "",
                    isRequired: false,
                    maxLength: 0,
                    minLength: 0,
                    isCascade: false,
                    cascadeItems: {}
                  },
                  {
                    id: "tobacco_habit_left_reason",
                    title: "Habit left reason",
                    type: "textarea",
                    isVisible: true,
                    name: "tobacco_habit_left_reason",
                    options: ["Yes", "No"],
                    selectedValue: "",
                    placeholder: "",
                    errorText: "",
                    isRequired: false,
                    maxLength: 0,
                    minLength: 0,
                    isCascade: false,
                    cascadeItems: {}
                  }
                ]
              }
            }
          ]
        }
      },
      {
        id: "narcotics",
        title: "Narcotics",
        type: "radio",
        isVisible: true,
        name: "narcotics",
        options: ["Yes", "No"],
        selectedValue: "No",
        placeholder: "",
        errorText: "",
        isRequired: false,
        maxLength: 0,
        minLength: 0,
        isCascade: false,
        cascadeItems: {
          formTitle: "",
          formSubTitle: "",
          formFields: [
            {
              id: "narcotics_quanlity",
              title: "Quantity in Gram",
              type: "number",
              isVisible: true,
              name: "narcotics_quanlity",
              options: ["Yes", "No"],
              selectedValue: "0",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "narcotics_years",
              title: "No. of Years",
              type: "number",
              isVisible: true,
              name: "narcotics_years",
              options: ["Yes", "No"],
              selectedValue: "0",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {}
            },
            {
              id: "narcotics_habit_in_past",
              title: "These habit has been in the past?",
              type: "radio",
              isVisible: true,
              name: "narcotics_habit_in_past",
              options: ["Yes", "No"],
              selectedValue: "No",
              placeholder: "",
              errorText: "",
              isRequired: false,
              maxLength: 0,
              minLength: 0,
              isCascade: false,
              cascadeItems: {
                formTitle: "",
                formSubTitle: "",
                formFields: [
                  {
                    id: "narcotics_habit_left_date",
                    title: "Habit left date",
                    type: "date",
                    isVisible: true,
                    name: "narcotics_habit_left_date",
                    options: ["Yes", "No"],
                    selectedValue: "1912-12-12",
                    placeholder: "",
                    errorText: "",
                    isRequired: false,
                    maxLength: 0,
                    minLength: 0,
                    isCascade: false,
                    cascadeItems: {}
                  },
                  {
                    id: "narcotics_habit_left_reason",
                    title: "Habit left reason",
                    type: "textarea",
                    isVisible: true,
                    name: "narcotics_habit_left_reason",
                    options: ["Yes", "No"],
                    selectedValue: "",
                    placeholder: "",
                    errorText: "",
                    isRequired: false,
                    maxLength: 0,
                    minLength: 0,
                    isCascade: false,
                    cascadeItems: {}
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  };

  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
      event.stopPropagation();
    }
  }
  onlyNumbers(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    if (!/^\d+$/.test(inputChar)) {
      event.preventDefault();
    }
  }
}
