<div class="user-dashboard">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
    type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="card-user user-route">
    <div class="card-user">
      <div class="row pl-3">
        <div class="col-md-6">
          <h1 class="mob">Agent Customers List</h1>
        </div>
        <div class="col-md-6">
          <button type="button" class="btn btn-primary float-end me-5" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" (click)="EditCustomer1(null)">
            <img src="../../assets/svgIcon/add-circle.svg" class="icon_width_height_24_18 icon-white">
            Add Customer
          </button>
        </div>
      </div>

      <div class="row p-3">
        <ag-grid-angular style="height:63vh;" class="ag-theme-alpine w-100" [columnDefs]="columnDefsCustomers"
          [rowData]="customerList" [defaultColDef]="defaultColDef" [paginationPageSize]="paginationPageSize"
          [paginationAutoPageSize]="false" [pagination]="true" [gridOptions]="gridOptions"
          (gridReady)="onGridReadyCustomers($event)" aria-hidden="true" id="cust_grid">
        </ag-grid-angular>

        <div class="" *ngIf="customer">
          <div class="">
            <div class="col-12 mt-2 m-0 p-3 card">
              <div class="row m-0 p-0 text-start">
                <div class="col-md-6 d-flex">
                  <div>
                    <p class="font-body m-0">Name</p>
                    <h4>{{customer.firstName}} {{customer.lastName}}</h4>
                  </div>
                </div>
                <div class="col-md-6 d-flex justify-content-start">
                  <div>
                    <p class="font-body m-0">Status</p>
                    <h4 *ngIf="customer.isActive" class="badge badge-light-success">Active</h4>
                    <h4 *ngIf="!customer.isActive" class="badge badge-light-danger">Inactive</h4>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div>
                    <p class="font-body m-0">Email</p>
                    <h4>{{ customer.userEmail }}</h4>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div>
                    <p class="font-body m-0">Phone Number</p>
                    <h4>{{ customer.phoneNumber }}</h4>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div>
                    <p class="font-body m-0">Street Address</p>
                    <h4>{{ customer.streetAddress }}</h4>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div>
                    <p class="font-body m-0">Pincode</p>
                    <h4>{{ customer.pincode }}</h4>
                  </div>
                </div>
                <div class="col-12 d-flex">
                  <button type="button" class="btn btn-primary me-3" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling"
                    (click)="EditCustomer1(customer)">
                    <img src="../../assets/svgIcon/PencilLineBlack.svg" class="icon_width_height_24_18 icon-white">
                    Edit Customer
                  </button>

                  <!-- <button type="button" class="btn btn-primary me-3" data-bs-toggle="offcanvas"
                    data-bs-target="#addvehicle" aria-controls="offcanvasScrolling"
                    (click)="addVehicle(customer.uniqueUserId)">
                    <span class="svg-icon svg-icon-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                          transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
                      </svg>
                    </span>
                    Add Vehicle
                  </button> -->
                  <!-- <button type="button" class="btn btn-primary me-3" data-bs-toggle="offcanvas" data-bs-target="#addPolicy" aria-controls="offcanvasScrolling" (click)="addPolicy(customer.uniqueUserId)">
                    <span class="svg-icon svg-icon-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                          transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
                      </svg>
                    </span>
                    Add Policy
                  </button> -->
                </div>
  
                <div class="col-12 mt-3">
                  <div class="collapsible-panel">
                    <div class="panel-header" (click)="toggleCollapse('doc', docPanel)">   
                      <span class="d-flex align-items-center">Document List
                          <span class="text-main d-flex font-14 align-items-center ml-2">
                              <!-- <img src="/assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon-main edit-icon cursor-pointer ml-2 mr-1"> Edit -->
                              <button type="button" class="btn btn-primary btn-md py-1 px-2" data-bs-toggle="offcanvas"
                              data-bs-target="#adddocument" aria-controls="offcanvasScrolling"
                              (click)="addDocument(customer.uniqueUserId)">
                              <img src="../../assets/svgIcon/add-circle.svg" class="icon_width_height_24_18 icon-white">
                              Add Document
                            </button>
                          </span> 
                      </span> 
                      <span class="collapse-icon"><img src="../../assets/svgIcon/{{ docPanel ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                  </div>
                  <div class="panel-content" [ngClass]="{ 'expanded': docPanel,'collapsed': !docPanel }">
                    <ag-grid-angular style="height:63vh;" class="ag-theme-alpine w-100" [columnDefs]="columnDefsDocuments"
                      [rowData]="userDocumentList" [defaultColDef]="defaultColDef" [paginationPageSize]="paginationPageSize"
                      [paginationAutoPageSize]="false" [pagination]="true" [gridOptions]="gridOptions"
                      (gridReady)="GetUserDocuments()" aria-hidden="true" id="doc_grid">
                    </ag-grid-angular>
                  </div>  
                    <div class="collapsible-panel">
                      <div class="panel-header" (click)="toggleCollapse('veh', vehPanel)">   
                        <span class="d-flex align-items-center">Vehicle List
                            <span class="text-main d-flex font-14 align-items-center ml-2">
                              <button type="button" class="btn btn-primary btn-md py-1 px-2" data-bs-toggle="offcanvas"
                              data-bs-target="#addvehicle" aria-controls="offcanvasScrolling"
                              (click)="addVehicle(customer.uniqueUserId)">
                              <img src="../../assets/svgIcon/add-circle.svg" class="icon_width_height_24_18 icon-white">
                              Add Vehicle
                            </button>
                            </span> 
                        </span> 
                        <span class="collapse-icon"><img src="../../assets/svgIcon/{{ vehPanel ? 'ChevronUp' : 'ChevronDown' }}.svg" alt=""></span>
                    </div>
                    <div class="panel-content" [ngClass]="{ 'expanded': vehPanel,'collapsed': !vehPanel }">                    
                      Vehicle
                    </div>                     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false"
          tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
          <manageagentcustomer></manageagentcustomer>
        </div>
        <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false"
          tabindex="-1" id="adddocument" aria-labelledby="offcanvasScrollingLabel">
          <add-edit-document></add-edit-document>
        </div>

        <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false"
          tabindex="-1" id="addvehicle" aria-labelledby="offcanvasScrollingLabel">
          <managevehicle></managevehicle>
        </div>
        <div class="offcanvas offcanvas-end overflow-scroll w-50" data-bs-scroll="true" data-bs-backdrop="false"
          tabindex="-1" id="addPolicy" aria-labelledby="offcanvasScrollingLabel">
          <app-add-policy></app-add-policy>
        </div>
      </div>
    </div>
  </div>
</div>