import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PincodeMaster } from '../Models/signUp.component.model';
import { LookUpService } from '../Services/lookup.service';
import { UserService } from '../Services/user.service';
import swal from 'sweetalert2';

@Component({
  selector: 'manageagentcustomer',
  templateUrl: './manageagentcustomer.component.html',
  styleUrls: ['./manageagentcustomer.component.css']
})
export class ManageagentcustomerComponent {
  customerForm: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  pincodeMaster = new PincodeMaster();
  @ViewChild('closebtn', { static: true }) closeButtonRef: ElementRef;
  constructor(private fb: FormBuilder, private lookUpService: LookUpService,
    private userservice: UserService) {
    this.customerForm = this.fb.group({
      "uniqueUserId": [''],
      "firstName": ['', [Validators.required]],
      "lastName": ['', [Validators.required]],
      "phoneNumber": ['', [Validators.required]],
      "countryCode": [''],
      "userEmail": ['', [Validators.pattern(/\S+@\S+\.\S+/)]],
      "dob": ['', [Validators.required]],
      "gender": ['Male', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "pincode": [''],
      "pincodeId": [''],

    });

    const dateObject = new Date();
    this.customerForm.get('dob')?.setValue(dateObject.toISOString().slice(0, 10));
  }

  async submit() {
    if (this.customerForm.get('phoneNumber')?.value != null) {
      let phone = this.customerForm.get('phoneNumber')?.value;
      if (phone.dialCode) {
        this.customerForm.get('countryCode')?.setValue(phone.dialCode);
        this.customerForm.get('phoneNumber')?.setValue(phone.number.replace(phone.dialCode, '').replace(/\s/g, ""));
      }

    }
    this.customerForm.get('pincodeId')?.setValue(this.pincodeMaster?.id?.toString());
    const response = await this.userservice.ManipulateUserInfo(this.customerForm.value);
    if (response?.isSuccess) {
      if (this.customerForm.get('uniqueUserId')?.value === '') {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Customer Added',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Customer Updated',
          showConfirmButton: false,
          timer: 3000,
        });
      }

    }

    this.closeButtonRef.nativeElement.click();
  }

  onCountryChangeNew(e: any) {
    this.customerForm.get('phoneNumber')?.setValue("");
  }

  async pincodeUserChangeEvent(pincode: any, isLoad?: boolean) {
    this.pincodeMaster = new PincodeMaster();
    var pin = "";
    if (isLoad)
      pin = pincode;
    else
      pin = pincode.value;
    const res = await this.lookUpService.pincodeMasterDetails(pin);
    if (res != null && res.result) {
      this.pincodeMaster = res.result[0];
      this.customerForm.get('pincodeId')?.setValue(this.pincodeMaster?.id?.toString());
    }
  }

  async EditCustomer(customer: any) {
    if(customer){
      this.customerForm.patchValue(customer);
      const dateObject = new Date(customer.dob);
      this.customerForm.get('dob')?.setValue(dateObject.toISOString().slice(0, 10));
      this.pincodeUserChangeEvent(customer.pincode, true);
    }
    else
    {
      this.customerForm.reset();
    }
  }


}
